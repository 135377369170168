import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import UserProfile from '../../molecules/Profile';
import { fetchProfile } from '../../../actions';
import FullScreenLoader from '../../molecules/FullScreenLoader';

const Profile = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const companyRegisterData = useSelector(
    (state: any) => state?.companyRegisterData
  );
  const { profile, getProfileStatus } = companyRegisterData;

  useEffect(() => {
    dispatch(fetchProfile());
  }, []);

  return (
    <>
      {getProfileStatus === 'PENDING' ? (
        <FullScreenLoader title={'Fetching user details'} />
      ) : null}
      <div className="container">
        <div className="section">
          <UserProfile profile={profile} />
        </div>
      </div>
    </>
  );
};
export default Profile;
