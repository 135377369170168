import React from 'react';

import DashboardComponent from '../../organisms/Dashboard';

const Dashboard = () => {
  return (
    <div
      className="dashboard-vertical-center"
      style={{
        minHeight: '70vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }}
    >
      <div className="section">
        <DashboardComponent />
      </div>
    </div>
  );
};
export default Dashboard;
