import { Action } from '../../actions/ActionTypes.interface';
const initialState = {};

export const companyOnboardReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case 'POST_COMPANY_COMPLETE': {
      return {
        ...state,
        ...{
          postCompanyStatus: 'SUCCESS',
          postCompanyResult: action.payload,
          postCompanyErrorMessage: null
        }
      };
    }
    case 'POST_COMPANY_START': {
      return {
        ...state,
        ...{
          postCompanyStatus: 'PENDING',
          postCompanyErrorMessage: null
        }
      };
    }
    case 'POST_COMPANY_FAILURE': {
      return {
        ...state,
        ...{
          postCompanyStatus: 'FAILURE',
          postCompanyErrorMessage: action.errorMessage
        }
      };
    }

    case 'POST_COMPANY_RESET': {
      return {
        ...state,
        ...{
          postCompanyStatus: undefined,
          postCompanyResult: undefined,
          postCompanyErrorMessage: null
        }
      };
    }

    case 'GET_COUNTRIES_COMPLETE': {
      return {
        ...state,
        ...{
          getCountriesStatus: 'SUCCESS',
          countries: action.payload,
          getCountriesErrorMessage: null
        }
      };
    }
    case 'GET_COUNTRIES_START': {
      return {
        ...state,
        ...{ getCountriesStatus: 'PENDING', getCountriesErrorMessage: null }
      };
    }
    case 'GET_COUNTRIES_FAILURE': {
      return {
        ...state,
        ...{
          getCountriesStatus: 'FAILURE',
          getCountriesErrorMessage: action.errorMessage
        }
      };
    }

    case 'GET_CUSTOMERS_COMPLETE': {
      return {
        ...state,
        ...{
          getCustomersStatus: 'SUCCESS',
          customers: action.payload,
          getCustomersErrorMessage: null
        }
      };
    }
    case 'GET_CUSTOMERS_START': {
      return {
        ...state,
        ...{ getCustomersStatus: 'PENDING', getCustomersErrorMessage: null }
      };
    }
    case 'GET_CUSTOMERS_FAILURE': {
      return {
        ...state,
        ...{
          getCustomersStatus: 'FAILURE',
          getCustomersErrorMessage: action.errorMessage
        }
      };
    }

    case 'GET_ORGANIZATION_COMPLETE': {
      return {
        ...state,
        ...{
          getOrganizationStatus: 'SUCCESS',
          organizations: action.payload,
          getOrganizationsErrorMessage: null
        }
      };
    }
    case 'GET_ORGANIZATION_START': {
      return {
        ...state,
        ...{
          getOrganizationStatus: 'PENDING',
          getOrganizationsErrorMessage: null
        }
      };
    }
    case 'GET_ORGANIZATION_FAILURE': {
      return {
        ...state,
        ...{
          getOrganizationStatus: 'FAILURE',
          getOrganizationsErrorMessage: action.errorMessage
        }
      };
    }

    case 'GET_OPERATIONALADMIN_COMPLETE': {
      const operationalAdmins = action.payload?.map(
        (item: { userId: string }) => {
          return { ...item, ...{ value: item?.userId, label: item?.userId } };
        }
      );
      return {
        ...state,
        ...{
          getOperationalAdminStatus: 'SUCCESS',
          operationalAdmins: operationalAdmins,
          getOperationalAdminErrorMessage: null
        }
      };
    }
    case 'GET_OPERATIONALADMIN_START': {
      return {
        ...state,
        ...{
          getOperationalAdminStatus: 'PENDING',
          getOperationalAdminErrorMessage: null
        }
      };
    }
    case 'GET_OPERATIONALADMIN_FAILURE': {
      return {
        ...state,
        ...{
          getOperationalAdminStatus: 'FAILURE',
          getOperationalAdminErrorMessage: action.errorMessage
        }
      };
    }

    case 'GET_ALL_COMPANY_TYPES_COMPLETE': {
      return {
        ...state,
        ...{
          getAllCompanyTypesStatus: 'SUCCESS',
          allCompanyTypes: action.payload,
          getAllCompanyTypesErrorMessage: null
        }
      };
    }
    case 'GET_ALL_COMPANY_TYPES_START': {
      return {
        ...state,
        ...{
          getAllCompanyTypesStatus: 'PENDING',
          getAllCompanyTypesErrorMessage: null
        }
      };
    }
    case 'GET_ALL_COMPANY_TYPES_FAILURE': {
      return {
        ...state,
        ...{
          getAllCompanyTypesStatus: 'FAILURE',
          getAllCompanyTypesErrorMessage: action.errorMessage
        }
      };
    }

    case 'GET_GROUPS_COMPLETE': {
      return {
        ...state,
        ...{
          getGroupsStatus: 'SUCCESS',
          groups: action.payload,
          getGroupsErrorMessage: null
        }
      };
    }
    case 'GET_GROUPS_START': {
      return {
        ...state,
        ...{
          getGroupsStatus: 'PENDING',
          getGroupsErrorMessage: null
        }
      };
    }
    case 'GET_GROUPS_FAILURE': {
      return {
        ...state,
        ...{
          getGroupsStatus: 'FAILURE',
          getGroupsErrorMessage: action.errorMessage
        }
      };
    }

    case 'GET_FEDERATED_COMPANY_DOMAINS_COMPLETE': {
      // console.log('action.payload', action.payload);
      // const emailDomains = action.payload?.map((item: any) => {
      //   return '@' + item?.emailDomain;
      // });
      const emailDomains = action.payload?.map((item: any) => {
        return item?.emailDomain;
      });
      return {
        ...state,
        ...{
          getFederatedCompanyDomainsStatus: 'SUCCESS',
          federatedCompanyDomains: emailDomains || [],
          getFederatedCompanyDomainsErrorMessage: null
        }
      };
    }
    case 'GET_FEDERATED_COMPANY_DOMAINS_START': {
      return {
        ...state,
        ...{
          getFederatedCompanyDomainsStatus: 'PENDING',
          getFederatedCompanyDomainsErrorMessage: null,
          federatedCompanyDomains: undefined
        }
      };
    }
    case 'GET_FEDERATED_COMPANY_DOMAINS_FAILURE': {
      return {
        ...state,
        ...{
          getFederatedCompanyDomainsStatus: 'FAILURE',
          getFederatedCompanyDomainsErrorMessage: action.errorMessage,
          federatedCompanyDomains: undefined
        }
      };
    }

    default:
      return state;
  }
};
