import React from 'react';
import { useParams } from 'react-router-dom';

import AuditReportScheduleViewComponent from '../../organisms/AuditReportScheduleView';

const AuditReportScheduleView = () => {
  let { id: scheduleId = '', action = 'view' } = useParams();
  return (
    <div className="container">
      <AuditReportScheduleViewComponent
        scheduleId={scheduleId}
        action={action}
      />
    </div>
  );
};
export default AuditReportScheduleView;
