import React from 'react';

const Loader = ({ arialLabel = null }) => {
  return (
    <div
      className="loading-dots"
      aria-label={arialLabel || 'Loading, please wait...'}
    >
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};
export default Loader;
