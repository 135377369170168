import React, { useState, useEffect } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { Tooltip } from 'react-tooltip';

const PAUserEdit = ({
  title = ` Designated Partner Administrators`,
  paUserList,
  onChange,
  validation
}) => {
  const [paList, setPaList] = useState([
    { firstName: '', lastName: '', email: '' }
  ]);

  useEffect(() => {
    if (paUserList && paUserList?.length) {
      setPaList(paUserList);
    }
  }, [paUserList]);

  const addMorePa = () => {
    const paListTemp = cloneDeep(paList);
    paListTemp.push({ firstName: '', lastName: '', email: '' });
    setPaList(paListTemp);
    if (onChange) onChange(paListTemp);
  };

  const removePa = (index) => {
    const paListTemp = cloneDeep(paList);
    paListTemp.splice(index, 1);
    setPaList(paListTemp);
    if (onChange) onChange(paListTemp);
  };

  const handleUserInput = (event, index) => {
    const paListTemp = cloneDeep(paList);
    let paRow = paListTemp[index];
    paRow = { ...paRow, ...{ [event.target.name]: event.target.value } };
    paListTemp[index] = paRow;
    setPaList(paListTemp);
    if (onChange) onChange(paListTemp);
  };

  return (
    <div className="">
      <div className="card">
        <div className="card-header">
          <h5 className="text-size-16 text-weight-700">{title}</h5>
        </div>

        <div className="card-body">
          {paList &&
            paList?.map(({ firstName, lastName, email }, index) => (
              <div key={`paAdd${index}`} className="row">
                <div className="col-md-3">
                  <div className={`form-group qtr-margin-bottom`}>
                    <div className="form-group__text">
                      <input
                        id={`input-paFirstName${index}`}
                        type="text"
                        value={firstName}
                        name="firstName"
                        onChange={(e) => handleUserInput(e, index)}
                      />
                      <label
                        htmlFor={`input-paFirstName${index}`}
                        className="text-weight-700 required"
                      >
                        First Name
                      </label>
                    </div>
                    {validation &&
                    validation.length &&
                    validation[index]?.firstName ? (
                      <span className="text-danger text-size-12">
                        {validation[index]?.firstName}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className={`form-group qtr-margin-bottom`}>
                    <div className="form-group__text">
                      <input
                        id={`input-paLastName${index}`}
                        type="text"
                        value={lastName}
                        name="lastName"
                        onChange={(e) => handleUserInput(e, index)}
                      />
                      <label
                        htmlFor={`input-paLastName${index}`}
                        className="text-weight-700 required"
                      >
                        Last Name
                      </label>
                    </div>
                    {validation &&
                    validation.length &&
                    validation[index]?.lastName ? (
                      <span className="text-danger text-size-12">
                        {validation[index]?.lastName}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className={`form-group qtr-margin-bottom`}>
                    <div className="form-group__text">
                      <input
                        id={`input-paEmail${index}`}
                        type="email"
                        value={email}
                        name="email"
                        onChange={(e) => handleUserInput(e, index)}
                      />
                      <label
                        htmlFor={`input-paEmail${index}`}
                        className="text-weight-700 required"
                      >
                        Email
                      </label>
                    </div>
                    {validation &&
                    validation.length &&
                    validation[index]?.email ? (
                      <span className="text-danger text-size-12">
                        {validation[index]?.email}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-2">
                  {index === 0 ? (
                    <button
                      className="btn btn--circle btn--ghost"
                      type="button"
                      style={{ marginTop: '23px' }}
                      onClick={addMorePa}
                      data-tooltip-id="tooltip-add-more-pa"
                    >
                      <span className="icon-add"></span>
                      <Tooltip id="tooltip-add-more-pa">
                        <span>Add more PA</span>
                      </Tooltip>
                    </button>
                  ) : null}
                  {index > 0 ? (
                    <button
                      type="button"
                      onClick={() => removePa(index)}
                      className="btn btn--circle btn--ghost"
                      style={{ marginTop: '23px' }}
                      data-tooltip-id="tooltip-remove-pa"
                    >
                      <span className="icon-close"></span>
                      <Tooltip id="tooltip-remove-pa">
                        <span>Remove PA</span>
                      </Tooltip>
                    </button>
                  ) : null}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
export default PAUserEdit;
