import React, { useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import { useSelector } from 'react-redux';

import InlineEdit from '../../../molecules/InlineEdit';
import UserSelect from '../../../molecules/UserSelect';
import { fetchCecUsers, companyRoleUserUpdate } from '../../../../services';
import { SelectOption, colourStyles } from '../../../molecules/Select';
import { PamDetailsTypeDef } from '../../../pages/CompanyOnboard/companyOnboardEditTypes';
import UsersListValue from '../../../atoms/UsersListValue';
import { arraysContainSameElements } from '../../../../helpers/common';

export interface SponsorEditProps {
  isEditable?: boolean;
  title?: React.ReactNode;
  open?: boolean;
  onOpenChange?: Function;
  value?: any;
  onSave?: Function;
  onChange?: Function;
  isLoading?: boolean;
  companyId?: string;
  companyNodeId?: string;
  operationalAdminList: string[];
  sponsorList: string[];
  editSource: string;
}

const PAMEdit = ({
  isEditable = true,
  title,
  open,
  onOpenChange,
  value,
  onSave,
  onChange,
  isLoading,
  companyId,
  companyNodeId,
  operationalAdminList,
  sponsorList,
  editSource
}: SponsorEditProps) => {
  const authData = useSelector((state: any) => state?.auth);
  const { actualUserType } = authData;

  const [edit, setEdit] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [status, setStatus] = useState<string | undefined>();
  const [editValue, setEditValue] = useState<any>();
  const [error, setError] = useState<any | undefined>();

  useEffect(() => {
    setEdit(!!open);
  }, [open]);

  useEffect(() => {
    setEditValue(value);
  }, [value]);

  useEffect(() => {
    validate();
  }, [editValue]);

  // const isValidPam = (newValue = []) => {
  //   const userIdArray = newValue?.map(({ userId }: any) => userId && userId);
  //   if (value?.length)
  //     return value?.some((res: any) => {
  //       return (
  //         userIdArray.includes(res?.userId) &&
  //         res?.activeFlag?.toLowerCase() === 'y'
  //       );
  //     });
  //   return true;
  // };

  const onValueChange = (selectedUsers: any) => {
    //  if (!isValidPam(selectedUsers)) {
    //  return setError({ message: 'You cannot remove all nominated PAM' });
    // }
    const changedValue = selectedUsers;
    setEditValue(changedValue);
    if (onChange) onChange(changedValue);
  };

  const onEditChange = () => {
    setEdit(true);
    if (onOpenChange) {
      onOpenChange(true);
    }
  };

  const onEditCancel = () => {
    setEditValue(value);
    setEdit(false);
    if (onOpenChange) {
      onOpenChange(false);
    }
  };

  const userExistsInAllRole = (val: string) => {
    return operationalAdminList?.includes(val) && sponsorList?.includes(val);
  };

  const validate = (): Boolean => {
    const editedValue = editValue ? editValue : value || [];
    const originalValue = value || [];

    if (!editValue || editValue?.length === 0) {
      setError({ message: 'PAM is required' });
      return false;
    }

    const removeList = originalValue?.filter(
      (item: any) =>
        !editedValue.find((innerItem: any) => innerItem.value === item.value)
    );

    const remainingOriginalActivePAM = originalValue?.filter(
      (item: any) =>
        !removeList.find((innerItem: any) => innerItem.value === item.value) &&
        item?.activeFlag?.toLowerCase() === 'y'
    );

    const existingActivePAM = originalValue?.filter(
      (item: any) => item?.activeFlag?.toLowerCase() === 'y'
    );

    if (remainingOriginalActivePAM.length === 0 && existingActivePAM?.length) {
      setError({
        message:
          'You cannot remove all nominated PAM : ' +
          existingActivePAM?.map((item: SelectOption) => item.value).join(', ')
      });
      return false;
    } else {
      let userHas3roles: SelectOption[] = [];
      editValue?.forEach((item: SelectOption) => {
        if (userExistsInAllRole(item?.value)) {
          userHas3roles.push(item);
        }
      });

      if (userHas3roles.length) {
        setError({
          message: 'Not allowed to add more than 2 roles to the same user',
          users: userHas3roles
        });
        return false;
      } else {
        setError(undefined);
      }

      return true;
    }
  };

  const onClickSave = async () => {
    const editedValue = editValue || [];
    const originalValue = value || [];

    setIsSubmitted(true);

    const addList = editValue
      ?.filter(
        (item: any) =>
          !originalValue?.find(
            (innerItem: any) => innerItem.value === item.value
          )
      )
      ?.map((item: any) => {
        return {
          userId: item?.value,
          email: item?.email,
          firstName: item?.firstName,
          lastName: item?.lastName
        };
      });
    const removeList = originalValue
      .filter(
        (item: any) =>
          !editedValue?.find((innerItem: any) => innerItem.value === item.value)
      )
      ?.map((item: any) => {
        return {
          userId: item?.value,
          email: item?.email,
          firstName: item?.firstName,
          lastName: item?.lastName
        };
      });

    if (!validate()) {
      return;
    }

    setStatus('PENDING');

    try {
      const result = await companyRoleUserUpdate(
        'pam',
        actualUserType
          ? actualUserType?.map((item: string) => item?.toUpperCase())
          : [],
        addList,
        removeList,
        {
          companyId: companyId,
          companyNodeId: companyNodeId
        },
        editSource
      );

      setEdit(false);
      if (onOpenChange) {
        onOpenChange(false);
      }
      setStatus('SUCCESS');
      if (onSave)
        onSave(
          editValue?.length
            ? editValue?.map((item: any) => {
                let itemTemp = item;
                const isNewlyAdded = addList?.find((innerItem: any) => {
                  return innerItem.userId === item.userId;
                });
                if (isNewlyAdded) {
                  itemTemp.activeFlag = 'n';
                  itemTemp.status = 'PENDING';
                }
                return itemTemp;
              })
            : []
        );
    } catch (err) {
      setStatus('ERROR');
    }
  };

  const loadCecUsers = (inputValue: any, callback: Function) => {
    if (inputValue?.length > 2) {
      fetchCecUsers(inputValue).then((result: any) => {
        callback(
          result?.map((item: any) => {
            return { ...item, label: item.userId, value: item.userId };
          }) || []
        );
      });
    } else {
      callback([]);
    }
  };

  const loadOptions = debounce(loadCecUsers, 600);

  return (
    <div>
      {isSubmitted && edit && error?.message ? (
        <div className="alert alert--warning">
          <div className="alert__icon icon-warning-outline"></div>
          <div className="alert__message">
            {error?.message}

            {error?.users
              ? ' - ' +
                error?.users?.map((item: SelectOption) => item.value).join(' ,')
              : null}
          </div>
        </div>
      ) : null}

      <InlineEdit
        isDisableSave={arraysContainSameElements(
          editValue?.map(({ email }: any) => email),
          value?.map(({ email }: any) => email)
        )}
        isEditable={isEditable}
        title={title}
        readContent={
          <div className="edit-form-value-holder">
            <UsersListValue users={editValue || []} />
            {/* {editValue?.map((item: any) => item.value).join(', ')} */}
          </div>
        }
        isEditing={edit}
        onChangeEdit={onEditChange}
        onCancelEdit={onEditCancel}
        isLoading={status === 'PENDING' || isLoading}
        editContent={
          <div className="edit-form-value-holder">
            <UserSelect
              isClearable
              value={editValue}
              controlShouldRenderValue={false}
              name="pam"
              isMulti
              cacheOptions
              placeholder={'Search PAM....'}
              loadOptions={loadOptions}
              onChange={onValueChange}
            />
          </div>
        }
        onSave={onClickSave}
      />
    </div>
  );
};

export default PAMEdit;
