import React from 'react';

import SearchUsers from '../../organisms/SearchUsers';

const UsersManage = () => {
  return (
    <div className="container">
      <div className="section">
        <SearchUsers />
      </div>
    </div>
  );
};
export default UsersManage;
