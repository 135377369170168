import React from 'react';
import { Link } from 'react-router-dom';

const RequestStatus = () => {
  return (
    <div className="vertical-center">
      <div className="container">
        <div className="section">
          <div className="text-center">
            <h1 className="text-size-128">404</h1>
            <h2 className="text-size-64">Page Not Found</h2>
            <h4>The Page your looking for {" doesn't "} exist</h4>
            <h4 className="">
              Go to <Link to="/">Home Page</Link>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RequestStatus;
