import React from 'react';
import { useParams } from 'react-router-dom';

import ExternalUserDetailComponent from '../../molecules/ExternalUserDetail';

const CompanyRevalidationDetails = () => {
  let { id: userId = '', action = 'details' } = useParams();
  return (
    <div className="container">
      <ExternalUserDetailComponent userId={userId} action={action} />
    </div>
  );
};
export default CompanyRevalidationDetails;
