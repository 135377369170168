import React from 'react';
import CreatableSelect from 'react-select/creatable';

import {
  theme,
  colourStyles,
  DropdownIndicator,
  IndicatorSeparator,
  SingleValue
} from './Select';

const CreatableSelectComponent = ({ ...props }) => {
  return (
    <CreatableSelect
      className="basic-multi-select"
      theme={theme}
      styles={colourStyles}
      components={{ DropdownIndicator, IndicatorSeparator, SingleValue }}
      {...props}
    />
  );
};
export default CreatableSelectComponent;
