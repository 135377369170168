import React, { useState, useEffect } from 'react';

const Accordion = ({ title, content, open }) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (typeof open !== 'undefined') {
      setIsActive(!!open);
    }
  }, [open]);

  return (
    <div className="accordion-item">
      <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
        <div className="qtr-margin-right">
          <span
            className={isActive ? 'icon-chevron-up' : 'icon-chevron-down'}
          ></span>
        </div>
        <div>{title}</div>
      </div>
      {isActive && <div className="accordion-content">{content}</div>}
    </div>
  );
};

export default Accordion;
