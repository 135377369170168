import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import InlineEdit from '../../../molecules/InlineEdit';
import CreatableSelect from '../../../molecules/Select/CreatableSelect';
import { SelectOption } from '../../../molecules/Select';
import notify from '../../../../helpers/notification';
import httpUtil from '../../../../helpers/interceptor';
import { fetchGroups } from '../../../../actions';
import { arraysContainSameElements } from '../../../../helpers/common';

export interface GroupEditProps {
  isEditable?: boolean;
  title?: React.ReactNode;
  open?: boolean;
  onOpenChange?: Function;
  companyType?: string;
  value?: any;
  userType?: string[];
  isLoading?: boolean;
  companyId?: string;
  companyNodeId?: string;
  updateGroupFormValue?: Function;
}

function GroupEdit({
  isEditable = true,
  title,
  open,
  onOpenChange,
  value,
  isLoading = false,
  userType,
  companyId,
  companyNodeId,
  updateGroupFormValue
}: GroupEditProps) {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [status, setStatus] = useState<string | undefined>();
  const [editValue, setEditValue] = useState<any>();
  const [error, setError] = useState<any | undefined>();
  const [inputValue, setInputValue] = useState('');

  const companyOnboardData = useSelector((state: any) => state.onboardCompany);
  const { groups = [], getGroupsStatus } = companyOnboardData;

  useEffect(() => {
    setEdit(!!open);
  }, [open]);

  useEffect(() => {
    if (edit) {
      dispatch(fetchGroups());
    }
  }, [edit]);

  useEffect(() => {
    setEditValue(value);
  }, [value]);

  useEffect(() => {
    // validate();
  }, [editValue]);

  const onEditChange = () => {
    setEdit(true);
    if (onOpenChange) {
      onOpenChange(true);
    }
  };

  const onEditCancel = () => {
    setEditValue(value);
    setEdit(false);
    if (onOpenChange) {
      onOpenChange(false);
    }
    setIsSubmitted(false);
  };

  const onClickSave = () => {
    setIsSubmitted(true);
    setError(undefined);
    const error: any = {};
    if (!editValue) {
      error.message = 'Group Name is required';
    }

    if (Object.keys(error).length > 0) {
      return setError({ ...error });
    }

    const payload = {
      companyId,
      companyNodeId,
      userType: userType
        ? userType?.map((item: string) => item?.toUpperCase())
        : [],
      groupName: editValue
    };
    setStatus('PENDING');

    httpUtil
      .post(`/api/am/dashboard/v1/company/groups`, payload)
      .then((_) => {
        setStatus('SUCCESS');
        setEdit(false);
        if (onOpenChange) {
          onOpenChange(false);
        }

        if (updateGroupFormValue)
          updateGroupFormValue({
            groupName: editValue
          });

        notify('Group Name is updated successfully.', 'success');
      })
      .catch((error: any) => {
        setStatus('ERROR');
        const errorMessage = `An error occurred while updating Group Name ${
          error?.response?.data?.error
            ? ': ' + error?.response?.data?.error
            : ''
        }`;
        notify(errorMessage, 'error');
      });
  };

  const onSelectorChange = (
    newValue:
      | SelectOption
      | SelectOption[]
      | string[]
      | string
      | undefined
      | number,
    name: string
  ) => {
    setEditValue(newValue);
  };

  const handleInputChange = (inputValue: any, actionMeta: any) => {
    if (actionMeta.action === 'input-change') {
      let sanitizedValue = inputValue
        .replace(/[^a-zA-Z0-9_ -]/g, '')
        .toUpperCase();
      sanitizedValue = sanitizedValue.replace(/\s+/g, ' ');
      setInputValue(sanitizedValue);
    }
  };

  return (
    <div>
      {isSubmitted && edit && error?.message ? (
        <div className="alert alert--warning">
          <div className="alert__icon icon-warning-outline"></div>
          <div className="alert__message">{error?.message}</div>
        </div>
      ) : null}

      <InlineEdit
        isDisableSave={arraysContainSameElements(value, editValue)}
        isEditable={isEditable}
        title={title}
        readContent={<div className="edit-form-value-holder">{editValue}</div>}
        isEditing={edit}
        onChangeEdit={onEditChange}
        onCancelEdit={onEditCancel}
        isLoading={
          status === 'PENDING' || isLoading || getGroupsStatus === 'PENDING'
        }
        editContent={
          <div className="form-group__text">
            <CreatableSelect
              name="groupName"
              isClearable
              options={
                groups?.map((item: any) => {
                  return {
                    value: item,
                    label: item
                  };
                }) || []
              }
              value={
                editValue
                  ? {
                      value: editValue,
                      label: editValue
                    }
                  : undefined
              }
              inputValue={inputValue}
              onInputChange={handleInputChange}
              onChange={(newValue: any) => {
                const isNew = newValue?.__isNew__ as any;
                let value = newValue?.value;
                if (isNew) {
                  value = value.replace(/[^a-zA-Z0-9_ -]/g, '').toUpperCase();
                  value = value.replace(/\s+/g, ' ').trim();
                }
                setInputValue('');
                onSelectorChange(value, 'groupName');
              }}
            />
          </div>
        }
        onSave={onClickSave}
      />
    </div>
  );
}

export default GroupEdit;
