import httpUtil from '../helpers/interceptor';
import notify from '../helpers/notification';

export const fetchUserReviewList = (body: {} = {}) => {
  return (dispatch: Function) => {
    dispatch({ type: 'GET_USER_REVIEW_LIST_START' });
    return httpUtil
      .post(`/api/user/revalidation/v1/get/userRevalidate`, body)
      .then((res) => {
        dispatch({
          type: 'GET_USER_REVIEW_LIST_COMPLETE',
          payload: { data: res || [], filter: { ...body } }
        });
        return res;
      })
      .catch((error) => {
        let errorMessage = '';
        if (error?.response?.status != 401) {
          errorMessage = `An error occurred while fetching users review list ${
            error?.response?.data?.error
              ? ': ' + error?.response?.data?.error
              : ''
          }`;
          notify(errorMessage, 'error');
        }
        dispatch({
          type: 'GET_USER_REVIEW_LIST_FAILURE',
          errorMessage: errorMessage
        });
      });
  };
};

export const fetchExternalUsersList = (body: {}, filters = []) => {
  return (dispatch: Function) => {
    dispatch({ type: 'GET_EXTERNAL_USERS_LIST_START' });
    return httpUtil
      .post(`/api/am/dashboard/v1/external/deactivate/usersSearch`, body)
      .then((res) => {
        dispatch({
          type: 'GET_EXTERNAL_USERS_LIST_COMPLETE',
          payload: { data: res?.data || [], filter: { ...body, filters } }
        });
        return res;
      })
      .catch((error) => {
        let errorMessage = '';
        if (error?.response?.status != 401) {
          errorMessage = `An error occurred while fetching external users ${
            error?.response?.data?.error
              ? ': ' + error?.response?.data?.error
              : ''
          }`;
          notify(errorMessage, 'error');
        }
        dispatch({
          type: 'GET_EXTERNAL_USERS_LIST_FAILURE',
          errorMessage: errorMessage
        });
      });
  };
};
