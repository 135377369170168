import { store } from './interceptor';

// import { roles } from '../config/rolesPermissions';
import { Permission, RolePermission } from '../interfaces/permission';

export interface AccessCondition {
  roleName: string;
  exist: boolean;
}

const checkAccess = (rolePermission: any, resource: string, action: string) => {
  let hasAccessToResource = false;

  for (let i = 0; i < rolePermission?.permissions?.length; i++) {
    if (rolePermission?.permissions[i] === '*') {
      hasAccessToResource = true;
      return hasAccessToResource;
    } else {
      if (rolePermission?.permissions[i]?.resource === resource) {
        if (!action) {
          hasAccessToResource = true;
          return hasAccessToResource;
        } else {
          if (
            rolePermission?.permissions[i]?.actions?.includes('*') ||
            rolePermission?.permissions[i]?.actions?.includes(action)
          ) {
            hasAccessToResource = true;
            return hasAccessToResource;
          }
        }
      }
    }
  }

  return hasAccessToResource;
};

export const isFederatedUserHasAccess = (
  resource: string = '',
  action: string = ''
) => {
  if (resource === 'requests') {
    return false;
  }
  return true;
};

export const hasAccessForResourceRole = (
  conditions: AccessCondition[],
  resource: string = '',
  action: string = ''
) => {
  let { userActiveRolePermissions = [], rolePermissions } = (store?.getState()
    ?.auth || {}) as {
    userActiveRolePermissions: RolePermission[] | undefined;
    rolePermissions: RolePermission[] | undefined;
  };

  const resourceRole = rolePermissions
    ?.filter((item: RolePermission) => {
      return conditions?.find(
        (innerItem: AccessCondition) => innerItem?.roleName === item?.name
      );
    })
    .map((item: RolePermission) => {
      const condition = conditions?.find(
        (innerItem: AccessCondition) => innerItem?.roleName === item?.name
      );
      return { ...item, exist: condition?.exist };
    });

  const userActiveRolePermissionsForResource = resourceRole?.length
    ? userActiveRolePermissions?.filter((item: RolePermission) => {
        const find = resourceRole?.find(
          (innerItem: any) => innerItem?.priority === item?.priority
        );

        return (find && find?.exist) || !find;
      })
    : userActiveRolePermissions;

  return hasAccess(resource, action, userActiveRolePermissionsForResource);
};

export const hasAccess = (
  resource: string = '',
  action: string = '',
  activeRoles: RolePermission[] | undefined = undefined
) => {
  let {
    userActiveRolePermissions = [],
    userType,
    mainUserType,
    isFederatedUser
  } = (store?.getState()?.auth || {}) as {
    userActiveRolePermissions: RolePermission[] | undefined;
    userType?: string[];
    mainUserType?: string;
    isFederatedUser?: boolean;
  };

  let activeRolePermissions: RolePermission[] | undefined =
    userActiveRolePermissions;
  if (activeRoles) {
    activeRolePermissions = activeRoles;
  }

  const allUserTypes = userType?.length
    ? [...userType, ...[mainUserType]]
    : [mainUserType];

  // FEDERATED USER SHOULD NOT HAVE REQUEST ACCESS
  if (
    isFederatedUser &&
    !(allUserTypes?.includes('pa') || allUserTypes?.includes('pam')) &&
    !isFederatedUserHasAccess(resource, action)
  ) {
    return false;
  }

  // Requests is allowed for all
  if (resource === 'requests') {
    if (
      !allUserTypes?.includes('pa') &&
      !allUserTypes?.includes('pam') &&
      action === 'readHistory'
    ) {
      return false;
    }

    return true;
  }

  let hasActionAccess = false;
  for (let i = 0; i < activeRolePermissions?.length; i++) {
    const rolePermission = activeRolePermissions[i];
    if (rolePermission) {
      const check = checkAccess(rolePermission, resource, action);
      if (check) {
        hasActionAccess = check;
        return hasActionAccess;
      }
    }
  }

  return hasActionAccess;
};

/* Check user has permission to visit page */
export const hasRouteAccess = (routePermissions: Permission[]) => {
  let {
    userActiveRolePermissions = [],
    userType,
    mainUserType,
    isFederatedUser
  } = (store?.getState()?.auth || {}) as {
    userActiveRolePermissions: RolePermission[] | undefined;
    userType?: string[];
    mainUserType?: string;
    isFederatedUser?: boolean;
  };

  const allUserTypes = userType?.length
    ? [...userType, ...[mainUserType]]
    : [mainUserType];

  if (
    isFederatedUser &&
    !(allUserTypes?.includes('pa') || allUserTypes?.includes('pam'))
  ) {
    // FEDERATED USER SHOULD NOT HAVE Request Access
    for (let i = 0; i < routePermissions?.length; i++) {
      for (let j = 0; j < routePermissions[i]?.actions?.length; j++) {
        if (
          !isFederatedUserHasAccess(
            routePermissions[i]?.resource,
            routePermissions[i]?.actions[j]
          )
        ) {
          return false;
        }
      }
    }
  }

  if (!allUserTypes?.includes('pa') && !allUserTypes?.includes('pam')) {
    for (let i = 0; i < routePermissions?.length; i++) {
      for (let j = 0; j < routePermissions[i]?.actions?.length; j++) {
        if (
          routePermissions[i]?.resource === 'requests' &&
          routePermissions[i]?.actions[j] === 'readHistory'
        ) {
          return false;
        }
      }
    }
  }

  // // Request is allowed for all
  // for (let i = 0; i < routePermissions?.length; i++) {
  //   for (let j = 0; j < routePermissions[i]?.actions?.length; j++) {
  //     if (routePermissions[i]?.resource === 'requests') {
  //       return true;
  //     }
  //   }
  // }

  let hasActionAccess = false;

  for (let k = 0; k < userActiveRolePermissions?.length; k++) {
    const rolePermission = userActiveRolePermissions[k];
    if (rolePermission) {
      for (let i = 0; i < routePermissions?.length; i++) {
        for (let j = 0; j < routePermissions[i]?.actions?.length; j++) {
          const check = checkAccess(
            rolePermission,
            routePermissions[i]?.resource,
            routePermissions[i]?.actions[j]
          );
          if (check) {
            hasActionAccess = check;
            return hasActionAccess;
          }
        }
      }
    }
  }

  return hasActionAccess;
};
