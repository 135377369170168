import React, { useEffect, useState } from 'react';

import Loader from '../../atoms/Loader';
import './index.css';

export interface InlineEditProps {
  isEditable?: boolean;
  title?: React.ReactNode;
  isDisableSave?: boolean;
  readContent?: React.ReactNode;
  editContent?: React.ReactNode;
  isEditing?: boolean;
  onSave?: Function;
  onChangeEdit?: Function;
  onCancelEdit?: Function;
  isLoading?: boolean;
  hideSaveCancelButton?: boolean;
}

const InlineEdit = ({
  isEditable = true,
  title,
  isDisableSave = false,
  readContent,
  editContent,
  isEditing,
  onSave,
  onChangeEdit,
  onCancelEdit,
  isLoading,
  hideSaveCancelButton
}: InlineEditProps) => {
  const [edit, setEdit] = useState<boolean>(false);

  const onClickEdit = () => {
    if (onChangeEdit) onChangeEdit(true);
    setEdit(true);
  };

  const onClickCancel = () => {
    if (onCancelEdit) onCancelEdit(false);
  };

  const onClickSave = () => {
    if (onSave) onSave();
  };

  useEffect(() => {
    if (isEditing !== undefined) setEdit(isEditing);
  }, [isEditing]);

  return (
    <div className="panel panel--bordered inline-edit">
      <div className="inline-edit-headers">
        <div className="inline-edit-title">{title}</div>
        {isEditable ? (
          <>
            {hideSaveCancelButton ? null : edit ? (
              <div className="inline-edit-actions">
                <span
                  className={`label label--tiny  ${
                    isDisableSave
                      ? 'label--light inline-edit-actions-item-disabled'
                      : 'inline-edit-actions-item'
                  }`}
                  onClick={isDisableSave ? undefined : onClickSave}
                >
                  Save
                </span>

                <span
                  className="label label--dark label--tiny inline-edit-actions-item"
                  onClick={onClickCancel}
                >
                  Cancel
                </span>
              </div>
            ) : (
              <div className="inline-edit-actions">
                <span
                  className="label label--tiny inline-edit-actions-item"
                  onClick={onClickEdit}
                >
                  Edit
                </span>
              </div>
            )}
          </>
        ) : null}
      </div>
      {edit && isEditable ? (
        <div className=""> {editContent} </div>
      ) : (
        readContent
      )}
      {isLoading ? (
        <div className="center-holder-wrap">
          <Loader />
        </div>
      ) : null}
    </div>
  );
};

export default InlineEdit;
