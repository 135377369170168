import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import httpUtil from '../../../helpers/interceptor';
import notify from '../../../helpers/notification';
import FullScreenLoader from '../../molecules/FullScreenLoader';
import InvitationApproval from '../../organisms/InvitationApproval';

const MailApproval = () => {
  let { id: inviteId = '' } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [invite, setInvite] = useState<any>({});

  useEffect(() => {
    getInviteDetails();
  }, [inviteId]);

  const getInviteDetails = () => {
    let apiUrl = '/api/am/dashboard/v1/onBoardingDetails';
    httpUtil
      .post(apiUrl, { inviteId })
      .then((res: any) => {
        setIsLoading(false);
        setInvite(res?.payLoad ? res?.payLoad : {});
        return res;
      })
      .catch((error) => {
        setIsLoading(false);
        let errorMessage = '';
        if (error?.response?.status != 401) {
          errorMessage = `An error occurred while fetching invite ${
            error?.response?.data?.error
              ? ': ' + error?.response?.data?.error
              : ''
          }`;
          notify(errorMessage, 'error');
        }
      });
  };

  return (
    <div className="">
      {isLoading && <FullScreenLoader />}
      {!isLoading && (
        <div className="container">
          <div className="section">
            <div className="row d-flex justify-content-center">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-header">
                    <h3 className="" style={{ marginBottom: '0px' }}>
                      Invitation
                    </h3>
                  </div>
                  <div className="card-body">
                    <InvitationApproval invitation={invite} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default MailApproval;
