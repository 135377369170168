import React from 'react';

import SearchCompaniesTable from '../../organisms/SearchCompaniesTable';

const CompanyManage = () => {
  return (
    <div className="container">
      <div className="section">
        <SearchCompaniesTable />
      </div>
    </div>
  );
};
export default CompanyManage;
