const initialState = {};

export const companyRegisterReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'COMPANY_TYPE_COMPLETE': {
      return {
        ...state,
        ...{
          companyTypeStatus: 'SUCCESS',
          companyTypes: action.payload,
          companyTypeErrorMessage: null
        }
      };
    }
    case 'COMPANY_TYPE_START': {
      return {
        ...state,
        ...{ companyTypeStatus: 'PENDING', companyTypeErrorMessage: null }
      };
    }
    case 'COMPANY_TYPE_FAILURE': {
      return {
        ...state,
        ...{
          companyTypeStatus: 'FAILURE',
          companyTypeErrorMessage: action.errorMessage
        }
      };
    }
    case 'COMPANY_LIST_COMPLETE': {
      return {
        ...state,
        ...{
          companyListStatus: 'SUCCESS',
          companyList: action.payload || [],
          companyListErrorMessage: null
        }
      };
    }
    case 'COMPANY_LIST_START': {
      return {
        ...state,
        ...{ companyListStatus: 'PENDING', companyListErrorMessage: null }
      };
    }
    case 'COMPANY_LIST_FAILURE': {
      return {
        ...state,
        ...{
          companyListStatus: 'FAILURE',
          companyListErrorMessage: action.companyListErrorMessage
        }
      };
    }

    case 'ORG_LIST_COMPLETE': {
      return {
        ...state,
        ...{
          orgListStatus: 'SUCCESS',
          orgList: action.payload,
          orgListErrorMessage: null
        }
      };
    }
    case 'ORG_LIST_START': {
      return {
        ...state,
        ...{
          orgListStatus: 'PENDING',
          orgListErrorMessage: null
        }
      };
    }
    case 'ORG_LIST_FAILURE': {
      return {
        ...state,
        ...{
          orgList: [],
          orgListStatus: 'FAILURE',
          orgListErrorMessage: action.orgListErrorMessage
        }
      };
    }

    case 'PROFILE_SUBMIT_COMPLETE': {
      return {
        ...state,
        ...{
          profileSubmitStatus: 'SUCCESS',
          profileSubmitResponse: action.payload,
          profileSubmitErrorMessage: null
        }
      };
    }
    case 'PROFILE_SUBMIT_START': {
      return {
        ...state,
        ...{
          profileSubmitStatus: 'PENDING',
          profileSubmitErrorMessage: null
        }
      };
    }
    case 'PROFILE_SUBMIT_FAILURE': {
      return {
        ...state,
        ...{
          profileSubmitStatus: 'FAILURE',
          profileSubmitErrorMessage: action.errorMessage
        }
      };
    }

    case 'PROFILE_SUBMIT_RESET': {
      return {
        ...state,
        ...{
          profileSubmitStatus: undefined,
          profileSubmitErrorMessage: null
        }
      };
    }

    case 'GET_PROFILE_COMPLETE': {
      return {
        ...state,
        ...{
          getProfileStatus: 'SUCCESS',
          profile: action.payload,
          getProfileErrorMessage: null
        }
      };
    }
    case 'GET_PROFILE_START': {
      return {
        ...state,
        ...{
          getProfileStatus: 'PENDING',
          getProfileErrorMessage: null
        }
      };
    }
    case 'GET_PROFILE_FAILURE': {
      return {
        ...state,
        ...{
          getProfileStatus: 'FAILURE',
          getProfileErrorMessage: action.errorMessage
        }
      };
    }

    default:
      return state;
  }
};
