import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import RequestsHandle from '../../organisms/RequestsHandle';

const Requests = () => {
  const requestData = useSelector((state) => state.request);
  const companyRegisterData = useSelector((state) => state.companyRegisterData);
  const authData = useSelector((state) => state.auth);
  const { isFederatedUser, userType = [] } = authData;

  const {
    getActiveRequestStatus,
    activeRequest = { response: [] },
    requesterActiveRequest = [],
    getActiveRequestErrorMessage,
    getUpdateRequestStatus
  } = requestData;

  const { profileSubmitStatus } = companyRegisterData;

  const { response = [] } = activeRequest;

  useEffect(() => {
    if (getActiveRequestStatus != 'SUCCESS') {
      //  dispatch(fetchUserActiveRequest());
    }
  }, []);

  const isRequestProfileStatusPending = () => {
    let isExist = false;
    requesterActiveRequest?.forEach(({ status, profileStatus }) => {
      if (
        status?.toLowerCase() === 'approved' &&
        profileStatus?.toLowerCase() === 'pending'
      ) {
        isExist = true;
      }
    });

    return isExist;
  };

  return (
    <div className="container">
      <div className="section">
        {userType.includes('requester') && !profileSubmitStatus && (
          <div className="">
            <h5>
              Please find the request details for your Supply Chain access.
            </h5>
            {/* {isRequestProfileStatusPending() && (
              <div className="base-margin-top">
                <div className="alert alert--warning">
                  <div className="alert__message">
                    <div>
                      Your Supply Chain profile creation request has been
                      approved.
                    </div>
                    <div className="qtr-margin-top">
                      Your account has not been provisioned. Please contact{' '}
                      <a href="mailto:sciam-support@cisco.com">
                        sciam-support@cisco.com
                      </a>{' '}
                      for Supply Chain access provisioning.
                    </div>
                  </div>
                </div>
              </div>
            )} */}
          </div>
        )}
        <div className="">
          <RequestsHandle
            requestTableType={
              userType.includes('pa') || userType.includes('pam')
                ? 'activeRequest'
                : 'activeRequestorRequest'
            }
          />
        </div>
      </div>
    </div>
  );
};
export default Requests;
