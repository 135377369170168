import React from 'react';
import { Link } from 'react-router-dom';

const Unauthorized = () => {
  return (
    <div className="vertical-center">
      <div className="container">
        <div className="section">
          <div className="text-center">
            <h1 className="text-size-128">401</h1>
            <h2 className="text-size-64">Unauthorized Access</h2>
            <h4>Your account does not have access to this page.</h4>
            <h4 className="">
              Go to <Link to="/">Home Page</Link>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Unauthorized;
