import React from 'react';
import Select, {
  components,
  ThemeConfig,
  IndicatorSeparatorProps,
  DropdownIndicatorProps,
  StylesConfig,
  SingleValueProps,
  GroupBase
} from 'react-select';

import './index.css';

const indicatorSeparatorStyle = {
  alignSelf: 'stretch',
  marginBottom: 8,
  marginTop: 8,
  width: 1
};

export interface SelectOption {
  readonly value: string;
  readonly label: string;
  readonly color?: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
  [key: string]: any;
}

export const IndicatorSeparator = ({
  innerProps
}: IndicatorSeparatorProps<any, boolean, GroupBase<any>>) => {
  return <span style={indicatorSeparatorStyle} {...innerProps} />;
};

const indicatorStyle = {
  color: 'inherit'
};

export const DropdownIndicator = (
  props: DropdownIndicatorProps<any, boolean, GroupBase<any>>
) => {
  return (
    <components.DropdownIndicator {...props}>
      <span
        style={indicatorStyle}
        className="icon-dropdown text-size-10"
      ></span>
    </components.DropdownIndicator>
  );
};

export const SingleValue = ({ children, ...props }: SingleValueProps<any>) => (
  <components.SingleValue {...props}>{children}</components.SingleValue>
);

export const colourStyles: StylesConfig<any> = {
  container: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: 'white',
      borderColor: isFocused ? 'red' : 'pink'
    };
  },
  control: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: 'white',
      minHeight: '35px',
      border: isFocused
        ? '1px solid var(--cui-accent-color)'
        : 'var(--cui-border)',
      boxShadow: isFocused
        ? '0 0 0 var(--cui-focus-thickness) rgba(var(--cui-focus-color), var(--cui-focus-opacity))'
        : 'none',
      '&:hover': {
        border: '1px solid var(--cui-accent-color)'
      }
    };
  },
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? '#ced4da'
        : isFocused
        ? '#f2f2f2'
        : '#fff',
      color: isDisabled ? '#ccc' : isSelected ? 'white' : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : '#fff'
          : undefined,
        border: !isDisabled
          ? isSelected
            ? data.color
            : '1px solid #00bceb'
          : undefined
      }
    };
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: '#fff',
      border: '1px solid #00bceb',
      borderRadius: '2px'
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: 'white'
    }
  }),
  singleValue: (base) => ({
    ...base,
    padding: 3,
    borderRadius: 2,
    background: '#fff',
    border: '1px solid #00bceb'
  })
};

export const theme: ThemeConfig = {
  spacing: { baseUnit: 2 },
  borderRadius: 2
} as ThemeConfig;

const SelectComponent = ({ ...props }) => {
  return (
    <Select
      className="basic-multi-select"
      theme={theme}
      styles={colourStyles}
      components={{
        DropdownIndicator,
        IndicatorSeparator,
        SingleValue: SingleValue
      }}
      {...props}
    />
  );
};

export default SelectComponent;
