import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// import RequestStatusTable from '../../organisms/RequestStatus';
import {
  fetchRequesterActiveRequest,
  fetchUserActiveRequest,
  fetchRequestHistory
} from '../../../actions';
import RequestTable from '../RequestTable';

const RequestsHandle = ({ requestTableType = '' }) => {
  const dispatch = useDispatch();
  const requestData = useSelector((state: any) => state.request);
  const authData = useSelector((state: any) => state.auth);
  const { userType: userTypes = [], user, actualUserType } = authData;

  const {
    getRequesterActiveRequestStatus,
    requesterActiveRequest,
    getRequesterActiveRequestErrorMessage,
    getRequestHistoryStatus,
    requestHistory,
    getRequestHistoryErrorMessage,
    getActiveRequestStatus,
    activeRequest,
    getActiveRequestErrorMessage
  } = requestData;

  useEffect(() => {
    if (requestTableType == 'activeRequestorRequest') {
      dispatch(fetchRequesterActiveRequest());
    } else if (requestTableType == 'activeRequest') {
      dispatch(
        fetchUserActiveRequest({
          userType: actualUserType
            ? actualUserType?.map((item: string) => item?.toUpperCase())
            : []
        })
      );
    } else if (requestTableType == 'requestHistory') {
      dispatch(
        fetchRequestHistory(
          actualUserType
            ? actualUserType?.map((item: string) => item?.toUpperCase())
            : []
        )
      );
    }
  }, [requestTableType]);

  return (
    <>
      {requestTableType === 'activeRequestorRequest' ? (
        <RequestTable
          title={''}
          requestTableType="activeRequestorRequest"
          requesterStatusTable={true}
          requests={
            requesterActiveRequest && requesterActiveRequest?.length
              ? { response: requesterActiveRequest }
              : null
          }
          status={getRequesterActiveRequestStatus}
          errorMessage={getRequesterActiveRequestErrorMessage}
        />
      ) : null}

      {requestTableType === 'activeRequest' ? (
        <RequestTable
          title={''}
          requestTableType="activeRequest"
          requesterStatusTable={true}
          requests={
            activeRequest && activeRequest?.length
              ? { response: activeRequest }
              : null
          }
          status={getActiveRequestStatus}
          errorMessage={getActiveRequestErrorMessage}
        />
      ) : null}

      {requestTableType === 'requestHistory' ? (
        <RequestTable
          title={''}
          requestTableType="requestHistory"
          requesterStatusTable={false}
          requests={requestHistory}
          status={getRequestHistoryStatus}
          errorMessage={getRequestHistoryErrorMessage}
        />
      ) : null}
    </>
  );
};
export default RequestsHandle;
