import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { checkDomainCompanyRegistered } from '../../../actions/AuthActions';

import FullScreenLoader from '../FullScreenLoader';

const DomainCheckWrapper = ({ children, checkDomain = true }) => {
  const dispatch = useDispatch();
  const userDetailsData = useSelector((state) => state.auth);
  const {
    domainCompanyRegisteredStatus,
    isDomainCompanyRegistered,
    domainCompanyRegisteredErrorMessage
  } = userDetailsData;

  useEffect(() => {
    if (checkDomain) {
      dispatch(checkDomainCompanyRegistered());
    }
  }, [checkDomain]);

  if (!checkDomain) {
    return children;
  } else {
    switch (true) {
      case domainCompanyRegisteredStatus === 'PENDING':
        return <FullScreenLoader title={'Fetching user details'} />;

      case domainCompanyRegisteredStatus === 'SUCCESS' &&
        !isDomainCompanyRegistered:
        return (
          <div>
            <div className="alert alert--warning">
              <div className="alert__message">
                <div>Companies are not mapped to the email domain.</div>
                <div className="qtr-margin-top">
                  Please contact{' '}
                  <a href="mailto:sciam-support@cisco.com">
                    sciam-support@cisco.com
                  </a>{' '}
                  to complete user onboarding.
                </div>
              </div>
            </div>
          </div>
        );

      case domainCompanyRegisteredStatus === 'SUCCESS' &&
        isDomainCompanyRegistered:
        return children;

      case domainCompanyRegisteredStatus === 'FAILURE' &&
        domainCompanyRegisteredErrorMessage:
        return (
          <div className="base-margin-top">
            <div className="alert alert--warning">
              <div className="alert__message">
                {domainCompanyRegisteredErrorMessage}
              </div>
            </div>
          </div>
        );

      default:
        return <></>;
    }
  }
};

export default DomainCheckWrapper;
