import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Loader from '../../atoms/Loader';
import notify from '../../../helpers/notification';
import Dialog from '../../atoms/Dialog';
import {
  DeactivatePayload,
  deactivateUser
} from '../../../services/UsersService';
import { isItemInArrayIgnoreCase } from '../../../helpers/common';

export interface UserActionDialogProps {
  buttonTitle?: string;
  details: any;
  action: string;
  onSuccess?: Function;
  open: boolean;
  onChange?: Function;
}

const UserActionDialog = ({
  buttonTitle,
  details,
  action,
  onSuccess,
  open,
  onChange
}: UserActionDialogProps) => {
  const authData = useSelector((state: any) => state.auth);
  const { actualUserType } = authData;
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [remark, setRemark] = useState('');
  const [validation, setValidation] = useState<{ remark?: string }>({});
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [statusAction, setStatusAction] = useState('');
  const [submitted, setSubmitted] = useState<boolean>(false);

  useEffect(() => {
    setConfirmDialogOpen(!!open);
  }, [open]);

  useEffect(() => {
    return () => {
      setRemark('');
    };
  }, [details]);

  useEffect(() => {
    setStatusAction(action);
    setRemark('');
    setSubmitted(false);
  }, [action]);

  useEffect(() => {
    checkValidation(true);
  }, [remark, statusAction]);

  const onRemarkChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setRemark(event.target.value);
  };
  const checkValidation = (isRemarkRequired = false) => {
    let errors: { remark?: string } = { ...validation };
    let remarkValue = remark?.trim();

    if (!remarkValue && isRemarkRequired) {
      errors.remark = 'Required';
    } else if (isRemarkRequired && remarkValue && remarkValue.length < 25) {
      errors.remark = 'Justification must be at least 25 characters';
    } else if (isRemarkRequired && remarkValue && remarkValue.length > 250) {
      errors.remark = "You can't write more than 250 characters";
    } else {
      delete errors.remark;
    }
    setValidation(errors);
    return Object.keys(errors).length === 0;
  };

  const updatedCompanyAccount = async (
    action: string,
    userUpdateData: DeactivatePayload
  ) => {
    if (checkValidation(true)) {
      try {
        setIsUpdateLoading(true);
        const result: any = await deactivateUser(userUpdateData);
        setIsUpdateLoading(false);
        const responseStatus = result?.status?.toLowerCase();
        if (responseStatus === 'success') {
          notify(`User has been deactivated successfully`, 'success');
          if (onSuccess) {
            onSuccess(details);
          }
          setConfirmDialogOpen(false);
          if (onChange) onChange(false);
        } else {
          notify(
            `An error occurred while deactivating user, Please try again`,
            'error'
          );
        }
      } catch (error: any) {
        setIsUpdateLoading(false);
      }
    }
  };

  const updateInviteClick = (action: string) => {
    setSubmitted(true);
    const payloadData: DeactivatePayload = {
      deactiveUserId: details?.userId,
      userType: actualUserType
        ? actualUserType?.map((item: any) => item?.toUpperCase())
        : []
    };

    payloadData.remarks = remark;
    updatedCompanyAccount(action, payloadData);
  };

  const confirmDialogClose = () => {
    setConfirmDialogOpen(false);
    if (onChange) {
      onChange(false);
    }
  };

  const statusSubmit = (event: React.FormEvent) => {
    event.preventDefault();
  };

  const dialogOpen = () => {
    setConfirmDialogOpen(true);
    if (onChange) onChange(true);
  };

  return (
    <>
      {buttonTitle && (
        <a
          className="base-margin-left btn btn--small btn--dark"
          onClick={dialogOpen}
        >
          {buttonTitle}
        </a>
      )}
      <Dialog
        open={confirmDialogOpen}
        size="small"
        onDialogClose={confirmDialogClose}
        closeOnClickOutside={false}
        title={`Confirm`}
        body={
          <div>
            <form name="statusform" onSubmit={statusSubmit}>
              <h4>
                {isItemInArrayIgnoreCase(
                  details?.email,
                  details?.PA?.length ? details?.PA : []
                ) ? (
                  <>
                    This user is a Partner Administrator for one or more company
                    accounts. Are you sure that you want to deactivate the user{' '}
                    <br />{' '}
                  </>
                ) : (
                  <>
                    <span>
                      Are you sure to deactivate the user <br />
                    </span>
                  </>
                )}{' '}
                <b className="text-size-16">
                  {details?.firstname} {details?.lastname} ({details?.email})
                </b>{' '}
                ?
              </h4>

              <div>
                <div
                  className={`form-group text-left ${
                    submitted && validation?.remark ? 'form-group--error' : ''
                  }`}
                >
                  <div className="form-group__text">
                    <textarea
                      id="approver-comment"
                      rows={2}
                      value={remark || ''}
                      onChange={onRemarkChange}
                    ></textarea>
                    <label htmlFor="approver-comment">
                      <b>
                        Please provide a detailed justification{' '}
                        <span
                          className="text-danger"
                          title="this is a required field"
                        >
                          *
                        </span>
                        {submitted && validation?.remark && (
                          <>
                            <span className="text-danger text-size-12">
                              {' '}
                              {validation?.remark}
                            </span>
                          </>
                        )}
                      </b>
                    </label>
                  </div>
                </div>
              </div>
              {isUpdateLoading && (
                <div className="center-holder-wrap">
                  <Loader />
                </div>
              )}
            </form>
          </div>
        }
        footer={
          <div>
            <button
              type="button"
              onClick={confirmDialogClose}
              className="btn btn--ghost"
              disabled={isUpdateLoading}
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={() => updateInviteClick(statusAction)}
              className="btn btn--primary"
              disabled={isUpdateLoading}
            >
              Confirm
            </button>
          </div>
        }
      />
    </>
  );
};
export default UserActionDialog;
