import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import Loader from '../../atoms/Loader';
import httpUtil from '../../../helpers/interceptor';
import notify from '../../../helpers/notification';
import Dialog from '../../atoms/Dialog';
import { fetchCompanyOffBoardingDetails } from '../../../services';
import { hasAccess } from '../../../helpers';

export interface CompanyProcessDialogProps {
  companyDetail: any;
  buttonTitle: string;
  open?: boolean;
  onChange?: Function;
  onSave?: Function;
}

export interface Task {
  taskName: string;
  buttonTitle: string;
  open?: boolean;
  onChange?: Function;
}

const CompanyProcessDialog = ({
  companyDetail = {},
  buttonTitle,
  open: isDialogOpen = false,
  onChange = () => {},
  onSave = () => {}
}: CompanyProcessDialogProps) => {
  const [open, setOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [statusAction, setStatusAction] = useState('');
  const [status, setStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [comment, setComment] = useState('');
  const [value, setValue] = useState<any>({ action: 'maintainCompany' });
  const [validation, setValidation] = useState<any>({});
  const [taskDetails, setTaskDetails] = useState<any>([]);
  const [taskFlowDetails, setTaskFlowDetails] = useState<any>([]);
  const [userRoleInCompany, setUserRoleInCompany] = useState<string[]>([]);
  const [formType, setFormType] = useState<string | undefined>();
  const [userHasApprovalRole, setUserHasApprovalRole] =
    useState<boolean>(false);

  const authData = useSelector((state: any) => state?.auth);
  const { user, userActiveRolePermissions } = authData;

  // useEffect(() => {
  //   // if (!open) {
  //   //   return () => {
  //   //     setValue({ action: 'maintainCompany' });
  //   //   };
  //   // }
  // }, [open]);

  const taskMaintainCompanyInit = [
    {
      taskName: 'PAM',
      displayTaskName:
        'Partner Account Manager/Operational Admin/User Access Manager',
      taskRoles: ['PAM', 'OP', 'UAM'],
      status: '',
      approvers: []
    }
  ];

  const taskRemoveCompanyInit = [
    {
      taskName: 'PAM',
      displayTaskName:
        'Partner Account Manager/Operational Admin/User Access Manager',
      taskRoles: ['PAM', 'OP', 'UAM'],
      status: '',
      approvers: []
    },
    {
      taskName: 'UAM',
      displayTaskName: 'User Access Manager',
      taskRoles: ['UAM'],
      status: '',
      approvers: []
    }
  ];

  const uamTaskRemoveCompanyInit = [
    {
      taskName: 'UAM',
      displayTaskName: 'User Access Manager',
      taskRoles: ['UAM'],
      status: '',
      approvers: []
    }
  ];

  useEffect(() => {
    if (value?.action === 'removeCompany') {
      let isUAMTask =
        taskFlowDetails?.length === 1 &&
        taskFlowDetails?.find((item: any) => {
          if (isItemInArrayIgnoreCase('UAM', item?.role || [])) {
            return true;
          }
          return false;
        });

      if (!taskFlowDetails?.length && userHasApprovalRole) {
        isUAMTask = true;
      }

      if (isUAMTask) {
        setTaskDetails(uamTaskRemoveCompanyInit);
      } else {
        setTaskDetails(taskRemoveCompanyInit);
      }
    } else if (value?.action === 'maintainCompany') {
      setTaskDetails(taskMaintainCompanyInit);
    }
  }, [value?.action]);

  useEffect(() => {
    setUserHasApprovalRole(hasAccess('company', 'offBoardingApprove'));
  }, []);

  useEffect(() => {
    const submittedAction =
      taskFlowDetails && taskFlowDetails?.length
        ? taskFlowDetails[0]?.disableStatus
        : '';

    let actionTemp = '';
    if (submittedAction?.toLowerCase() === 'inactive') {
      actionTemp = 'maintainCompany';
    } else if (submittedAction?.toLowerCase() === 'deactive') {
      actionTemp = 'removeCompany';
    }

    if (actionTemp) {
      setValue((preVal: any) => {
        return {
          ...(preVal || {}),
          ...{ ['action']: actionTemp }
        };
      });
    }

    let formTypeTemp = '';

    if (
      !(taskFlowDetails && taskFlowDetails?.length) &&
      companyDetail?.activeFlag?.toLowerCase() === 'y'
    ) {
      formTypeTemp = 'disable';
    }
    if (actionTemp === 'removeCompany') {
      formTypeTemp = 'approval';
    }

    setFormType(formTypeTemp);
  }, [taskFlowDetails, companyDetail]);

  useEffect(() => {
    const userRoleInCompanyTemp: string[] = userHasApprovalRole ? ['UAM'] : [];

    if (
      userActiveRolePermissions?.find(
        (roleItem: any) => roleItem?.name === companyDetail?.userGroupName
      )
    ) {
      userRoleInCompanyTemp.push('OP');
    }

    if (
      companyDetail?.lsPAMDetails
        ?.map((item: any) => item?.userId)
        .includes(user?.ccoid)
    ) {
      userRoleInCompanyTemp.push('PAM');
    }

    setUserRoleInCompany(userRoleInCompanyTemp);
  }, [userHasApprovalRole, companyDetail]);

  useEffect(() => {
    setOpen(isDialogOpen);
  }, [isDialogOpen]);

  // useEffect(() => {
  //   if (companyDetail?.companyId) {
  //   //  getCompanyOffboardingDetails();
  //   }
  // }, [companyDetail?.companyId]);

  // const getCompanyOffboardingDetails = async () => {
  //   try {
  //     const payload: any = {
  //       companyId: companyDetail?.companyId || ''
  //     };

  //     setIsLoading(true);
  //     const result = await fetchCompanyOffBoardingDetails(payload);
  //     if (result && result?.data) {
  //       setTaskFlowDetails(result?.data);
  //     } else {
  //       setTaskFlowDetails([]);
  //     }
  //     setIsLoading(false);
  //   } catch (err) {
  //     setIsLoading(false);
  //   }
  // };

  useEffect(() => {
    setComment('');
    setValidation({});
    setConfirmDialogOpen(false);
  }, [open]);

  useEffect(() => {
    checkValidation(
      statusAction === 'DISABLE' ||
        statusAction === 'APPROVE' ||
        statusAction === 'DENY'
    );
  }, [comment, statusAction]);

  const dialogOpen = () => {
    setOpen(true);
    if (onChange) onChange(true);
  };

  const statusUpdateDialogOpen = (action: string) => {
    if (checkValidation(action === 'DISABLE')) {
      setStatusAction(action);
      setConfirmDialogOpen(true);
    }
  };

  const confirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const dialogClose = () => {
    setOpen(false);
    if (onChange) onChange(false);
  };

  const checkValidation = (isCommentRequired = false) => {
    let errors = { ...validation };
    let justificationValue = value?.justification?.trim();

    if (!justificationValue && isCommentRequired) {
      errors.justification = 'Required';
    } else if (
      isCommentRequired &&
      justificationValue &&
      justificationValue.length < 25
    ) {
      errors.justification = 'Justification must be at least 25 characters';
    } else if (
      isCommentRequired &&
      justificationValue &&
      justificationValue.length > 250
    ) {
      errors.justification = "You can't write more than 250 characters";
    } else {
      delete errors.justification;
    }
    setValidation(errors);
    return Object.keys(errors).length === 0;
  };

  const updateStatusClick = (action: string) => {
    const userType = [];
    if (
      userActiveRolePermissions?.find(
        (roleItem: any) => roleItem?.name === companyDetail?.userGroupName
      )
    ) {
      userType.push('OP');
    }

    if (
      companyDetail?.lsPAMDetails
        ?.map((item: any) => item?.userId)
        .includes(user?.ccoid)
    ) {
      userType.push('PAM');
    }

    const companyStatusUpdateData: {
      companyId: string;
      remarks?: string;
      requestType?: string;
      supplierSiteId?: string;
      userType: string[];
      companyName: string;
      approvalStatus: string;
      paEmailsList?: string[];
      pamEmailsList?: string[];
      sponsorEmailsList?: string[];
      opEmailsList?: string[];
      siteAddress?: string;
    } = {
      companyId: companyDetail?.companyId || '',
      companyName: companyDetail?.vendorName || '',
      supplierSiteId: companyDetail?.supplierSiteId || '',
      userType: userType,
      requestType:
        value?.action === 'removeCompany'
          ? 'RemoveUserCompany'
          : 'MaintainUserCompany',
      approvalStatus:
        action.toLocaleUpperCase() === 'DISABLE'
          ? 'APPROVE'
          : action.toLocaleUpperCase()
    };
    type AdminEmail = {
      email: string;
    };

    const paEmailsList =
      companyDetail?.lsPA
        ?.filter(({ activeFlag }: any) => activeFlag?.toLowerCase() == 'y')
        .map(({ email }: AdminEmail) => email) || [];
    const pamEmailsList =
      companyDetail?.lsPAMDetails
        ?.filter(({ activeFlag }: any) => activeFlag?.toLowerCase() == 'y')
        .map(({ email }: AdminEmail) => email) || [];
    const sponsorEmailsList =
      companyDetail?.lsSponsorAdmin
        ?.filter(({ activeFlag }: any) => activeFlag?.toLowerCase() == 'y')
        ?.map(({ email }: AdminEmail) => email) || [];
    const opEmailsList =
      companyDetail?.lsOPAdmin?.map(({ email }: AdminEmail) => email) || [];

    companyStatusUpdateData.paEmailsList = paEmailsList;
    companyStatusUpdateData.pamEmailsList = pamEmailsList;
    companyStatusUpdateData.sponsorEmailsList = sponsorEmailsList;
    companyStatusUpdateData.opEmailsList = opEmailsList;
    companyStatusUpdateData.siteAddress = `${companyDetail?.addressLine1}${
      companyDetail?.addressLine2 ? ', ' + companyDetail?.addressLine2 : ''
    }${companyDetail?.city ? ', ' + companyDetail?.city : ''}${
      companyDetail?.country ? ', ' + companyDetail?.country : ''
    }${companyDetail?.postalCode ? ', ' + companyDetail?.postalCode : ''}`;

    if (action === 'DISABLE' || action === 'DENY') {
      companyStatusUpdateData.remarks = value?.justification || '';
    }

    if (checkValidation(action === 'DISABLE' || action === 'DENY')) {
      let apiUrl = '/api/am/dashboard/v1/company/offboard/request';

      setIsLoading(true);
      return httpUtil
        .post(apiUrl, companyStatusUpdateData)
        .then((res: any) => {
          setIsLoading(false);

          let actionSuccessText = '';
          let actionFailedText = '';
          switch (true) {
            case action === 'APPROVE':
              actionSuccessText = 'approved';
              actionFailedText = 'approving';
              break;

            case action === 'DENY':
              actionSuccessText = 'denied';
              actionFailedText = 'denying';
              break;

            case action === 'DISABLE':
              actionSuccessText = 'deactivated';
              actionFailedText = 'deactivating';
              break;

            default:
              actionSuccessText = 'updated';
              actionFailedText = 'updating';
              break;
          }
          const responseStatus = res?.status?.toLowerCase();
          if (responseStatus === 'success') {
            notify(
              `Company has been ${actionSuccessText} successfully`,
              'success'
            );
            if (onSave) onSave();
            setOpen(false);
            if (onChange) onChange(false);
          } else {
            notify(
              `An error occurred while ${actionFailedText} company, Please try again`,
              'error'
            );
          }
          return res;
        })
        .catch((error) => {
          setIsLoading(false);
          let errorMessage = '';
          if (error?.response?.status != 401) {
            errorMessage = `An error occurred while updating company ${
              error?.response?.data?.error
                ? ': ' + error?.response?.data?.error
                : ''
            }`;
            notify(errorMessage, 'error');
          }
        });
    }
  };

  function isItemInArrayIgnoreCase(item: string, array: string[]) {
    const lowercasedItem = item?.toLowerCase();
    return array.some((element) => element?.toLowerCase() === lowercasedItem);
  }

  const approvedDetails = (taskUserRoles: string[]) => {
    let approvedDetailsData;
    taskFlowDetails.forEach((item: any) => {
      if (isItemInArrayIgnoreCase(item?.creatorType, taskUserRoles)) {
        approvedDetailsData = {
          createdDate: item?.creationDate,
          approvedBy: item?.userId,
          justification: item?.remarks,
          status: 'completed'
        };
      }
    });
    return approvedDetailsData;
  };

  useEffect(() => {
    setStatus(getStatus()?.toLowerCase());
  }, [taskFlowDetails]);

  const getStatus = () => {
    for (let i = 0; i < taskFlowDetails.length; i++) {
      if (
        isItemInArrayIgnoreCase(taskFlowDetails[i]?.workflowStatus, [
          'completed',
          'denied'
        ])
      ) {
        return taskFlowDetails[i]?.workflowStatus;
      }
    }

    for (let i = 0; i < taskFlowDetails.length; i++) {
      return taskFlowDetails[i]?.workflowStatus;
    }
    return '';
  };

  const onActionChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    setValue((preVal: any) => {
      return {
        ...(preVal || {}),
        ...{
          ['action']: event.target.value
        }
      };
    });
  };

  const onInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    if (event.target.type === 'checkbox') {
      setValue((preVal: any) => {
        return {
          ...(preVal || {}),
          ...{ [event.target.name]: (event.target as any).checked }
        };
      });
    } else {
      setValue((preVal: any) => {
        return {
          ...(preVal || {}),
          ...{ [event.target.name]: event.target.value }
        };
      });
    }
  };

  return (
    <>
      {buttonTitle &&
        ((status != 'completed' &&
          status != 'denied' &&
          companyDetail?.activeFlag?.toLowerCase() === 'n') ||
          companyDetail?.activeFlag?.toLowerCase() === 'y') && (
          <a
            className="base-margin-left btn btn--small btn--dark"
            onClick={dialogOpen}
          >
            {buttonTitle}
          </a>
        )}
      <Dialog
        open={open}
        size="large"
        onDialogClose={dialogClose}
        closeOnClickOutside={false}
        title={`Company : ${companyDetail?.vendorName}`}
        body={
          <div>
            {isLoading && (
              <div className="center-holder-wrap">
                <Loader />
              </div>
            )}
            <div className="panel panel--bordered qtr-margin-top">
              <div className="row">
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                  <div className="record-info">
                    <span className="record-label">Company Name</span>
                    <div>
                      <span className="record-value">
                        {companyDetail?.vendorName}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                  <div className="record-info">
                    <span className="record-label">Company Type</span>
                    <div>
                      <span className="record-value">
                        {companyDetail?.companyType}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                  <div className="record-info">
                    <span className="record-label">Vendor Id</span>
                    <div>
                      <span className="record-value">
                        {companyDetail?.vendorId}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                  <div className="record-info">
                    <span className="record-label">Site Id</span>
                    <div>
                      <span className="record-value">
                        {companyDetail?.supplierSiteId}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                  <div className="record-info">
                    <span className="record-label">Site Address</span>
                    <div>
                      <span className="record-value">
                        {companyDetail?.addressLine1}
                        {companyDetail?.addressLine2
                          ? ', ' + companyDetail?.addressLine2
                          : null}
                        {companyDetail?.city
                          ? ', ' + companyDetail?.city
                          : null}
                        {companyDetail?.country
                          ? ', ' + companyDetail?.country
                          : null}
                        {companyDetail?.postalCode
                          ? ', ' + companyDetail?.postalCode
                          : null}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                  <div className="record-info">
                    <span className="record-label">SCREG Status</span>
                    <div>
                      <span className="record-value">
                        {companyDetail?.activeFlag?.toLowerCase() === 'y' ? (
                          <span className="label label--small  label--success  label--bordered">
                            Active
                          </span>
                        ) : (
                          <span className="label label--small label--warning label--bordered">
                            Inactive
                          </span>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {formType || (taskFlowDetails && taskFlowDetails?.length) ? (
              <>
                {taskFlowDetails && taskFlowDetails?.length ? (
                  <div className="panel panel--bordered qtr-margin-top">
                    <div className=" half-margin-bottom half-margin-top">
                      {value?.action === 'maintainCompany' ? (
                        <span>Maintain company account and users</span>
                      ) : null}
                      {value?.action === 'removeCompany' ? (
                        <span>
                          Remove company account and users :{' '}
                          {status?.toLocaleUpperCase()}
                        </span>
                      ) : null}
                    </div>
                  </div>
                ) : (
                  <>
                    {' '}
                    {taskDetails?.length &&
                      taskDetails[0]?.status?.toLowerCase() !== 'approved' && (
                        <div className="panel panel--bordered qtr-margin-top">
                          <div className=" half-margin-bottom half-margin-top">
                            <div className="form-group  form-group--compressed">
                              <label className="radio">
                                <input
                                  type="radio"
                                  name="action"
                                  value="maintainCompany"
                                  checked={value?.action === 'maintainCompany'}
                                  onChange={onActionChange}
                                />
                                <span className="radio__input"></span>
                                <span className="radio__label">
                                  Maintain company account and users
                                </span>
                              </label>
                            </div>

                            <div className="form-group form-group--compressed">
                              <label className="radio">
                                <input
                                  type="radio"
                                  checked={value?.action === 'removeCompany'}
                                  name="action"
                                  value="removeCompany"
                                  onChange={onActionChange}
                                />
                                <span className="radio__input"></span>
                                <span className="radio__label">
                                  Remove company account and users
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      )}{' '}
                  </>
                )}

                <div className="panel panel--bordered qtr-margin-top">
                  <div className="half-padding-left">
                    <h5>Company Deactivation Status</h5>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="steps request-steps steps--primary">
                        {taskDetails &&
                          taskDetails.map(
                            (
                              {
                                taskName,
                                approvers,
                                status,
                                displayTaskName,
                                taskRoles
                              }: {
                                taskName: string;
                                status: string;
                                approvers: any;
                                displayTaskName: string;
                                taskRoles: string[];
                              },
                              index: number
                            ) => {
                              const approvedUserDetails: any =
                                approvedDetails(taskRoles);
                              return (
                                <div
                                  key={`task${taskName}`}
                                  className={`step ${
                                    approvedUserDetails?.status?.toLowerCase() !==
                                    'completed'
                                      ? 'visited'
                                      : ''
                                  }  ${
                                    approvedUserDetails?.status?.toLowerCase() ===
                                    'completed'
                                      ? 'active'
                                      : ''
                                  }`}
                                  style={{
                                    width: `${100 / taskDetails.length}%`
                                  }}
                                >
                                  <div className={`step__icon`}>
                                    <span className="step-text">
                                      {/* {displayTaskName} */}
                                    </span>
                                  </div>
                                  <>
                                    <div className="step__label">
                                      <div>Requestor:{displayTaskName}</div>
                                      <div>
                                        Status:
                                        {approvedUserDetails?.status ===
                                        'completed'
                                          ? 'Completed'
                                          : status}
                                      </div>
                                    </div>

                                    {approvedUserDetails && (
                                      <div className="step_info half-margin-top bg-gray-300 text-left">
                                        <table className="table no-padding table--compressed">
                                          <tr>
                                            <td>
                                              <b>Approved By</b>
                                            </td>
                                            <td>
                                              {approvedUserDetails?.approvedBy}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <b>Date & Time</b>
                                            </td>
                                            <td>
                                              {approvedUserDetails?.createdDate
                                                ? moment(
                                                    new Date(
                                                      approvedUserDetails?.createdDate
                                                    )
                                                  ).format(
                                                    'MM-DD-YYYY, h:mm:ss A'
                                                  )
                                                : ''}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <b>Justification</b>
                                            </td>
                                            <td>
                                              <div className="comment-wrap">
                                                {
                                                  approvedUserDetails?.justification
                                                }
                                              </div>
                                            </td>
                                          </tr>
                                        </table>
                                      </div>
                                    )}

                                    {approvers && approvers?.length ? (
                                      <div className="step_info half-margin-top">
                                        {approvers
                                          ?.toString()
                                          ?.split(',')
                                          .map((item: string, i: number) => (
                                            <div
                                              key={`approvers${i}`}
                                              className="qtr-margin-top"
                                            >
                                              {item}
                                            </div>
                                          ))}
                                      </div>
                                    ) : null}
                                  </>
                                </div>
                              );
                            }
                          )}
                      </div>
                    </div>
                  </div>
                </div>

                {formType === 'disable' && (
                  <div className={`form-group base-margin-top`}>
                    <div className="form-group__text">
                      <textarea
                        id="justification"
                        name="justification"
                        rows={1}
                        onChange={onInputChange}
                        // disabled={readOnly}
                        value={value?.justification}
                        required
                      />
                      <label htmlFor="justification">
                        Please provide a detailed justification{' '}
                        <span
                          className="text-danger"
                          title="this is a required field"
                        >
                          *
                        </span>{' '}
                        <span className="text-danger">
                          (minimum 25 characters)
                        </span>
                        {validation.justification && (
                          <span className="text-danger text-size-12">
                            {' '}
                            {validation.justification}
                          </span>
                        )}
                      </label>
                    </div>
                  </div>
                )}
              </>
            ) : null}
          </div>
        }
        footer={
          <div>
            {companyDetail?.activeFlag?.toLowerCase() === 'y' &&
            formType === 'approval' &&
            userHasApprovalRole ? (
              <>
                <button
                  type="button"
                  onClick={() => statusUpdateDialogOpen('APPROVE')}
                  className="btn"
                  // disabled={isLoading}
                >
                  Approve
                </button>
                <button
                  type="button"
                  onClick={() => statusUpdateDialogOpen('DENY')}
                  className="btn btn--dark"
                  //  disabled={isLoading}
                >
                  Deny
                </button>
              </>
            ) : null}

            {formType === 'disable' ? (
              <button
                type="button"
                onClick={() => statusUpdateDialogOpen('DISABLE')}
                className="btn btn--dark"
                disabled={isLoading}
              >
                Deactivate
              </button>
            ) : null}
          </div>
        }
      />
      <Dialog
        open={confirmDialogOpen}
        size="small"
        onDialogClose={confirmDialogClose}
        closeOnClickOutside={false}
        title={`Confirm`}
        body={
          <div>
            <h4>
              Are you sure to{' '}
              <b>
                {/* {' '}
                <span style={{ textTransform: 'capitalize' }}>
                  {value?.action?.toLowerCase()}
                </span> */}
                deactivate
              </b>{' '}
              the company <b>{companyDetail?.vendorName}</b>
            </h4>

            {isLoading && (
              <div className="center-holder-wrap">
                <Loader />
              </div>
            )}
          </div>
        }
        footer={
          <div>
            <button
              type="button"
              onClick={confirmDialogClose}
              className="btn btn--ghost"
              disabled={isLoading}
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={() => updateStatusClick(statusAction)}
              className="btn btn--primary"
              disabled={isLoading}
            >
              Confirm
            </button>
          </div>
        }
      />
    </>
  );
};

export default CompanyProcessDialog;
