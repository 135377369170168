import React from 'react';
import AuditReportScheduleTable from '../../organisms/AuditReportScheduleTable';

const RequestStatus = () => {
  return (
    <div className="container">
      <div className="section">
        <AuditReportScheduleTable />
      </div>
    </div>
  );
};
export default RequestStatus;
