import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';

import PlaceHolderBox from '../../atoms/PlaceHolderBox';
import Loader from '../../atoms/Loader';
import {
  fetchCompanyTypes,
  fetchComapanyList,
  fetchOrganization,
  profileSubmit,
  fetchUserActiveRequest,
  fetchProfile
} from '../../../actions';
import Accordion from '../../atoms/Accordion';
import ChildCompany from '../../molecules/ChildCompany';
// import RequestStatus from '../../organisms/RequestStatus';
import { config } from '../../../config/config';

const RegisterUserCompany = () => {
  const dispatch = useDispatch();
  const { oneAccessUrl } = config;

  const [readOnly, setReadOnly] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState({});
  const [isCompanyShow, setIsCompanyShow] = useState(false);
  const [isOrgShow, setIsOrgShow] = useState(false);
  const [validation, setValidation] = useState({});
  const [isValid, setIsValid] = useState(false);

  const [availableCompanyTypes, setAvailableCompanyTypes] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const authData = useSelector((state) => state.auth);
  const { isFederatedUser } = authData;

  const companyRegisterData = useSelector((state) => state.companyRegisterData);
  const {
    companyTypeStatus,
    companyTypes,
    companyList,
    companyListStatus,
    orgListStatus,
    companyTypeErrorMessage,
    companyListErrorMessage,
    orgListErrorMessage,
    profileSubmitStatus,
    profileSubmitResponse,
    profile
  } = companyRegisterData;
  const orgList = useSelector((state) => state.companyRegisterData?.orgList);

  useEffect(() => {
    if (formData.childCompany) {
      setIsOrgShow(true);
      dispatch(fetchOrganization(formData.childCompany));
    } else {
      setIsOrgShow(false);
    }
  }, [formData.childCompany]);

  useEffect(() => {
    setAvailableCompanyTypes(
      companyTypes
        ? companyTypes?.filter((item) => {
            return companyList?.find(({ companyTypeId }) => {
              return companyTypeId === item.companyTypeId;
            });
          })
        : []
    );
  }, [companyList, companyTypes]);

  useEffect(() => {
    if (formData.companyType) {
      setIsCompanyShow(true);
    } else {
      setIsCompanyShow(false);
    }

    let companyListResult = cloneDeep(companyList);
    const uniqueKeys = new Set();
    companyListResult = companyListResult
      ?.filter((item) => {
        return item.companyTypeId == formData.companyType;
      })
      ?.filter((item) => {
        if (!uniqueKeys.has(item.companyName)) {
          uniqueKeys.add(item.companyName);
          return true;
        }
        return false;
      });
    setFilteredCompanies(companyListResult || []);
  }, [formData.companyType, companyList]);

  useEffect(() => {
    dispatch(fetchCompanyTypes());
  }, []);

  useEffect(() => {
    dispatch(fetchComapanyList(isFederatedUser));
  }, [isFederatedUser]);

  // useEffect(() => {
  //   dispatch(fetchCompanyTypes());
  // }, [profile]);

  // useEffect(() => {
  //   if (profileSubmitStatus === 'SUCCESS' && !isFederatedUser) {
  //     dispatch(fetchProfile());
  //   }
  // }, [profileSubmitStatus]);

  /* FORM PREFILL START */
  useEffect(() => {
    (async () => {
      if (profile?.profileStatus?.toLowerCase() === 'active') {
        setReadOnly(true);
        const selectedCompanyType = availableCompanyTypes?.find((item) => {
          return item.companyTypeName == profile.mcompanytype;
        });

        const selectedFormValue = {
          companyId: profile.companyId,
          childCompany: String(profile.companyNodeId),
          companyType: selectedCompanyType?.companyTypeId.toString(),
          primaryOrganization: profile?.mcouserorg || '',
          secondaryCompanies: profile.mcomultiorg
            ? profile.mcomultiorg.split(',')
            : [],
          justification: profile.justification
        };
        setFormData({
          ...formData,
          ...selectedFormValue
        });
      }
    })();
  }, [profile, availableCompanyTypes]);

  /* FORM PREFILL END */

  const registerSubmit = async (event) => {
    setSubmitted(true);
    event.preventDefault();

    checkValidation();
    if (isValid && !readOnly) {
      const selectedCompanyType = availableCompanyTypes?.find((item) => {
        return item.companyTypeId == formData.companyType;
      });

      const profileData = {
        companyNodeId: formData.childCompany,
        companyId: formData.companyId,
        companyTypeName: selectedCompanyType
          ? selectedCompanyType.companyTypeName
          : '',
        companyTypeId: formData.companyType,
        supplierSiteId: formData.supplierSiteId,
        primaryCompany: formData.primaryOrganization,
        secondaryCompanies: formData.secondaryCompanies.join(','),
        justification: formData.justification
      };

      await dispatch(
        profileSubmit(profileData, isFederatedUser ? 'federated' : undefined)
      );

      dispatch(fetchProfile());
    }
  };

  const onChangeChildCompany = (
    parentCompanyId,
    childCompanyId,
    supplierSiteId
  ) => {
    const updatedFormData = {
      companyId: parentCompanyId,
      childCompany: childCompanyId,
      supplierSiteId: supplierSiteId,
      primaryOrganization: '',
      secondaryCompanies: []
    };
    setFormData({
      ...formData,
      ...updatedFormData
    });
  };

  const onChange = (event) => {
    let updatedFormData = {};

    /* remove selected primary org from secondary org */
    if (event.target.name === 'primaryOrganization') {
      let updatedList = [...(formData?.secondaryCompanies || [])];
      if (event.target.value && updatedList.length) {
        let itemIndex = updatedList.indexOf(event.target.value);
        if (itemIndex !== -1) {
          updatedList.splice(updatedList.indexOf(event.target.value), 1);
        }
      }
      updatedFormData = {
        ...updatedFormData,
        ...{ secondaryCompanies: updatedList }
      };
    }

    if (event.target.name === 'secondaryCompanies') {
      let updatedList = [...(formData?.secondaryCompanies || [])];
      if (event.target.checked) {
        updatedList = [...updatedList, event.target.value];
      } else {
        updatedList.splice(updatedList.indexOf(event.target.value), 1);
      }
      updatedFormData = {
        ...updatedFormData,
        ...{ secondaryCompanies: updatedList }
      };
    } else {
      updatedFormData = {
        ...updatedFormData,
        ...{ [event.target.name]: event.target.value }
      };
    }

    if (event.target.name === 'companyType') {
      updatedFormData = {
        ...updatedFormData,
        companyId: '',
        childCompany: '',
        primaryOrganization: '',
        secondaryCompanies: []
      };
    }

    if (event.target.name === 'childCompany') {
      updatedFormData = {
        ...updatedFormData,
        primaryOrganization: '',
        secondaryCompanies: []
      };
    }

    setFormData({
      ...formData,
      ...updatedFormData
    });
  };

  useEffect(() => {
    checkValidation();
  }, [formData, orgList]);

  const checkValidation = () => {
    let errors = { ...validation };

    if (!formData?.companyType?.trim()) {
      errors.companyType = 'Company Type is required';
    } else {
      delete errors.companyType;
    }

    if (!formData?.childCompany?.trim()) {
      errors.childCompany = 'Company is required';
    } else {
      delete errors.childCompany;
    }

    if (!formData?.primaryOrganization?.trim() && orgList && orgList?.length) {
      errors.primaryOrganization = 'Primary Organization is required';
    } else {
      delete errors.primaryOrganization;
    }
    if (!formData?.justification?.trim()) {
      errors.justification = 'Justification is required';
    } else if (formData?.justification?.trim().length < 25) {
      errors.justification =
        'Justification must be at least 25 characters long';
    } else {
      delete errors.justification;
    }
    setValidation(errors);
  };

  useEffect(() => {
    setIsValid(Object.keys(validation).length === 0);
  }, [validation]);

  const {
    companyType,
    companyId,
    childCompany,
    primaryOrganization,
    secondaryCompanies,
    justification
  } = formData;

  return (
    <div>
      <form name="companyRegisterform" onSubmit={registerSubmit}>
        <div className="form-wrap base-margin-top">
          {profileSubmitStatus === 'PENDING' && (
            <div className="center-holder-wrap">
              <Loader />
            </div>
          )}

          {profileSubmitStatus === 'SUCCESS' && (
            <>
              <div className="alert alert--success dbl-padding">
                <div className="alert__message">
                  {isFederatedUser && (
                    <>
                      <h6>
                        Your Supply Chain profile has been created
                        successfully!!
                      </h6>
                      <h6>
                        Please click on below button to request Supply Chain
                        roles.
                      </h6>
                      <a
                        href={oneAccessUrl}
                        target="_blank"
                        className="btn btn--small"
                        rel="noreferrer"
                        style={{ textDecoration: 'none' }}
                      >
                        One Access
                      </a>
                    </>
                  )}
                  {!isFederatedUser && (
                    <>
                      <h6>
                        Your Supply Chain profile creation request has been sent
                        for approval.
                      </h6>
                      <h6>
                        You will be notified through email, once it is approved.
                      </h6>
                    </>
                  )}
                </div>
              </div>

              {/* <RequestStatus requests={[profileSubmitResponse]} /> */}
            </>
          )}

          {profileSubmitStatus !== 'SUCCESS' && (
            <>
              <div className="base-margin-top base-margin-bottom">
                <h4>
                  Please use the below form to register yourself as Supply Chain
                  user to get access to Supply Chain applications.
                </h4>
              </div>
              <div>
                <div className="alert alert--warning">
                  <div className="alert__message">
                    Companies without{' '}
                    {!isFederatedUser ? 'Partner Administrator and' : ''}{' '}
                    Partner Account Manager are not listed. Please contact{' '}
                    <a href="mailto:sciam-support@cisco.com">
                      sciam-support@cisco.com
                    </a>{' '}
                    to complete company onboarding.
                  </div>
                </div>
              </div>

              <div className="panel base-padding panel--raised panel--bordered">
                <div className="row">
                  <div className="col-md-4 col--bordered-right">
                    <div className="panel company-form-panel">
                      {(companyTypeStatus === 'PENDING' ||
                        companyListStatus === 'PENDING') && (
                        <div className="center-holder-wrap">
                          <Loader />
                        </div>
                      )}

                      <h6>
                        Please choose a company type{' '}
                        <span
                          className="text-danger"
                          title="this is a required field"
                        >
                          *
                        </span>{' '}
                      </h6>
                      {submitted && validation.companyType && (
                        <span className="text-danger text-size-12">
                          {validation.companyType}
                        </span>
                      )}

                      {companyTypeStatus === 'FAILURE' &&
                        companyTypeErrorMessage && (
                          <div className="alert alert--warning">
                            <div className="alert__message">
                              {companyTypeErrorMessage}
                            </div>
                          </div>
                        )}

                      {companyListStatus === 'FAILURE' &&
                        companyListErrorMessage && (
                          <div className="alert alert--warning">
                            <div className="alert__message">
                              {companyListErrorMessage}
                            </div>
                          </div>
                        )}

                      {companyTypeStatus === 'SUCCESS' &&
                        companyListStatus === 'SUCCESS' && (
                          <div className="base-padding-left company-type-list-wrap">
                            {availableCompanyTypes.length === 0 && (
                              <div className="alert alert--warning">
                                <div className="alert__message">
                                  No companies are found
                                </div>
                              </div>
                            )}
                            {availableCompanyTypes?.map((value) => (
                              <div
                                key={value.companyTypeId}
                                className="form-group base-margin-top"
                              >
                                <label className="radio">
                                  <input
                                    type="radio"
                                    name="companyType"
                                    value={value.companyTypeId}
                                    checked={value.companyTypeId == companyType}
                                    onChange={onChange}
                                    required
                                    disabled={readOnly}
                                  />
                                  <span className="radio__input"></span>
                                  <span className="radio__label">
                                    {value.companyTypeName}
                                  </span>
                                </label>
                              </div>
                            ))}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-md-5 col--bordered-right">
                    <div className="panel company-form-panel">
                      {!isCompanyShow && (
                        <PlaceHolderBox title="Please choose company" />
                      )}
                      {isCompanyShow && (
                        <div className="">
                          <h6>
                            Please choose affiliated company and site address{' '}
                            <span
                              className="text-danger"
                              title="this is a required field"
                            >
                              *
                            </span>
                          </h6>
                          {submitted && validation.childCompany && (
                            <span className="text-danger text-size-12">
                              {validation.childCompany}
                            </span>
                          )}
                          <div className="company-list-wrap">
                            {filteredCompanies.map(
                              (
                                {
                                  companyName,
                                  companyId: itemCompanyId,
                                  companyTypeId
                                },
                                index
                              ) => (
                                <div key={`company${itemCompanyId}-${index}`}>
                                  <div className="half-margin-top">
                                    <Accordion
                                      title={companyName}
                                      open={itemCompanyId == companyId}
                                      content={
                                        <ChildCompany
                                          companyName={companyName}
                                          companyTypeId={companyTypeId}
                                          name="childCompany"
                                          onChange={onChangeChildCompany}
                                          value={childCompany}
                                          required
                                          disabled={readOnly}
                                        />
                                      }
                                    />
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="panel company-form-panel">
                      {!isOrgShow && (
                        <PlaceHolderBox
                          title={
                            isCompanyShow
                              ? 'Please choose organization'
                              : 'Please choose organization'
                          }
                        />
                      )}
                      {isCompanyShow && isOrgShow && (
                        <>
                          <div className="">
                            <h6>
                              Primary Organization{' '}
                              {orgListStatus === 'SUCCESS' &&
                                orgList?.length > 0 && (
                                  <span
                                    className="text-danger"
                                    title="this is a required field"
                                  >
                                    *
                                  </span>
                                )}
                            </h6>
                            {submitted && validation.primaryOrganization && (
                              <span className="text-danger text-size-12">
                                {validation.primaryOrganization}
                              </span>
                            )}
                            <div className="base-padding-left organization-form-wrap">
                              {orgListStatus === 'PENDING' && (
                                <div className="center-holder-wrap">
                                  <Loader />
                                </div>
                              )}

                              {orgListStatus === 'FAILURE' &&
                                orgListErrorMessage && (
                                  <div className="alert alert--warning">
                                    <div className="alert__message">
                                      {orgListErrorMessage}
                                    </div>
                                  </div>
                                )}

                              {orgListStatus === 'SUCCESS' &&
                                orgList?.length == 0 && (
                                  <div className="form-group base-margin-top">
                                    No primary organization found
                                  </div>
                                )}

                              {orgList &&
                                orgList?.map((orgName, index) => (
                                  <div
                                    key={`primaryOrganization${index}`}
                                    className="form-group base-margin-top"
                                  >
                                    <label
                                      className={`radio ${
                                        readOnly ? 'disabled' : ''
                                      }`}
                                    >
                                      <input
                                        type="radio"
                                        name="primaryOrganization"
                                        value={orgName}
                                        onChange={onChange}
                                        checked={orgName == primaryOrganization}
                                        required
                                        disabled={readOnly}
                                      />
                                      <span className="radio__input"></span>
                                      <span className="radio__label">
                                        {orgName}
                                      </span>
                                    </label>
                                  </div>
                                ))}
                            </div>
                          </div>

                          <div className="base-margin-top">
                            <h6>
                              Secondary Organization - <small>(Optional)</small>
                            </h6>
                            <div className="base-padding-left organization-form-wrap">
                              {orgListStatus === 'PENDING' && (
                                <div className="center-holder-wrap">
                                  <Loader />
                                </div>
                              )}

                              {orgListStatus === 'FAILURE' &&
                                orgListErrorMessage && (
                                  <div className="alert alert--warning">
                                    <div className="alert__message">
                                      {orgListErrorMessage}
                                    </div>
                                  </div>
                                )}

                              {orgListStatus === 'SUCCESS' &&
                                orgList?.length == 0 && (
                                  <div className="form-group base-margin-top">
                                    No secondary organization found
                                  </div>
                                )}

                              {orgList &&
                                orgList?.map((orgName, index) => {
                                  const isChecked = secondaryCompanies?.find(
                                    (item) => item == orgName
                                  );
                                  return (
                                    <div
                                      key={`secondaryCompanies${index}`}
                                      className="form-group base-margin-top"
                                    >
                                      <label
                                        className={`checkbox ${
                                          readOnly ||
                                          orgName === primaryOrganization
                                            ? 'disabled'
                                            : ''
                                        }`}
                                      >
                                        <input
                                          type="checkbox"
                                          name="secondaryCompanies"
                                          value={orgName || ''}
                                          checked={
                                            isChecked ? isChecked : false
                                          }
                                          onChange={onChange.bind(this)}
                                          disabled={
                                            readOnly ||
                                            orgName === primaryOrganization
                                          }
                                        />
                                        <span className="checkbox__input"></span>
                                        <span className="checkbox__label">
                                          {orgName}
                                        </span>
                                      </label>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className={`form-group  ${
                    submitted && validation.justification
                      ? 'form-group--error'
                      : ''
                  }`}
                >
                  <div className="form-group__text">
                    <textarea
                      id="justification"
                      name="justification"
                      rows="3"
                      onChange={onChange}
                      disabled={readOnly}
                      value={justification}
                      required
                    />
                    <label htmlFor="justification">
                      Please provide a detailed justification{' '}
                      <span
                        className="text-danger"
                        title="this is a required field"
                      >
                        *
                      </span>{' '}
                      <span className="text-danger">
                        (minimum 25 characters)
                      </span>
                      {submitted && validation.justification && (
                        <span className="text-danger text-size-12">
                          {' '}
                          {validation.justification}
                        </span>
                      )}
                    </label>
                  </div>
                </div>

                <>
                  {!readOnly && (
                    <div className="text-center base-margin-top">
                      <button
                        type="submit"
                        disabled={
                          companyTypeStatus === 'PENDING' ||
                          orgListStatus === 'PENDING' ||
                          profileSubmitStatus === 'PENDING' ||
                          !isValid
                        }
                        className="btn btn--primary"
                      >
                        Submit
                      </button>
                    </div>
                  )}
                </>
              </div>
            </>
          )}
        </div>
      </form>
    </div>
  );
};
export default RegisterUserCompany;
