import React from 'react';
import { Link } from 'react-router-dom';

const Feedback = () => {
  return (
    <div
      className="dashboard-vertical-center"
      style={{
        minHeight: '70vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }}
    >
      <div className="section">
        <div className="">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className=" base-padding-bottom">
                <h4>Send Feedback</h4>
              </div>

              <p>
                We{"'"}d love to hear from you. Please email us at{' '}
                <a href="mailto:sc_security_iam@cisco.com">
                  sc_security_iam@cisco.com
                </a>{' '}
                to provide your feedback.
              </p>
              <p>
                To learn how to navigate the Supply Chain Partner Registration
                tool, quick reference guides are available on our{' '}
                <a
                  href="https://cisco.sharepoint.com/sites/SCTDataSecurityandPrivacy/SitePages/Identity-and-Access-Governance.aspx"
                  target="_blank"
                  rel="noreferrer"
                >
                  SharePoint
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Feedback;
