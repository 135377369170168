import httpUtil from '../helpers/interceptor';
import notify from '../helpers/notification';

export const companySubmit = (companyData: any) => {
  return (dispatch: Function) => {
    dispatch({ type: 'POST_COMPANY_START' });
    // setTimeout(() => {
    //   dispatch({
    //     type: 'POST_COMPANY_COMPLETE',
    //     payload: 'Done' || []
    //   });
    // }, 5000);

    return httpUtil
      .post(`/api/am/dashboard/v1/company/onboard/submit`, companyData)
      .then((res: any) => {
        const responseStatus = res?.status?.toLowerCase();
        if (responseStatus === 'success') {
          dispatch({
            type: 'POST_COMPANY_COMPLETE',
            payload: res || []
          });
        } else {
          const errrMessage = `An error occurred while on-boarding company ${
            res?.msg ? ' - ' + res?.msg : ''
          }`;
          dispatch({
            type: 'POST_COMPANY_FAILURE',
            errorMessage: errrMessage
          });
          notify(errrMessage, 'error');
        }

        return res;
      })
      .catch((error) => {
        let errorMessage = '';
        if (error?.response?.status != 401) {
          errorMessage = `An error occurred while on-boarding company ${
            error?.response?.data?.error
              ? ': ' + error?.response?.data?.error
              : ''
          }`;
          notify(errorMessage, 'error');
        }
        dispatch({
          type: 'POST_COMPANY_FAILURE',
          errorMessage: errorMessage
        });
      });
  };
};

export const fetchCountries = () => {
  return (dispatch: Function) => {
    dispatch({ type: 'GET_COUNTRIES_START' });
    return httpUtil
      .get(`/api/am/v1/countries`)
      .then((res) => {
        dispatch({ type: 'GET_COUNTRIES_COMPLETE', payload: res || [] });
        return res;
      })
      .catch((error) => {
        let errorMessage = '';
        if (error?.response?.status != 401) {
          errorMessage = `An error occurred while fetching countries ${
            error?.response?.data?.error
              ? ': ' + error?.response?.data?.error
              : ''
          }`;
          notify(errorMessage, 'error');
        }
        dispatch({
          type: 'GET_COUNTRIES_FAILURE',
          errorMessage: errorMessage
        });
      });
  };
};

export const fetchCustomers = () => {
  return (dispatch: Function) => {
    dispatch({ type: 'GET_CUSTOMERS_START' });
    return httpUtil
      .get(`/api/am/v1/customers`)
      .then((res) => {
        dispatch({ type: 'GET_CUSTOMERS_COMPLETE', payload: res || [] });
        return res;
      })
      .catch((error) => {
        let errorMessage = '';
        if (error?.response?.status != 401) {
          errorMessage = `An error occurred while fetching customers ${
            error?.response?.data?.error
              ? ': ' + error?.response?.data?.error
              : ''
          }`;
          notify(errorMessage, 'error');
        }
        dispatch({
          type: 'GET_CUSTOMERS_FAILURE',
          errorMessage: errorMessage
        });
      });
  };
};

export const fetchAllOrganization = () => {
  return (dispatch: Function) => {
    dispatch({ type: 'GET_ORGANIZATION_START' });
    return httpUtil
      .get(`/api/am/dashboard/v1/company/oracle/orgs`)
      .then((res) => {
        dispatch({ type: 'GET_ORGANIZATION_COMPLETE', payload: res || [] });
        return res;
      })
      .catch((error) => {
        let errorMessage = '';
        if (error?.response?.status != 401) {
          errorMessage = `An error occurred while fetching organization ${
            error?.response?.data?.error
              ? ': ' + error?.response?.data?.error
              : ''
          }`;
          notify(errorMessage, 'error');
        }
        dispatch({
          type: 'GET_ORGANIZATION_FAILURE',
          errorMessage: errorMessage
        });
      });
  };
};

export const fetchOperationalAdmin = (companyType: string) => {
  return (dispatch: Function) => {
    dispatch({ type: 'GET_OPERATIONALADMIN_START' });
    return httpUtil
      .post(`/api/am/dashboard/v1/company/admins/operational`, { companyType })
      .then((res) => {
        dispatch({ type: 'GET_OPERATIONALADMIN_COMPLETE', payload: res || [] });
        return res;
      })
      .catch((error) => {
        let errorMessage = '';
        if (error?.response?.status != 401) {
          errorMessage = `An error occurred while fetching operational admins ${
            error?.response?.data?.error
              ? ': ' + error?.response?.data?.error
              : ''
          }`;
          notify(errorMessage, 'error');
        }
        dispatch({
          type: 'GET_OPERATIONALADMIN_FAILURE',
          errorMessage: errorMessage
        });
      });
  };
};

export const fetchAllCompanyTypes = () => {
  return (dispatch: Function) => {
    dispatch({ type: 'GET_ALL_COMPANY_TYPES_START' });
    return httpUtil
      .get(`/api/am/dashboard/v1/company-types`)
      .then((res) => {
        dispatch({
          type: 'GET_ALL_COMPANY_TYPES_COMPLETE',
          payload: res || []
        });
        return res;
      })
      .catch((error) => {
        let errorMessage = '';
        if (error?.response?.status != 401) {
          errorMessage = `An error occurred while fetching company types ${
            error?.response?.data?.error
              ? ': ' + error?.response?.data?.error
              : ''
          }`;
          notify(errorMessage, 'error');
        }
        dispatch({
          type: 'GET_ALL_COMPANY_TYPES_FAILURE',
          errorMessage: errorMessage
        });
      });
  };
};

export const fetchGroups = () => {
  return (dispatch: Function) => {
    dispatch({ type: 'GET_GROUPS_START' });
    return httpUtil
      .get(`/api/am/dashboard/v1/company/groups`)
      .then((res) => {
        dispatch({
          type: 'GET_GROUPS_COMPLETE',
          payload: res || []
        });
        return res;
      })
      .catch((error) => {
        let errorMessage = '';
        if (error?.response?.status != 401) {
          errorMessage = `An error occurred while fetching groups ${
            error?.response?.data?.error
              ? ': ' + error?.response?.data?.error
              : ''
          }`;
          notify(errorMessage, 'error');
        }
        dispatch({
          type: 'GET_GROUPS_FAILURE',
          errorMessage: errorMessage
        });
      });
  };
};

export const fetchFederatedCompanyDomains = () => {
  return (dispatch: Function) => {
    dispatch({ type: 'GET_FEDERATED_COMPANY_DOMAINS_START' });
    return httpUtil
      .post(
        `/api/am/dashboard/v1/company/getCompanyFederatedEmailDomainNames`,
        {
          activeFlag: 'Y'
        }
      )
      .then((res: any) => {
        dispatch({
          type: 'GET_FEDERATED_COMPANY_DOMAINS_COMPLETE',
          payload: res || []
        });
        return res;
      })
      .catch((error) => {
        let errorMessage = '';
        if (error?.response?.status != 401) {
          errorMessage = `An error occurred while fetching domain ${
            error?.response?.data?.error
              ? ': ' + error?.response?.data?.error
              : ''
          }`;
          notify(errorMessage, 'error');
        }
        dispatch({
          type: 'GET_FEDERATED_COMPANY_DOMAINS_FAILURE',
          errorMessage: errorMessage
        });
      });
  };
};
