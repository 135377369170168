import React, { useState, useEffect, useRef, useCallback } from 'react';
import moment from 'moment';
import { AgGridReact } from 'ag-grid-react';
import { Link } from 'react-router-dom';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import httpUtil from '../../../helpers/interceptor';

import Loader from '../../atoms/Loader';
import notify from '../../../helpers/notification';
import RefreshButton from '../../atoms/RefreshButton';
import Filters from '../../molecules/Filters';
import './index.css';
import { capitalizeFirstLetter } from '../../../helpers/common';
import { cloneDeep } from 'lodash';
import Dialog from '../../atoms/Dialog';
import { ScheduledReportDelete } from '../../../services';
import Dropdown from '../../atoms/Dropdown';
import { hasAccess } from '../../../helpers';

const AuditReportScheduleTable = () => {
  const gridRef = useRef(null);
  const [gridApi, setGridApi] = useState<any>(null);

  const [rows, setRows] = React.useState([]);
  const [columns, setColumns] = React.useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = React.useState(10);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [finalSearch, setFinalSearch] = useState<string | null>(null);
  const [filterValue, setFilterValue] = useState<any>({});
  const [validation, setValidation] = useState<any>({});
  const [refreshing, setRefreshing] = useState(false);
  const [paginationPageSize, setPaginationPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteReportData, setDeleteReportData] = useState<any>({});
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  // const [deleteDialogvalidation, setDeleteDialogvalidation] = useState<any>({});
  // const [comment, setComment] = useState('');

  const frequencyOpt = ['Daily', 'Weekly', 'Monthly'].map((value) => ({
    value: value.toLowerCase(),
    label: value
  }));

  frequencyOpt.push({ value: 'oneTime', label: 'One Time' });

  const initialFilterValue = [
    {
      name: 'reportScheduleName',
      label: 'Report Name',
      type: 'text',
      show: true,
      value: ''
    },
    {
      name: 'description',
      label: 'Description',
      type: 'text',
      show: false,
      value: ''
    },
    {
      name: 'startDate',
      label: 'Start Date',
      type: 'date',
      show: false,
      value: ''
    },
    {
      name: 'endDate',
      label: 'End Date',
      type: 'date',
      show: false,
      value: ''
    },
    {
      name: 'frequency',
      label: 'Frequency',
      type: 'select',
      show: false,
      value: null,
      className: '',
      options: frequencyOpt
    },
    {
      name: 'recipients',
      label: 'Recipients',
      type: 'text',
      show: false,
      value: null,
      className: ''
    },
    {
      name: 'createdBy',
      label: 'Created By',
      type: 'text',
      show: false,
      value: null,
      className: ''
    },
    {
      name: 'creationDate',
      label: 'Created Date',
      type: 'date',
      show: false,
      value: null,
      className: ''
    }
  ];

  useEffect(() => {
    setFilterValue((preVal: any) => {
      return {
        ...(preVal || {}),
        ...{
          filters: initialFilterValue
        }
      };
    });
  }, []);

  useEffect(() => {
    const columnsList = [
      {
        headerName: 'Report Name',
        field: 'reportName',
        minWidth: 250,
        flex: 1
      },
      {
        headerName: 'Start Date',
        field: 'startDate',
        cellRenderer: (params: any) => {
          return params?.value
            ? moment(params?.value, 'YYYY-MM-DD').format('MM-DD-YYYY')
            : '-';
        },
        width: 160
      },
      {
        headerName: 'End Date',
        field: 'endDate',
        cellRenderer: (params: any) => {
          return params?.value
            ? moment(params?.value, 'YYYY-MM-DD').format('MM-DD-YYYY')
            : '-';
        },
        width: 160
      },
      {
        headerName: 'Frequency',
        field: 'frequency',
        width: 160
      },
      {
        headerName: 'Created By',
        field: 'createdBy',
        width: 160
      },
      {
        headerName: 'Last Updated By',
        field: 'lastUpdatedBy',
        width: 160
      },
      {
        headerName: 'Created Date',
        field: 'creationDate',
        width: 160
      },
      {
        headerName: 'Action',
        field: 'id',
        pinned: 'right',
        width: 180,
        cellClass: ['ag-cell-overflow-visible'],
        cellRenderer: (params: any) => {
          const startDate = moment().startOf('day');
          const timeEnd = moment(params.data?.endDate);
          const diff = timeEnd.diff(startDate);
          const diffDuration = moment.duration(diff);
          return params.data ? (
            <>
              <Link to={`/audit-report-schedule/view/${params.data?.idHex}`}>
                View
              </Link>
              <>
                {/* {diffDuration.asDays() > 0 && ( */}
                <>
                  {' '}
                  /{' '}
                  <Link
                    to={`/audit-report-schedule/update/${params.data?.idHex}`}
                  >
                    Edit
                  </Link>
                </>
                {/* )} */}
                {hasAccess('auditReportSchedule', 'delete') && (
                  <>
                    {' '}
                    /{' '}
                    <a
                      onClick={() => {
                        onDeleteReport(params.data);
                      }}
                    >
                      Delete
                    </a>
                  </>
                )}
              </>
            </>
          ) : (
            <span className="text-danger">{params?.data?.remark}</span>
          );
        }
      }
    ].map((item: any) => {
      let itemTemp: any = item;
      itemTemp.headerClass = 'text-weight-900';
      itemTemp.resizable = true;
      itemTemp.suppressMenu = true;
      // itemTemp.flex = 1;

      if (item.field !== 'action') {
        itemTemp.sortable = true;
        itemTemp.headerTooltip = true;
      } else {
        itemTemp.sortable = false;
      }
      return itemTemp;
    });
    setColumns(columnsList);
  }, [gridApi]);

  useEffect(() => {
    if (gridRef && gridRef.current) {
      (gridRef.current as any)?.api?.setServerSideDatasource(datasource);
    }
  }, [finalSearch]);

  useEffect(() => {
    checkValidation();
  }, [filterValue]);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    params.api.setServerSideDatasource(datasource);
  };

  const getOverlayNoRowsTemplate = () => {
    return 'No Report Sceheduled';
  };

  const checkValidation = () => {
    let errors: any = [];

    filterValue?.filters?.forEach((item: any) => {
      let error: any = {};
      if (item?.show) {
        if (item?.type === 'text' || item?.type === 'number') {
          const fieldValue = item?.value?.trim();
          if (fieldValue === '') {
            error.name = item?.name;
            error.message = `${item?.label} is required`;
          }
        } else if (item?.type === 'date' || item?.type === 'select') {
          const fieldValue = item?.value;
          if (!fieldValue) {
            error.name = item?.name;
            error.message = `${item?.label} is required`;
          }
        }
      }
      if (Object.keys(error).length) {
        errors.push(error);
      }
    });

    setValidation((preVal: any) => {
      return {
        ...(preVal || {}),
        ...{ filters: errors }
      };
    });

    return errors.length === 0;
  };
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setIsSubmitted(true);

    if (!checkValidation()) {
      return;
    }

    const filterResult = {
      ...filterValue?.filters?.reduce((acc: any, item: any) => {
        if (item.value !== '' && item.value !== null) {
          if (item.type === 'dateRange') {
            const tempValue = item.value;
            acc[item.name] = {
              startDate: tempValue?.length
                ? moment(new Date(tempValue[0])).format('YYYY-MM-DD')
                : null,
              endDate:
                tempValue?.length === 2
                  ? moment(new Date(tempValue[1])).format('YYYY-MM-DD')
                  : null
            };
          } else {
            acc[item.name] = item.value;
          }
        }
        return acc;
      }, {})
    };
    setFinalSearch(filterResult);

    refreshCache(undefined);
  };

  const refreshCache = useCallback((route) => {
    if (gridRef && gridRef.current) {
      (gridRef.current as any)?.api.refreshServerSide({
        route: route,
        purge: true
      });
    }
  }, []);

  const resetAndRefresh = () => {
    setFilterValue((preVal: any) => {
      return {
        ...(preVal || {}),
        ...{
          filters: initialFilterValue
        }
      };
    });
    setFinalSearch(null);
    refreshCache(undefined);
  };

  const calculateCurrentPage = (startIndex: number, itemsPerPage: number) => {
    return Math.floor(startIndex / itemsPerPage) + 1;
  };

  const getPageSize = () => {
    if (gridRef?.current && (gridRef?.current as any)?.api)
      return (gridRef.current as any).api.paginationGetPageSize();
    return null;
  };

  const getLastRowIndex = (request: any, results: any) => {
    if (!results) return undefined;
    var currentLastRow = (request.startRow || 0) + results.length;
    return currentLastRow < (request.endRow || 0) ? currentLastRow : undefined;
  };

  const datasource = {
    getRows(params: any) {
      let sortModel: any = {};
      if (params?.request?.sortModel) {
        const sortModels: any = params?.request?.sortModel;
        for (let sortM of sortModels) {
          sortModel[sortM.colId] = sortM['sort'];
        }
      }

      const filterBody: any = cloneDeep(finalSearch || {});
      // ? { ...(finalSearch as any), excelExport: false }
      // : { excelExport: false };

      if (filterBody['startDate']) {
        filterBody['startDate'] = moment(filterBody['startDate']).format(
          'YYYY-MM-DD'
        );
      }
      if (filterBody['endDate']) {
        filterBody['endDate'] = moment(filterBody['endDate']).format(
          'YYYY-MM-DD'
        );
      }

      if (filterBody['creationDate']) {
        filterBody['creationDate'] = moment(filterBody['creationDate']).format(
          'YYYY-MM-DD'
        );
      }
      if (filterBody['frequency']) {
        filterBody['frequency'] = filterBody['frequency']?.value;
      }

      const { startRow, endRow } = params.request;
      const currentPage = calculateCurrentPage(
        startRow,
        getPageSize() || paginationPageSize
      );
      setLoading(true);
      return httpUtil
        .post(
          `/api/am/scheduleJobs/v1/get/audit/scheduleJobs?page=${currentPage}&size=${
            getPageSize() || paginationPageSize
          }`,
          filterBody
          // { ...filterBody, sortModel }
        )
        .then((res: any) => {
          setLoading(false);
          setRefreshing(false);
          var lastRow = getLastRowIndex(params, res?.data || []);
          const result = res?.data?.map((item: any) => {
            return {
              ...item,
              ...{
                frequency: item?.frequency
                  ? item?.frequency?.toLowerCase() == 'onetime'
                    ? 'One Time'
                    : capitalizeFirstLetter(item?.frequency)
                  : '',
                creationDate: item.creationDate
                  ? moment(item.creationDate).format('MM-DD-YYYY')
                  : '-',
                lastUpdatedBy: item.lastUpdatedBy,
                lastUpdatedDate: item.lastUpdatedDate
                  ? moment(item.lastUpdatedDate).format('MM-DD-YYYY')
                  : '-'
              }
            };
          });

          setRows(result || []);
          params.successCallback(result, res.totalSize || undefined);
        })
        .catch((error) => {
          setRefreshing(false);
          setLoading(false);
          setRows([]);
          const errorMessage = `An error occurred while fetching Audit Scheduled Report ${
            error?.response?.data?.error
              ? ': ' + error?.response?.data?.error
              : ''
          }`;
          notify(errorMessage, 'error');
          params.failCallback();
        });
    }
  };

  // useEffect(() => {
  //   if (gridApi) {
  //     if (status === 'PENDING') {
  //       gridApi.showLoadingOverlay();
  //     } else {
  //       if (rows && rows.length === 0) {
  //         gridApi.showNoRowsOverlay();
  //       } else {
  //         gridApi.hideOverlay();
  //       }
  //     }
  //   }
  // }, [rows, status, gridApi]);

  const getRowStyle = (params: any) => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: '#f2f2f2' };
    }
  };

  const onChangeFilter = (filtersValue: any) => {
    setFilterValue((preVal: any) => {
      return { ...(preVal || {}), filters: filtersValue };
    });
  };

  const confirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  async function onDeleteReport(data: any) {
    setDeleteReportData(data);
    setConfirmDialogOpen(true);
  }

  useEffect(() => {
    // setComment('');
    setValidation({});
    setConfirmDialogOpen(false);
  }, [open]);

  // const checkDeleteDialogValidation = (isCommentRequired = false) => {
  //   let errors = { ...deleteDialogvalidation };
  //   let justificationValue = comment?.trim();

  //   if (!justificationValue && isCommentRequired) {
  //     errors.justification = 'Required';
  //   } else if (
  //     isCommentRequired &&
  //     justificationValue &&
  //     justificationValue.length < 25
  //   ) {
  //     errors.justification = 'Justification must be at least 25 characters';
  //   } else if (
  //     isCommentRequired &&
  //     justificationValue &&
  //     justificationValue.length > 250
  //   ) {
  //     errors.justification = "You can't write more than 250 characters";
  //   } else {
  //     delete errors.justification;
  //   }
  //   setDeleteDialogvalidation(errors);
  //   return Object.keys(errors).length === 0;
  // };

  async function deleteScheduledReport(data: any) {
    try {
      // if (checkDeleteDialogValidation(true)) {
      setIsLoading(true);
      const body = { id: data?.idHex };
      await ScheduledReportDelete(body);
      setIsLoading(false);
      setDeleteReportData({});
      setConfirmDialogOpen(false);
      setRefreshing(true);
      refreshCache(undefined);
      // }
    } catch (e) {
      setIsLoading(false);
    }
  }

  return (
    <>
      <div className="audit-report-table">
        <div className="card qtr-margin-top qtr-margin-bottom">
          <div className="card-header">
            <h5 className="text-primary text-capitalize">Scheduled Report</h5>
          </div>
          <div className="card-body">
            <form method="POST" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <div className="filter-bar-row filter-tool">
                    <div className="filter-bar-item filter-bar-left filter-tool-item">
                      <div className="flex">
                        <span className="base-margin-right half-margin-top">
                          Search By
                        </span>
                      </div>
                    </div>
                    <div className="filter-bar-item filter-bar-center filter-tool-item">
                      <div className="row">
                        <div className="col-auto" style={{ maxWidth: '100%' }}>
                          <Filters
                            filters={filterValue?.filters || []}
                            onChange={onChangeFilter}
                            validation={
                              isSubmitted && validation?.filters
                                ? validation?.filters
                                : []
                            }
                            searchButton={
                              <>
                                <button
                                  type="submit"
                                  className="btn"
                                  disabled={loading}
                                >
                                  Search
                                </button>

                                <button
                                  type="button"
                                  className="btn btn--dark"
                                  disabled={loading}
                                  onClick={resetAndRefresh}
                                >
                                  Reset
                                </button>
                                <RefreshButton
                                  onClick={() => {
                                    setRefreshing(true);
                                    refreshCache(undefined);
                                  }}
                                  disabled={loading}
                                  loading={loading && refreshing}
                                />
                              </>
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="filter-bar-item filter-bar-right filter-tool-item">
                      <div className="filter-bar-right-content">
                        <div className="pull-right">
                          {/* <ExcelExport
                          disabled={loading}
                          loading={loading}
                          fileName="Audit_report"
                          getData={() => exportToExcel(rows)}
                          hidden={rows.length == 0}
                        /> */}
                          <Link
                            to="/audit-report-schedule/create"
                            className="btn btn--ghost qtr-margin-right"
                          >
                            Create
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  {isSubmitted &&
                  validation?.filters &&
                  validation?.filters?.filter(
                    (filterItem: any) => filterItem?.name === 'filterCount'
                  )?.length ? (
                    <div
                      className="alert alert--warning"
                      style={{ marginBottom: '8px' }}
                    >
                      <div className="alert__icon icon-warning-outline"></div>
                      <div className="alert__message">
                        {
                          validation?.filters?.find(
                            (filterItem: any) =>
                              filterItem?.name === 'filterCount'
                          )?.message
                        }
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </form>
          </div>
        </div>

        <div
          className={`ag-theme-alpine`}
          style={{
            height: 470,
            width: '100%'
          }}
        >
          <AgGridReact
            domLayout={'normal'}
            ref={gridRef}
            columnDefs={columns as any}
            pagination={true}
            gridOptions={{ suppressContextMenu: true }}
            // alwaysMultiSort
            paginationPageSize={pageSize}
            onGridReady={onGridReady}
            overlayNoRowsTemplate={getOverlayNoRowsTemplate()}
            loadingOverlayComponent={Loader}
            enableCellTextSelection={true}
            suppressCellFocus={true}
            getRowStyle={getRowStyle}
            headerHeight={38}
            rowHeight={38}
            cacheBlockSize={pageSize}
            tooltipShowDelay={0}
            rowModelType={'serverSide'}
          ></AgGridReact>
        </div>
      </div>
      <Dialog
        open={confirmDialogOpen}
        size="small"
        onDialogClose={confirmDialogClose}
        closeOnClickOutside={false}
        title={`Confirm`}
        body={
          <div>
            <h4>
              Are you sure you want to <b>delete</b> the Scheduled Report{' '}
              <b>{deleteReportData?.reportName}</b>
            </h4>

            {/* <div className={`form-group base-margin-top`}>
              <div className="form-group__text">
                <textarea
                  id="justification"
                  name="justification"
                  rows={1}
                  onChange={(e) => setComment(e.target.value)}
                  // disabled={readOnly}
                  value={comment}
                  required
                />
                <label htmlFor="justification">
                  Please provide a detailed justification{' '}
                  <span
                    className="text-danger"
                    title="this is a required field"
                  >
                    *
                  </span>{' '}
                  <span className="text-danger">(minimum 25 characters)</span>
                  {deleteDialogvalidation.justification && (
                    <span className="text-danger text-size-12">
                      {' '}
                      {deleteDialogvalidation.justification}
                    </span>
                  )}
                </label>
              </div>
            </div> */}

            {isLoading && (
              <div className="center-holder-wrap">
                <Loader />
              </div>
            )}
          </div>
        }
        footer={
          <div>
            <button
              type="button"
              onClick={confirmDialogClose}
              className="btn btn--ghost"
              disabled={isLoading}
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={() => deleteScheduledReport(deleteReportData)}
              className="btn btn--primary"
              disabled={isLoading}
            >
              Confirm
            </button>
          </div>
        }
      />
    </>
  );
};
export default AuditReportScheduleTable;
