import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Loader from '../../atoms/Loader';
import httpUtil from '../../../helpers/interceptor';
import notify from '../../../helpers/notification';
import Dialog from '../../atoms/Dialog';

export interface CompanyReactivateDialogProps {
  companyDetail: any;
  buttonTitle: string;
  open?: boolean;
  onChange?: Function;
  onSave?: Function;
}

export interface Task {
  taskName: string;
  buttonTitle: string;
  open?: boolean;
  onChange?: Function;
}

const CompanyReactivateDialog = ({
  companyDetail = {},
  buttonTitle,
  open: isDialogOpen = false,
  onChange = () => {},
  onSave = () => {}
}: CompanyReactivateDialogProps) => {
  const [open, setOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [comment, setComment] = useState('');
  const [validation, setValidation] = useState<any>({});

  const authData = useSelector((state: any) => state?.auth);
  const { user, userActiveRolePermissions } = authData;

  useEffect(() => {
    setOpen(isDialogOpen);
  }, [isDialogOpen]);

  useEffect(() => {
    setComment('');
    setValidation({});
    setConfirmDialogOpen(false);
  }, [open]);

  // useEffect(() => {
  //   checkValidation();
  // }, [comment]);

  const dialogOpen = () => {
    setOpen(true);
    if (onChange) onChange(true);
  };

  const statusUpdateDialogOpen = (action: string) => {
    if (checkValidation()) {
      setConfirmDialogOpen(true);
    }
  };

  const confirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const dialogClose = () => {
    setOpen(false);
    if (onChange) onChange(false);
  };

  const checkValidation = () => {
    let errors = { ...validation };
    if (comment?.trim()) {
      if (comment && comment.length < 25) {
        errors.justification = 'Justification must be at least 25 characters';
      } else if (comment && comment.length > 250) {
        errors.justification = "You can't write more than 250 characters";
      } else {
        delete errors.justification;
      }
    }

    setValidation(errors);
    return Object.keys(errors).length === 0;
  };

  const updateStatusClick = () => {
    const userType = [];
    if (
      userActiveRolePermissions?.find(
        (roleItem: any) => roleItem?.name === companyDetail?.userGroupName
      )
    ) {
      userType.push('OP');
    }

    if (
      companyDetail?.lsPAMDetails
        ?.map((item: any) => item?.userId)
        .includes(user?.ccoid)
    ) {
      userType.push('PAM');
    }
    // userType.push('PAM');
    type AdminEmail = {
      email: string;
    };

    const companyStatusUpdateData: {
      companyId: string;
      remarks?: string;
      supplierSiteId?: string;
      userType: string[];
      companyName: string;
      paEmailsList: string[];
      pamEmailsList: string[];
      sponsorEmailsList: string[];
      opEmailsList: string[];
      siteAddress?: string;
    } = {
      companyId: companyDetail?.companyId || '',
      companyName: companyDetail?.vendorName || '',
      supplierSiteId: companyDetail?.supplierSiteId || '',
      userType: userType,
      paEmailsList: [],
      pamEmailsList: [],
      sponsorEmailsList: [],
      opEmailsList: []
    };

    companyStatusUpdateData.remarks = comment || '';

    const paEmailsList =
      companyDetail?.lsPA
        ?.filter(({ activeFlag }: any) => activeFlag?.toLowerCase() == 'y')
        .map(({ email }: AdminEmail) => email) || [];
    const pamEmailsList =
      companyDetail?.lsPAMDetails
        ?.filter(({ activeFlag }: any) => activeFlag?.toLowerCase() == 'y')
        .map(({ email }: AdminEmail) => email) || [];
    const sponsorEmailsList =
      companyDetail?.lsSponsorAdmin
        ?.filter(({ activeFlag }: any) => activeFlag?.toLowerCase() == 'y')
        ?.map(({ email }: AdminEmail) => email) || [];
    const opEmailsList =
      companyDetail?.lsOPAdmin?.map(({ email }: AdminEmail) => email) || [];

    companyStatusUpdateData.paEmailsList = paEmailsList;
    companyStatusUpdateData.pamEmailsList = pamEmailsList;
    companyStatusUpdateData.sponsorEmailsList = sponsorEmailsList;
    companyStatusUpdateData.opEmailsList = opEmailsList;

    companyStatusUpdateData.siteAddress = `${companyDetail?.addressLine1}${
      companyDetail?.addressLine2 ? ', ' + companyDetail?.addressLine2 : ''
    }${companyDetail?.city ? ', ' + companyDetail?.city : ''}${
      companyDetail?.country ? ', ' + companyDetail?.country : ''
    }${companyDetail?.postalCode ? ', ' + companyDetail?.postalCode : ''}`;

    setIsLoading(true);
    return httpUtil
      .post(
        '/api/am/dashboard/v1/company/offboard/reactivate/submit',
        companyStatusUpdateData
      )
      .then((res: any) => {
        setIsLoading(false);
        const { status, msg } = res;

        if (status) {
          if (status.toLowerCase() == 'success') {
            notify(msg, 'success');
            if (onSave) onSave();
            setOpen(false);
            if (onChange) onChange(false);
          } else {
            notify(msg, 'error');
          }
        }
        return res;
      })
      .catch((error) => {
        setIsLoading(false);
        let errorMessage = '';
        if (error?.response?.status != 401) {
          errorMessage = `An error occurred while reactivating company ${
            error?.response?.data?.msg ? ': ' + error?.response?.data?.msg : ''
          }`;
          notify(errorMessage, 'error');
        }
      });
  };

  return (
    <>
      {buttonTitle && companyDetail?.activeFlag?.toLowerCase() === 'n' && (
        <a
          className="base-margin-left btn btn--small btn--dark"
          onClick={dialogOpen}
        >
          {buttonTitle}
        </a>
      )}
      <Dialog
        open={open}
        size="large"
        onDialogClose={dialogClose}
        closeOnClickOutside={false}
        title={`Company : ${companyDetail?.vendorName}`}
        body={
          <div>
            {isLoading && (
              <div className="center-holder-wrap">
                <Loader />
              </div>
            )}
            <div className="panel panel--bordered qtr-margin-top">
              <div className="row">
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                  <div className="record-info">
                    <span className="record-label">Company Name</span>
                    <div>
                      <span className="record-value">
                        {companyDetail?.vendorName}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                  <div className="record-info">
                    <span className="record-label">Company Type</span>
                    <div>
                      <span className="record-value">
                        {companyDetail?.companyType}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                  <div className="record-info">
                    <span className="record-label">Vendor Id</span>
                    <div>
                      <span className="record-value">
                        {companyDetail?.vendorId}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                  <div className="record-info">
                    <span className="record-label">Site Id</span>
                    <div>
                      <span className="record-value">
                        {companyDetail?.supplierSiteId}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                  <div className="record-info">
                    <span className="record-label">Site Address</span>
                    <div>
                      <span className="record-value">
                        {companyDetail?.addressLine1}
                        {companyDetail?.addressLine2
                          ? ', ' + companyDetail?.addressLine2
                          : null}
                        {companyDetail?.city
                          ? ', ' + companyDetail?.city
                          : null}
                        {companyDetail?.country
                          ? ', ' + companyDetail?.country
                          : null}
                        {companyDetail?.postalCode
                          ? ', ' + companyDetail?.postalCode
                          : null}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                  <div className="record-info">
                    <span className="record-label">SCREG Status</span>
                    <div>
                      <span className="record-value">
                        {companyDetail?.activeFlag?.toLowerCase() === 'y' ? (
                          <span className="label label--small  label--success  label--bordered">
                            Active
                          </span>
                        ) : (
                          <span className="label label--small label--warning label--bordered">
                            Inactive
                          </span>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`form-group base-margin-top`}>
              <div className="form-group__text">
                <textarea
                  id="justification"
                  name="justification"
                  rows={1}
                  onChange={(e) => setComment(e.target.value)}
                  // disabled={readOnly}
                  value={comment}
                  required
                />
                <label htmlFor="justification">
                  Please provide a detailed justification{' '}
                  <span
                    className="text-danger"
                    title="this is a required field"
                  >
                    *
                  </span>{' '}
                  <span className="text-danger">(minimum 25 characters)</span>
                  {validation.justification && (
                    <span className="text-danger text-size-12">
                      {' '}
                      {validation.justification}
                    </span>
                  )}
                </label>
              </div>
            </div>
          </div>
        }
        footer={
          <div>
            <button
              type="button"
              onClick={() => statusUpdateDialogOpen('REACTIVATE')}
              className="btn btn--dark"
              disabled={isLoading}
            >
              Reactivate
            </button>
          </div>
        }
      />

      <Dialog
        open={confirmDialogOpen}
        size="small"
        onDialogClose={confirmDialogClose}
        closeOnClickOutside={false}
        title={`Confirm`}
        body={
          <div>
            <h4>
              Are you sure to{' '}
              <b>
                {/* {' '}
                <span style={{ textTransform: 'capitalize' }}>
                  {value?.action?.toLowerCase()}
                </span> */}
                reactivate
              </b>{' '}
              the company <b>{companyDetail?.vendorName}</b>
            </h4>

            {isLoading && (
              <div className="center-holder-wrap">
                <Loader />
              </div>
            )}
          </div>
        }
        footer={
          <div>
            <button
              type="button"
              onClick={confirmDialogClose}
              className="btn btn--ghost"
              disabled={isLoading}
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={() => updateStatusClick()}
              className="btn btn--primary"
              disabled={isLoading}
            >
              Confirm
            </button>
          </div>
        }
      />
    </>
  );
};

export default CompanyReactivateDialog;
