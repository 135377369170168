import React from 'react';
import { Tooltip } from 'react-tooltip';

import './index.css';
import { SelectOption } from '../../molecules/Select/Select';
import { capitalizeFirstLetter } from '../../../helpers/common';

export interface UsersListValueProps {
  users: SelectOption[];
  showStatus?: boolean;
}

const UsersListValue = ({ users, showStatus = true }: UsersListValueProps) => {
  return (
    <>
      {users && users?.length ? (
        <div className="qtr-margin-top card user-value-card">
          <div>
            {users?.map((item: SelectOption, index) => (
              <div
                className="panel panel--bordered user-value-panel"
                key={`${item.value}${index}`}
              >
                <div>
                  {capitalizeFirstLetter(item?.firstName || '-') +
                    (' ' + capitalizeFirstLetter(item?.lastName || '-'))}{' '}
                  <span className="text-gray-600">({item?.email || '-'})</span>
                  {showStatus && item?.activeFlag ? (
                    <span>
                      {item?.activeFlag?.toLowerCase() === 'y' ? (
                        <>
                          <span
                            className="icon-check-outline text-success qtr-margin-left"
                            style={{ cursor: 'pointer' }}
                            data-tooltip-id={`tooltip-user-status`}
                          ></span>
                          <Tooltip id={`tooltip-user-status`}>
                            <span style={{ zIndex: 1111 }}>Active</span>
                          </Tooltip>
                        </>
                      ) : null}

                      {item?.activeFlag?.toLowerCase() === 'n' ? (
                        <>
                          {item?.status?.toLowerCase() === 'denied' ? (
                            <span
                              className="icon-blocked text-danger  qtr-margin-left"
                              style={{ cursor: 'pointer' }}
                              data-tooltip-id={`tooltip-pending-nomination-${item?.userId}-${index}`}
                            ></span>
                          ) : (
                            <span
                              className="icon-time text-warning-alt  qtr-margin-left"
                              style={{ cursor: 'pointer' }}
                              data-tooltip-id={`tooltip-pending-nomination-${item?.userId}-${index}`}
                            ></span>
                          )}

                          <Tooltip
                            id={`tooltip-pending-nomination-${item?.userId}-${index}`}
                          >
                            <div>
                              <div style={{ zIndex: 1111 }}>
                                Nomination is {item?.status?.toLowerCase()}
                              </div>
                              {item?.status?.toLowerCase() === 'denied' ? (
                                <div style={{ zIndex: 1111 }}>
                                  Remarks : {item?.remarks}
                                </div>
                              ) : null}
                            </div>
                          </Tooltip>
                        </>
                      ) : null}
                    </span>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default UsersListValue;
