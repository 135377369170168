import React from 'react';

import AuditReportScheduleModifyComponent from '../../organisms/AuditReportScheduleModify';

const AuditReportScheduleModify = () => {
  return (
    <div className="container">
      <AuditReportScheduleModifyComponent />
    </div>
  );
};
export default AuditReportScheduleModify;
