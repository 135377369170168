import React from 'react';

import AsyncSelect from '../Select/AsyncSelect';
import { components, OptionProps } from 'react-select';
import { SelectOption } from '../Select/Select';

export const UserSelectOption = (props: OptionProps<SelectOption>) => {
  const { data } = props;
  return (
    <div>
      <components.Option className="half-padding" {...props}>
        <div>
          {data?.firstName + ' ' + data?.lastName} ({data?.userId})
        </div>
        <div className="text-size-12">{data?.email}</div>
      </components.Option>
    </div>
  );
};

const UserSelect = ({
  value,
  onChange,
  showSaveBtn,
  userSelectRendered = undefined,
  ...props
}: any) => {
  const removeValue = (rowValue: string) => {
    const existingValue = value || [];
    const filteredValue = existingValue.filter(
      (item: SelectOption) => item.value !== rowValue
    );
    if (onChange) onChange(filteredValue);
  };

  if (value) value = Array.isArray(value) ? value : [value];

  return (
    <>
      <div className="flex flex-between flex-middle">
        <div style={{ flex: 1 }}>
          <AsyncSelect
            value={value}
            onChange={onChange}
            components={{ Option: UserSelectOption }}
            {...props}
          />
        </div>
        {showSaveBtn && (
          <div className="qtr-margin-left">
            {' '}
            <span className="icon-save text-size-20"></span>{' '}
          </div>
        )}
      </div>
      {value && value?.length ? (
        <div className="qtr-margin-top card">
          <div className="responsive-table" style={{ maxHeight: '137px' }}>
            <table
              className="table table--lined table--compressed"
              aria-label="Striped table example"
            >
              <tbody>
                {value?.map((item: SelectOption) => (
                  <tr key={item.value}>
                    <td style={{ width: `calc(100% - 40px)` }}>
                      {userSelectRendered
                        ? userSelectRendered(item)
                        : `
                      ${item?.firstName + ' ' + item?.lastName} (${
                            item?.label
                          })`}
                    </td>
                    <td className="text-center" style={{ width: '40px' }}>
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => removeValue(item?.value)}
                        className="icon-exit-contain"
                      ></span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default UserSelect;
