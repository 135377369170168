import React, { useEffect, useState, useRef } from 'react';

const Dialog = ({
  title,
  body,
  footer,
  open,
  onDialogClose,
  closeOnClickOutside = true,
  size = 'small',
  className = '',
  showCloseButton = true
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (
      ref.current &&
      !ref.current.contains(event.target) &&
      closeOnClickOutside
    ) {
      dialogClose();
    }
  };

  useEffect(() => {
    if (open !== 'undefined') setDialogOpen(open);
  }, [open]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const dialogClose = () => {
    setDialogOpen(false);
    if (onDialogClose) {
      onDialogClose();
    }
  };

  return (
    <div
      className={`modal modal--${size} ${
        !dialogOpen ? 'hide' : ''
      } ${className}`}
      id="modal"
    >
      <div className="modal__dialog">
        <div ref={ref} className="modal__content">
          {showCloseButton && (
            <a onClick={dialogClose} className="modal__close">
              <span className="icon-close"></span>
            </a>
          )}

          {title && (
            <div className="modal__header">
              <div className="modal__title text-primary">{title}</div>
            </div>
          )}
          <div className="modal__body">{body}</div>
          {footer && (
            <div className="modal__footer" style={{ textAlign: 'center' }}>
              {footer}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Dialog;
