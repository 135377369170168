import React, { useEffect, useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { components, NoticeProps } from 'react-select';
import {
  theme,
  colourStyles,
  DropdownIndicator,
  IndicatorSeparator,
  SingleValue
} from './Select';
import './index.css';

const NoOptionsMessage = (noOptionsMessageProps: NoticeProps) => {
  const MIN_INPUT_LENGTH = 3;
  const [noOptionsMsg, setNoOptionsMsg] = useState('');

  useEffect(() => {
    if (!noOptionsMessageProps?.selectProps?.inputValue) {
      setNoOptionsMsg('Start typing to find');
    } else if (
      noOptionsMessageProps?.selectProps?.inputValue?.length < MIN_INPUT_LENGTH
    ) {
      setNoOptionsMsg(
        `Search input must be at least ${MIN_INPUT_LENGTH} characters`
      );
    } else {
      setNoOptionsMsg('');
    }
  }, [noOptionsMessageProps]);

  return (
    <components.NoOptionsMessage {...noOptionsMessageProps}>
      {noOptionsMsg ? noOptionsMsg : noOptionsMessageProps?.children}
    </components.NoOptionsMessage>
  );
};

const SelectComponent = ({ components: componentsProps, ...props }: any) => {
  return (
    <AsyncPaginate
      debounceTimeout={300}
      className="basic-multi-select"
      theme={theme}
      styles={colourStyles}
      maxMenuHeight={200} // for 5 items changing menu height
      components={{
        ...{
          NoOptionsMessage,
          DropdownIndicator,
          IndicatorSeparator,
          SingleValue
        },
        ...componentsProps
      }}
      {...props}
    />
  );
};

export default SelectComponent;
