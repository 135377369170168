import React, { useState, useEffect } from 'react';

import Loader from '../../atoms/Loader';
import Dialog from '../../atoms/Dialog';
import UsersListValue from '../../atoms/UsersListValue';
import { SelectOption } from '../../molecules/Select';

export interface CompanyDetailDialogProps {
  companyDetail: any;
  buttonTitle?: string;
  open?: boolean;
  handleNext: React.MouseEventHandler<HTMLButtonElement>;
  onChange: Function;
  loading?: boolean;
}

const CompanyDetailDialog = ({
  companyDetail,
  buttonTitle = '',
  open: isDialogOpen = false,
  handleNext,
  onChange,
  loading = false
}: CompanyDetailDialogProps) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    companyName,
    vendorId,
    addressLine1,
    addressLine2,
    city,
    country,
    postalCode,
    companyType,
    dunsNumber,
    state,
    emailDomains,
    countries,
    customers,
    pa,
    newPa,
    pam,
    sponsor,
    primaryOperationalAdmin,
    secondaryOperationalAdmin,
    parentCompany,
    organizations,
    groupName,
    supplierSiteId
  } = companyDetail;

  const companyDetails = [
    { title: 'Company Name', value: companyName },
    { title: 'Vendor Id', value: vendorId },
    { title: 'Site Id', value: supplierSiteId },
    { title: 'DUNS Number', value: dunsNumber },
    { title: 'Address Line 1', value: addressLine1 },
    { title: 'Address Line 2', value: addressLine2 },
    { title: 'City', value: city },
    { title: 'State', value: state },
    { title: 'Country', value: country },
    { title: 'Zip/Postal Code', value: postalCode },
    { title: 'Email domains', value: emailDomains?.join(', ') },
    { title: 'Group Name', value: groupName },
    { title: 'Company Type', value: companyType }
  ];

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    setOpen(isDialogOpen);
  }, [isDialogOpen]);

  const dialogOpen = () => {
    setOpen(true);
    if (onChange) onChange(true);
  };

  const dialogClose = () => {
    setOpen(false);
    if (onChange) onChange(false);
  };

  return (
    <>
      <form name="statusForm">
        {buttonTitle && <a onClick={dialogOpen}>{buttonTitle}</a>}
        <Dialog
          open={open}
          size="fluid"
          onDialogClose={dialogClose}
          closeOnClickOutside={false}
          title={`Preview : ${companyName}`}
          body={
            <div>
              {isLoading && (
                <div className="center-holder-wrap">
                  <Loader />
                </div>
              )}

              <div className="card">
                <div className="card-header">
                  <h5 className="text-primary">Company Details</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    {companyDetails.map((item, index) => {
                      return (
                        <div
                          key={`${item.value}${index}`}
                          className="col-xxl-3 col-xl-3 col-lg-3 col-md-6"
                        >
                          <div className="record-info">
                            <span className="record-label">{item.title}</span>
                            <div>
                              <span className="record-value">
                                {item.value || '-'}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    {companyDetail?.isCountryShow ? (
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                        <div className="record-info">
                          <span className="record-label">Countries</span>
                          <div>
                            <span className="record-value">
                              {countries?.join(', ')}
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {companyDetail?.isCustomerShow ? (
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                        <div className="record-info">
                          <span className="record-label">Customers</span>
                          <div>
                            <span className="record-value">
                              {customers?.join(', ')}
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {companyDetail?.isOrgShow ? (
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                        <div className="record-info">
                          <span className="record-label">Company Orgs</span>
                          <div>
                            <span className="record-value">
                              {organizations && organizations?.length
                                ? organizations?.join(', ')
                                : '-'}
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>

                  {parentCompany?.companyName ? (
                    <div className="card qtr-margin-top">
                      <div className="card-header">
                        <h5 className="text-size-16 text-weight-700 text-primary">
                          Parent Company Details
                        </h5>
                      </div>

                      <div className="card-body">
                        <div className="row">
                          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                            <div className="record-info">
                              <span className="record-label">Company Name</span>
                              <div>
                                <span className="record-value">
                                  {parentCompany?.companyName}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                            <div className="record-info">
                              <span className="record-label">Vendor Id</span>
                              <div>
                                <span className="record-value">
                                  {parentCompany?.vendorId}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                            <div className="record-info">
                              <span className="record-label">Company Type</span>
                              <div>
                                <span className="record-value">
                                  {parentCompany?.companyTypeName}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="card half-margin-top">
                <div className="card-header">
                  <h5 className="text-primary">Admin Details</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                      <div className="record-info">
                        <span className="record-label">
                          Partner Administrators
                        </span>
                        <div>
                          <span className="record-value">
                            <UsersListValue
                              users={(pa || [])?.map((item: SelectOption) => {
                                return {
                                  userId: item?.email,
                                  email: item?.email,
                                  firstName: item?.firstName,
                                  lastName: item?.lastName,
                                  activeFlag: item?.activeFlag,
                                  status: item?.status,
                                  remarks: item?.remarks
                                };
                              })}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                      <div className="record-info">
                        <span className="record-label">
                          Invited Partner Administrators
                        </span>
                        <div>
                          <span className="record-value">
                            <UsersListValue
                              users={(newPa || [])?.map(
                                (item: SelectOption) => {
                                  return {
                                    userId: item?.email,
                                    email: item?.email,
                                    firstName: item?.firstName,
                                    lastName: item?.lastName,
                                    activeFlag: item?.activeFlag,
                                    status: item?.status,
                                    remarks: item?.remarks
                                  };
                                }
                              )}
                            />
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                      <div className="record-info">
                        <span className="record-label">
                          Partner Account Managers
                        </span>
                        <div>
                          <span className="record-value">
                            <UsersListValue users={pam || []} />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                      <div className="record-info">
                        <span className="record-label">Sponsor</span>
                        <div>
                          <span className="record-value">
                            <UsersListValue users={sponsor ? [sponsor] : []} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card half-margin-top">
                <div className="card-header">
                  <h5 className="text-primary">Operational Admin Details</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                      <div className="record-info">
                        <span className="record-label">
                          Primary Operational Admin
                        </span>
                        <div>
                          <span className="record-value">
                            <UsersListValue
                              users={primaryOperationalAdmin || []}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                      <div className="record-info">
                        <span className="record-label">
                          Secondary Operational Admin
                        </span>
                        <div>
                          <span className="record-value">
                            <UsersListValue
                              users={secondaryOperationalAdmin || []}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {handleNext && (
                <div className="flex flex-right base-margin-top">
                  <button
                    type="button"
                    onClick={handleNext}
                    className="btn btn--ghost"
                  >
                    Finish
                  </button>
                </div>
              )}
            </div>
          }
          footer={<></>}
        />
      </form>
    </>
  );
};

export default CompanyDetailDialog;
