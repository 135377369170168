import React from 'react';

const MainLayout = ({ header, children, footer, banner }) => {
  return (
    <div>
      {header}
      <div className="content">
        {banner}
        {children}
      </div>
      {footer}
    </div>
  );
};
export default MainLayout;
