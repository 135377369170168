import React, { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';

const Logout = () => {
  const { oktaAuth } = useOktaAuth();
  const [loading, setLoading] = useState(false);

  const handleLogin = async () => {
    setLoading(true);
    await oktaAuth.signOut();
    setLoading(false);
  };

  useEffect(() => {
    handleLogin();
  }, []);

  return (
    <div className="vertical-center">
      <div className="container">
        <div className="section">
          <div className="text-center">
            {loading ? <h5>Logging off...</h5> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Logout;
