import { Action } from '../../actions/ActionTypes.interface';

const initialState: any = {};

export const userReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case 'GET_USER_REVIEW_LIST_COMPLETE': {
      return {
        ...state,
        ...{
          getUserReviewListStatus: 'SUCCESS',
          userReviewList: action.payload,
          getUserReviewListErrorMessage: null
        }
      };
    }
    case 'GET_USER_REVIEW_LIST_START': {
      return {
        ...state,
        ...{
          getUserReviewListStatus: 'PENDING',
          getUserReviewListErrorMessage: null
        }
      };
    }
    case 'GET_USER_REVIEW_LIST_FAILURE': {
      return {
        ...state,
        ...{
          getUserReviewListStatus: 'FAILURE',
          getUserReviewListErrorMessage: action.errorMessage
        }
      };
    }

    case 'GET_USER_REVIEW_LIST_RESET': {
      return {
        ...state,
        ...{
          getUserReviewListStatus: undefined,
          userReviewList: undefined,
          getUserReviewListErrorMessage: null
        }
      };
    }

    case 'GET_EXTERNAL_USERS_LIST_COMPLETE': {
      return {
        ...state,
        ...{
          getExternalUsersListStatus: 'SUCCESS',
          externalUsersList: action.payload,
          getExternalUsersListErrorMessage: null
        }
      };
    }
    case 'GET_EXTERNAL_USERS_LIST_START': {
      return {
        ...state,
        ...{
          getExternalUsersListStatus: 'PENDING',
          getExternalUsersListErrorMessage: null
        }
      };
    }
    case 'GET_EXTERNAL_USERS_LIST_FAILURE': {
      return {
        ...state,
        ...{
          getExternalUsersListStatus: 'FAILURE',
          getExternalUsersListErrorMessage: action.errorMessage
        }
      };
    }

    case 'GET_EXTERNAL_USERS_LIST_RESET': {
      return {
        ...state,
        ...{
          getExternalUsersListStatus: undefined,
          externalUsersList: undefined,
          getExternalUsersListErrorMessage: null
        }
      };
    }
    case 'GET_EXTERNAL_USERS_LIST_UPDATE': {
      let externalUsersList = state?.externalUsersList?.data || [];
      const updatingUser = action.payload;
      externalUsersList = externalUsersList?.map((item: any) => {
        if (updatingUser?.userId === item?.userId) {
          return updatingUser;
        }
        return item;
      });

      return {
        ...state,
        ...{
          externalUsersList: state?.externalUsersList
            ? { ...state?.externalUsersList, ...{ data: externalUsersList } }
            : null
        }
      };
    }
    default:
      return state;
  }
};
