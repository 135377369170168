export const ACTION_TYPES = {
  COMPANY_TYPE_START: 'COMPANY_TYPE_START',
  COMPANY_TYPE_COMPLETE: 'COMPANY_TYPE_COMPLETE',
  COMPANY_TYPE_FAILURE: 'COMPANY_TYPE_FAILURE',
  COMPANY_LIST_START: 'COMPANY_LIST_START',
  COMPANY_LIST_COMPLETE: 'COMPANY_LIST_COMPLETE',
  COMPANY_LIST_FAILURE: 'COMPANY_LIST_FAILURE',
  LOGIN_START: 'LOGIN_START',
  LOGIN_COMPLETE: 'LOGIN_COMPLETE',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  GET_COUNTRIES_COMPLETE: 'GET_COUNTRIES_COMPLETE'
};

interface DispatchAction {
  type: string;
  payload: any;
  errorMessage: string;
  [key: string]: any;
}
export type Action = DispatchAction;
