import React, { useEffect, useState } from 'react';
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite.min.css';

import './index.css';
import Dropdown from '../../atoms/Dropdown';
import Select from '../Select';

export interface FiltersProps {
  onChange?: Function;
  filters?: any;
  validation?: any;
  searchButton?: any;
}

const Filters = ({
  onChange,
  filters,
  validation = [],
  searchButton
}: FiltersProps) => {
  const [filterList, setFilterList] = useState<any>([]);

  useEffect(() => {
    setFilterList(filters);
  }, [filters]);

  const onFilterSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setFilterList((prevValue: any) => {
      const changedValue = (prevValue || []).map((item: any) => {
        if (item?.name === event.target.name) {
          return {
            ...item,
            show: checked,
            ...{ value: !checked ? '' : item?.value }
          };
        }
        return item;
      });
      if (onChange) {
        onChange(changedValue);
      }
      return changedValue;
    });
  };

  const onFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterList((prevValue: any) => {
      const changedValue = (prevValue || []).map((item: any) => {
        if (item?.name === event.target.name) {
          return { ...item, value: event.target.value };
        }
        return item;
      });
      if (onChange) {
        setTimeout(() => {
          onChange(changedValue);
        });
      }
      return changedValue;
    });
  };

  const onDateRangeFilterChange = (name: string, dateRange: Date[] | null) => {
    setFilterList((prevValue: any) => {
      const changedValue = (prevValue || []).map((item: any) => {
        if (item?.name === name) {
          return { ...item, value: dateRange };
        }
        return item;
      });
      if (onChange) {
        setTimeout(() => {
          onChange(changedValue);
        });
      }
      return changedValue;
    });
  };

  const onSelectChange = (name: string, newValue: any) => {
    setFilterList((prevValue: any) => {
      const changedValue = (prevValue || []).map((item: any) => {
        if (item?.name === name) {
          return {
            ...item,
            value: Array.isArray(newValue)
              ? newValue
              : newValue?.value
              ? newValue.value
              : undefined
          };
        }
        return item;
      });
      if (onChange) {
        setTimeout(() => {
          onChange(changedValue);
        });
      }
      return changedValue;
    });
  };

  const getValidationData = (name: string) => {
    return validation?.find((item: any) => item?.name === name);
  };

  return (
    <div className="qtr-padding schedule-filter-wrap">
      {filterList && filterList?.length
        ? filterList.map((item: any, index: number) => {
            const validationData = getValidationData(item?.name);
            return item?.show ? (
              <div
                className={`half-margin-bottom half-margin-top schedule-filter-item`}
                key={`filter${index}`}
              >
                <div className={`form-group`}>
                  <div
                    className={`form-group__text ${
                      item?.type === 'dateRange' ? 'form-datepicker' : ''
                    }`}
                    id={item?.name}
                  >
                    {item?.type === 'text' || item?.type === 'number' ? (
                      <input
                        name={item?.name || ''}
                        type={item?.type}
                        data-testid={item?.name || ''}
                        value={item?.value || ''}
                        onChange={onFilterChange}
                      />
                    ) : null}

                    <label
                      className="text-weight-700 required half-margin-right"
                      htmlFor={item?.label}
                    >
                      {item?.label || item?.name} {item?.tooltip}
                    </label>
                    {item?.type === 'dateRange' ? (
                      <DateRangePicker
                        editable={false}
                        oneTap
                        showOneCalendar
                        container={() => document.getElementById(item?.name)!}
                        format="MM-dd-yyyy"
                        placeholder={'mm-dd-yyyy ~ mm-dd-yyyy'}
                        onChange={(val) =>
                          onDateRangeFilterChange(item?.name, val)
                        }
                        value={item?.value}
                      />
                    ) : null}
                    {item?.type === 'select' ? (
                      <Select
                        isSearchable={true}
                        value={
                          item?.value
                            ? {
                                value: item?.value,
                                label: item?.value
                              }
                            : ''
                        }
                        isClearable
                        name={item?.name || ''}
                        options={
                          item?.options?.length
                            ? item?.options?.map(
                                (item: { value: string; label: string }) => {
                                  return {
                                    value: item?.value,
                                    label: item?.label
                                  };
                                }
                              )
                            : []
                        }
                        className="basic-select"
                        classNamePrefix="select"
                        onChange={(newValue: any) =>
                          onSelectChange(item?.name, newValue)
                        }
                      />
                    ) : null}

                    {item?.type === 'multiSelect' ? (
                      <Select
                        isSearchable={true}
                        isMulti={true}
                        value={item?.value || []}
                        isClearable
                        name={item?.name || ''}
                        options={
                          item?.options?.length
                            ? item?.options?.map(
                                (item: { value: string; label: string }) => {
                                  return {
                                    value: item?.value,
                                    label: item?.label
                                  };
                                }
                              )
                            : []
                        }
                        className="basic-select"
                        classNamePrefix="select"
                        onChange={(newValue: any) =>
                          onSelectChange(item?.name, newValue)
                        }
                      />
                    ) : null}
                  </div>
                  {validationData ? (
                    <div className="form-group__help text-danger" role="alert">
                      <span>{validationData?.message}</span>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null;
          })
        : null}

      <div className="half-margin-bottom half-margin-top schedule-filter-item schedule-filter-menu">
        <div className={`form-group`}>
          <div className={`form-group__text`}>
            <label
              className="text-weight-700 half-margin-right"
              style={{ visibility: 'hidden' }}
            >
              DropDown
            </label>
            <Dropdown
              title={
                <div className="dot-btn">
                  <span className="icon-more rotate-90"></span>
                </div>
              }
              className="schedule-filter-menu"
              showIcon={false}
              content={
                <div>
                  {filterList && filterList?.length
                    ? filterList.map((item: any, index: number) => {
                        return (
                          <div
                            className={`form-group filter-check-item ${
                              item?.disabled ? 'disabled' : ''
                            }`}
                            key={`filterSelect${index}`}
                          >
                            <label className="checkbox">
                              <input
                                type="checkbox"
                                checked={item?.show}
                                name={item?.name}
                                onChange={onFilterSelectChange}
                                disabled={item?.disabled}
                              />
                              <span className="checkbox__input"></span>
                              <span className="checkbox__label">
                                {item?.label || item?.name}
                              </span>
                            </label>
                          </div>
                        );
                      })
                    : null}
                </div>
              }
            />
          </div>
        </div>
      </div>

      {searchButton ? (
        <div className="filter-item" key={`moreFilter`}>
          {searchButton}
        </div>
      ) : null}
    </div>
  );
};

export default Filters;
