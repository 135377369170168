import React, { useEffect, useMemo, useState } from 'react';
import { format, subDays } from 'date-fns';
import { isBefore, addYears } from 'date-fns';
import { DatePicker } from 'rsuite';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import './index.css';
import { Tooltip } from 'react-tooltip';
import UserSelect from '../../molecules/UserSelect';
import { SelectOption } from '../../molecules/Select/Select';
import debounce from 'lodash.debounce';
import {
  fetchInternalUsers,
  fetchAuditReportScheduleDetails,
  ScheduledDateUpdate,
  reportScheduleUpdate
} from '../../../services';
import moment from 'moment';
import Filters from '../../molecules/FiltersModel';
import Loader from '../../atoms/Loader';
import {
  fetchAllCompanyTypes,
  fetchEventActions,
  fetchActors,
  fetchAuditLogCompanies,
  fetchVendorId,
  fetchSiteId
} from '../../../actions';
import {
  capitalizeFirstLetter,
  generateDatesForMonth,
  generateDatesForWeek,
  sanitizeInput
} from '../../../helpers/common';
import Dialog from '../../atoms/Dialog';

const allWeeks = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

const AuditReportScheduleModify = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [value, setValue] = useState<any>({});
  const [validation, setValidation] = useState<any>({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState<string | undefined>();
  const [scheduleDetails, setScheduleDetails] = useState<any>();
  const [isExpired, setIsExpired] = useState<boolean | undefined>();
  const companyOnboardData = useSelector((state: any) => state.onboardCompany);
  const auditReportData = useSelector((state: any) => state.auditReport);

  const { getAllCompanyTypesStatus, allCompanyTypes = [] } = companyOnboardData;
  const { getEventActionListStatus, eventAction = [] } = auditReportData;
  const { actors = [], getActorsListStatus } = useSelector(
    (state: any) => state.auditReport
  );
  const { auditLogCompanies = [], getAuditLogCompaniesListStatus } =
    useSelector((state: any) => state.auditReport);
  const [statusAction, setStatusAction] = useState<string>('');
  const [actionRow, setActionRow] = useState<any>('');
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const { vendorIds = [], getVendorIdListStatus } = useSelector(
    (state: any) => state.auditReport
  );
  const { siteIds = [], getSiteIdListStatus } = useSelector(
    (state: any) => state.auditReport
  );

  const reportFilterOpt = value?.filters?.find(
    (field: any) => field.name === 'reportType'
  )?.value;

  useEffect(() => {
    if (reportFilterOpt) {
      dispatch(
        fetchEventActions({
          distinctFieldName: 'eventAction',
          reportType: reportFilterOpt
        })
      );

      dispatch(
        fetchActors({
          // collectionName: 'scRegAuditTrail',
          distinctFieldName: 'userId',
          reportType: reportFilterOpt
        })
      );

      dispatch(
        fetchAuditLogCompanies({
          // collectionName: 'scRegAuditTrail',
          distinctFieldName: 'companyName',
          reportType: reportFilterOpt
        })
      );

      dispatch(
        fetchVendorId({
          // collectionName: 'scRegAuditTrail',
          distinctFieldName: 'vendorId',
          reportType: reportFilterOpt
        })
      );

      dispatch(
        fetchSiteId({
          // collectionName: 'scRegAuditTrail',
          distinctFieldName: 'siteId',
          reportType: reportFilterOpt
        })
      );
    }
  }, [reportFilterOpt]);

  useEffect(() => {
    if (typeof getAllCompanyTypesStatus === 'undefined') {
      dispatch(fetchAllCompanyTypes());
    }
  }, [getAllCompanyTypesStatus]);

  // useEffect(() => {
  //   if (typeof getActorsListStatus === 'undefined') {
  //     dispatch(fetchActors());
  //   }
  // }, [getActorsListStatus]);

  // useEffect(() => {
  //   if (typeof getAuditLogCompaniesListStatus === 'undefined') {
  //     dispatch(fetchAuditLogCompanies());
  //   }
  // }, [getAuditLogCompaniesListStatus]);

  useMemo(() => {
    const startDate = moment(new Date().toDateString());
    const timeEnd = moment(scheduleDetails?.endDate);
    const diff = timeEnd.diff(startDate);
    const diffDuration = moment.duration(diff);

    setIsExpired(!(diffDuration.asDays() > 0));
  }, [scheduleDetails]);

  const getAuditReport = () => {
    const id = (params as any)?.id;
    setIsLoading(true);
    const payload = {
      id
    };
    fetchAuditReportScheduleDetails(payload)
      .then((result: any) => {
        const data = result && result?.length ? result[0] : {};
        const filterData =
          data?.scRegAuditScheduleJobsDetails &&
          data?.scRegAuditScheduleJobsDetails.length
            ? data?.scRegAuditScheduleJobsDetails[0]
            : {};
        setScheduleDetails(result && result?.length ? result[0] : {});
        let currentDate = moment();
        let nextDate = currentDate.add(1, 'day');
        setValue((res: any) => ({
          ...res,
          reportName: data?.reportName,
          reportDescription: data?.reportDescription || '',
          frequency: data?.frequency,
          dayOnMonth: parseInt(filterData?.dayOnmonth)
            ? parseInt(filterData?.dayOnmonth).toString()
            : '1',
          weekDays:
            filterData?.weekDays?.map((res: string) =>
              capitalizeFirstLetter(res.toLowerCase())
            ) || [],
          startDate: data?.startDate
            ? moment(data.startDate).toDate()
            : moment().startOf('day').toDate(),
          endDate: data?.endDate
            ? moment(data.endDate).toDate()
            : nextDate.toDate(),
          recipients: data?.cecReceipents?.length
            ? data?.cecReceipents?.map((item: any) => {
                return {
                  value: item,
                  label: item,
                  userId: item,
                  email: item?.email,
                  firstName: item?.firstName,
                  lastName: item?.lastName
                };
              })
            : [],
          filters: initialFilterValue?.map((item: any) => {
            if (item.name === 'reportType' && data?.reportType) {
              item.value = data?.reportType;
            } else if (item.name === 'actor' && data?.actor) {
              item.show = true;
              item.value = data?.actor;
            } else if (item.name === 'companyName' && data?.companyName) {
              item.show = true;
              item.value = data?.companyName;
            } else if (item.name === 'companyType' && data?.companyType) {
              item.show = true;
              item.value = data?.companyType;
            } else if (item.name === 'eventAction' && data?.eventAction) {
              item.show = true;
              item.value = data?.eventAction.map((res: any) => ({
                value: res,
                label: res
              }));
            } else if (item.name === 'vendorId' && data?.vendorId) {
              item.show = true;
              item.value = data?.vendorId;
            } else if (item.name === 'siteId' && data?.siteId) {
              item.show = true;
              item.value = data?.siteId;
            } else {
              if (item.name !== 'reportType') item.show = false;
            }
            return item;
          })
        }));
        setIsLoading(false);
      })
      .catch((_) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const id = (params as any)?.id;
    if (id) {
      getAuditReport();
    }
  }, [params]);

  const initialFilterValue = [
    {
      name: 'reportType',
      label: 'Report Type',
      type: 'select',
      show: true,
      value: '',
      disabled: true,
      options: [
        'Company Account Modification Report',
        'Company Account Lifecycle Report',
        'User Account Lifecycle Report'
      ].map((item: string) => {
        return {
          value: item,
          label: item
        };
      })
    },
    {
      name: 'actor',
      label: 'Actor',
      type: 'select',
      show: false,
      value: '',
      options: [],
      disabled: false
    },
    {
      name: 'eventAction',
      label: 'Event Action',
      type: 'multiSelect',
      options: [],
      show: true,
      value: '',
      disabled: false
    },

    {
      name: 'companyName',
      label: 'Company Name',
      type: 'select',
      show: false,
      value: '',
      options: [],
      disabled: false
    },
    {
      name: 'vendorId',
      label: 'Vendor Id',
      type: 'select',
      options: [],
      show: false,
      value: '',
      disabled: false
    },
    {
      name: 'siteId',
      label: 'Site Id',
      type: 'select',
      options: [],
      show: false,
      value: ''
    },
    {
      name: 'companyType',
      label: 'Company Type',
      type: 'select',
      options: [],
      show: false,
      value: '',
      disabled: false
    }
  ];

  useEffect(() => {
    const formData = {
      // reportName: auditScheduleDetail?.reportName,
      // reportDescription: auditScheduleDetail?.reportDescription,
      // startDate: auditScheduleDetail?.startDate
      //   ? new Date(auditScheduleDetail?.startDate)
      //   : null,
      // endDate: auditScheduleDetail?.startDate
      //   ? new Date(auditScheduleDetail?.endDate)
      //   : null,
      frequency: 'oneTime',
      filters: initialFilterValue
    };
    setValue(formData);
  }, []);

  useEffect(() => {
    if (allCompanyTypes?.length) {
      setValue((preVal: any) => {
        let filters = preVal?.filters || [];
        let updatedFilters = filters.map((filterItem: any) => {
          if (filterItem?.name === 'companyType') {
            filterItem.options =
              allCompanyTypes && allCompanyTypes?.length
                ? allCompanyTypes?.map((companyTypeItem: any) => {
                    return {
                      value: companyTypeItem?.companyTypeName,
                      label: companyTypeItem?.companyTypeName
                    };
                  })
                : [];
          }
          return filterItem;
        });
        return {
          ...({
            ...preVal
          } || {}),
          filters: updatedFilters
        };
      });
    }
  }, [allCompanyTypes]);

  useEffect(() => {
    if (eventAction?.length) {
      setValue((preVal: any) => {
        let filters = preVal?.filters || [];
        let updatedFilters = filters.map((filterItem: any) => {
          if (filterItem?.name === 'eventAction') {
            filterItem.options =
              eventAction && eventAction?.length
                ? eventAction?.map((item: string) => {
                    return {
                      value: item,
                      label: item
                    };
                  })
                : [];
          }
          return filterItem;
        });
        return {
          ...({
            ...preVal
          } || {}),
          filters: updatedFilters
        };
      });
    }
  }, [eventAction]);

  useEffect(() => {
    if (actors?.length) {
      setValue((preVal: any) => {
        let filters = preVal?.filters || [];
        let updatedFilters = filters.map((filterItem: any) => {
          if (filterItem?.name === 'actor') {
            filterItem.options =
              actors && actors?.length
                ? actors?.map((item: string) => {
                    return {
                      value: item,
                      label: item
                    };
                  })
                : [];
          }
          return filterItem;
        });
        return {
          ...({
            ...preVal
          } || {}),
          filters: updatedFilters
        };
      });
    }
  }, [actors]);

  useEffect(() => {
    if (auditLogCompanies?.length) {
      setValue((preVal: any) => {
        let filters = preVal?.filters || [];
        let updatedFilters = filters.map((filterItem: any) => {
          if (filterItem?.name === 'companyName') {
            filterItem.options =
              auditLogCompanies && auditLogCompanies?.length
                ? auditLogCompanies?.map((item: string) => {
                    return {
                      value: item,
                      label: item
                    };
                  })
                : [];
          }
          return filterItem;
        });
        return {
          ...({
            ...preVal
          } || {}),
          filters: updatedFilters
        };
      });
    }
  }, [auditLogCompanies]);

  useEffect(() => {
    if (vendorIds?.length) {
      setValue((preVal: any) => {
        let filters = preVal?.filters || [];
        let updatedFilters = filters.map((filterItem: any) => {
          if (filterItem?.name === 'vendorId') {
            filterItem.options =
              vendorIds && vendorIds?.length
                ? vendorIds?.map((item: string) => {
                    return {
                      value: item,
                      label: item
                    };
                  })
                : [];
          }
          return filterItem;
        });
        return {
          ...({
            ...preVal
          } || {}),
          filters: updatedFilters
        };
      });
    }
  }, [vendorIds]);

  useEffect(() => {
    if (siteIds?.length) {
      setValue((preVal: any) => {
        let filters = preVal?.filters || [];
        let updatedFilters = filters.map((filterItem: any) => {
          if (filterItem?.name === 'siteId') {
            filterItem.options =
              siteIds && siteIds?.length
                ? siteIds?.map((item: string) => {
                    return {
                      value: item,
                      label: item
                    };
                  })
                : [];
          }
          return filterItem;
        });
        return {
          ...({
            ...preVal
          } || {}),
          filters: updatedFilters
        };
      });
    }
  }, [siteIds]);

  const onInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    if (event.target.type === 'checkbox') {
      setValue((preVal: any) => {
        return {
          ...(preVal || {}),
          ...{ [event.target.name]: (event.target as any).checked }
        };
      });
    } else {
      let dependedPayload: { dayOnMonth?: string } = {};
      if (
        event.target.name === 'frequency' &&
        event.target.value === 'monthly'
      ) {
        dependedPayload.dayOnMonth = '1';
      }

      let value = event.target.value;

      if (event.target.name == 'reportName') {
        value = sanitizeInput(event.target.value);
      }

      setValue((preVal: any) => {
        return {
          ...(preVal || {}),
          ...{ [event.target.name]: value },
          ...dependedPayload
        };
      });
    }
  };

  const onScheduleTypeChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    setValue((preVal: any) => {
      return {
        ...(preVal || {}),
        ...{
          ['frequency']: event.target.value === 'oneTime' ? 'oneTime' : 'daily',
          startDate: null,
          endDate: null
        }
      };
    });
  };

  const onChangeStartDate = (val: any) => {
    if (value?.frequency === 'oneTime') {
      setValue((preVal: any) => {
        return {
          ...(preVal || {}),
          ...{ ['startDate']: val, ['endDate']: val }
        };
      });
    } else {
      setValue((preVal: any) => {
        return {
          ...(preVal || {}),
          ...{ ['startDate']: val }
        };
      });
    }
  };

  const onChangeEndDate = (val: any) => {
    setValue((preVal: any) => {
      return {
        ...(preVal || {}),
        ...{ ['endDate']: val }
      };
    });
  };

  useEffect(() => {
    checkValidation();
  }, [value]);

  const checkValidation = () => {
    let filterErrors: any = [];
    let countFilters = 0;
    value?.filters?.forEach((item: any) => {
      let error: any = {};
      if (item?.show) {
        countFilters++;
        let fieldValue = '';
        if (item?.type === 'select') {
          fieldValue = item?.value;
        } else if (item?.type === 'multiSelect') {
          fieldValue = item?.value;
        } else {
          fieldValue = item?.value?.trim();
        }

        if (Array.isArray(fieldValue) && fieldValue.length == 0) {
          error.name = item?.name;
          error.message = `${item?.label} is required`;
        } else if (fieldValue === '' || typeof fieldValue === 'undefined') {
          error.name = item?.name;
          error.message = `${item?.label} is required`;
        }
      }
      if (Object.keys(error).length) {
        filterErrors.push(error);
      }
    });

    let formError = {} as any;

    if (countFilters == 1) {
      formError['errorfilters'] = 'Atleast One Filters is required';
    }

    if (!value?.reportName) {
      formError['reportName'] = 'Report Name is required';
    }
    if (!value?.startDate) {
      formError['startDate'] = 'Start Date is required';
    }

    if (!value?.endDate) {
      formError['endDate'] = 'End Date is required';
    }

    if (!value?.recipients || (value?.recipients as any)?.length == 0) {
      formError['recipients'] = 'Recipients are required';
    }

    if (value?.frequency === 'weekly') {
      if (!value?.weekDays || (value?.weekDays as any)?.length == 0) {
        formError['weekDays'] = 'On is required';
      }
    }

    if (value?.frequency == 'weekly' && value?.weekDays?.length) {
      let indexes = value?.weekDays.map((day: string) => allWeeks.indexOf(day));
      const allWeekDates = generateDatesForWeek(
        value?.startDate,
        value?.endDate,
        indexes
      );

      if (allWeekDates.length == 0) {
        formError['noDatesFound'] =
          'As per selected date range frequency, no dates are found';
      }
    }

    if (value?.frequency == 'monthly' && value?.startDate && value?.endDate) {
      const allMonthDates = generateDatesForMonth(
        value?.startDate,
        value?.endDate,
        [Number(value?.dayOnMonth)]
      );

      if (allMonthDates.length == 0) {
        formError['noDatesFound'] =
          'As per selected date range frequency, no dates are found';
      }
    }

    setValidation((preVal: any) => {
      return {
        ...{ filters: filterErrors },
        ...{ ...formError }
      };
    });

    return filterErrors.length === 0 && Object.keys(formError).length == 0;
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();
    setIsSubmitted(true);

    if (!checkValidation()) {
      return;
    }

    const filterResult = {
      ...value?.filters?.reduce((acc: any, item: any) => {
        if (
          item.show &&
          item.value !== '' &&
          item.value !== null &&
          typeof item?.value !== 'undefined'
        ) {
          if (item.name == 'eventAction') {
            acc[item.name] = item?.value?.map((res: any) => res.value);
          } else {
            acc[item.name] = item.value;
          }
        }
        return acc;
      }, {})
    };

    try {
      const id = (params as any)?.id;
      const payload: any = {
        id,
        reportType: reportFilterOpt,
        reportName: value?.reportName,
        reportDescription: value?.reportDescription,
        startDate: moment(new Date(value?.startDate)).format('YYYY-MM-DD'),
        endDate: moment(new Date(value?.endDate)).format('YYYY-MM-DD'),
        cecReceipents: value?.recipients?.map((item: SelectOption) => {
          return item?.userId;
          // return {
          //   firstName: item?.firstName,
          //   lastName: item?.lastName,
          //   userId: item?.userId,
          //   email: item?.email
          // };
        }),
        frequency: value?.frequency,
        ...filterResult
      };

      if (value?.frequency == 'weekly') {
        payload['weekDays'] = value?.weekDays?.map((item: string) =>
          item?.toUpperCase()
        );
      }

      if (value?.frequency == 'monthly') {
        payload['dayOnmonth'] = value?.dayOnMonth?.padStart(2, 0);
      }

      payload['message'] = getMessage()?.message;

      setIsLoading(true);
      setStatus('PENDING');
      const result = await reportScheduleUpdate(payload);
      await getAuditReport();
      setStatus('SUCCESS');
      setIsLoading(false);
      //  if (onSave) onSave(payload);
    } catch (err) {
      setIsLoading(false);
      setStatus(undefined);
    }
  };

  const disablePastDates = (date: any): boolean => {
    return isBefore(date, subDays(new Date(), 1));
  };

  const loadCecUsers = (inputValue: any, callback: Function) => {
    if (inputValue?.length > 2) {
      fetchInternalUsers(inputValue).then((result: any) => {
        callback(
          result?.map((item: any) => {
            return { ...item, label: item.userId, value: item.userId };
          }) || []
        );
      });
    } else {
      callback([]);
    }
  };

  const onRecipientsChange = (selectedUsers: any) => {
    setValue((preVal: any) => {
      return {
        ...(preVal || {}),
        ...{ ['recipients']: selectedUsers || [] }
      };
    });
  };

  const loadUsersOptions = debounce(loadCecUsers, 600);

  const getMessage = () => {
    if (value?.startDate && value?.endDate) {
      if (value?.frequency === 'daily') {
        if (value?.startDate === value?.endDate) {
          return {
            message: `Occurs on ${format(value?.endDate, 'dd-MMM-yyyy')} day`,
            htmlMessage: (
              <span>
                Occurs on{' '}
                <strong>{format(value?.endDate, 'dd-MMM-yyyy')}</strong> day
              </span>
            )
          };
        }
        return {
          message: `Occurs every day until ${format(
            value?.endDate,
            'dd-MMM-yyyy'
          )}`,
          htmlMessage: (
            <span>
              Occurs every day until{' '}
              <strong>{format(value?.endDate, 'dd-MMM-yyyy')}</strong>
            </span>
          )
        };
      } else if (value?.frequency === 'monthly') {
        return {
          message: `Occurs every month on day ${
            value?.dayOnMonth
          } until ${format(value?.endDate, 'dd-MMM-yyyy')}`,
          htmlMessage: (
            <span>
              Occurs every month on day <strong>{value?.dayOnMonth}</strong>{' '}
              until <strong>{format(value?.endDate, 'dd-MMM-yyyy')}</strong>
            </span>
          )
        };
      } else if (value?.frequency === 'weekly')
        return {
          message: `Occurs every week on ${value?.weekDays?.join(', ')}
          until ${format(value?.endDate, 'dd-MMM-yyyy')}`,
          htmlMessage: (
            <span>
              Occurs every week on{' '}
              <strong>{value?.weekDays?.join(', ')}</strong> until{' '}
              <strong>{format(value?.endDate, 'dd-MMM-yyyy')}</strong>
            </span>
          )
        };
    }
    return null;
  };

  // const disableDate = () => {
  //   // for DateRangePicker
  //   const currentDate = new Date();
  //   const oneYearFromNow = addDays(currentDate, 90);

  //   return allowedRange!(currentDate, oneYearFromNow);
  // };

  const disableEndDate = (date: Date | null): boolean => {
    const oneYearAfterStartDate = addYears(value?.startDate, 1);
    return (
      value?.startDate == null ||
      (date != null &&
        (date <= value.startDate || date > oneYearAfterStartDate))
    );
  };

  const handleDayToggle = (day: string) => {
    setValue((preVal: any) => {
      let prevWeekDays = preVal?.weekDays || [];
      let currentWeekDays = preVal?.weekDays || [];
      if (prevWeekDays.includes(day)) {
        currentWeekDays = prevWeekDays.filter((d: string) => d !== day);
      } else {
        currentWeekDays = [...prevWeekDays, day];
      }

      return {
        ...(preVal || {}),
        ...{ ['weekDays']: currentWeekDays }
      };
    });
  };

  const onChangeFilter = (filtersValue: any) => {
    setValue((preVal: any) => {
      const previousReportTypeValue = preVal.filters.find(
        (field: any) => field.name === 'reportType'
      )?.value;
      const reportTypeValue = filtersValue.find(
        (field: any) => field.name === 'reportType'
      ).value;
      const isReportTypeEmpty =
        reportTypeValue == null ||
        reportTypeValue == undefined ||
        reportTypeValue?.trim() === '';

      if (isReportTypeEmpty) {
        return {
          ...({
            ...preVal
          } || {}),
          filters: filtersValue?.map((item: any) => {
            if (item.name !== 'reportType') {
              item.disabled = true;
              item.show = false;
            }
            return item;
          })
        };
      } else {
        return {
          ...({
            ...preVal
          } || {}),
          filters: filtersValue?.map((item: any) => {
            if (item.name !== 'reportType')
              if (previousReportTypeValue != reportTypeValue) {
                // item.show = false;
                item.value = '';
                item.disabled = false;
              }
            return item;
          })
        };
      }
    });
  };

  const disableOneTimeDates = (date: Date) => {
    const today = subDays(new Date(), 1); // able to select today
    const oneYearAhead = addYears(today, 1);

    // Disable dates that are before today or more than 1 year in the future
    return isBefore(date, today) || isBefore(oneYearAhead, date);
  };

  const updateScheduledDateClick = async (rowData: any) => {
    try {
      setIsLoading(true);
      const payload = {
        id: rowData?._id,
        cancelledSchedules: [
          moment(new Date(rowData?.scheduledStartDate)).format('YYYY-MM-DD')
        ]
      };
      const result = await ScheduledDateUpdate(payload);
      setConfirmDialogOpen(false);
      await getAuditReport();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const onclickConfirmDialogOpen = (action: string, rowData: any) => {
    setActionRow(rowData);
    setStatusAction(action);
    setConfirmDialogOpen(true);
  };

  const confirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  return (
    <div className="card base-margin-top" style={{ marginBottom: '60px' }}>
      <div className="card-header">
        <div className="flex">
          <h5 className="text-primary" style={{ width: '100%' }}>
            Update Scheduled Report
          </h5>
          <Link
            to="/audit-report-schedule"
            state={{ fromSource: 'companyDetail' }}
            style={{ width: '90px' }}
            className="pull-right btn btn--ghost btn--small"
          >
            <span className="icon-arrow-left-tail"></span> Back
          </Link>
        </div>
      </div>
      <div className="card-body">
        <form onSubmit={onSubmit}>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <div className="row">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-6 half-margin-bottom half-margin-top">
                      <div className="form-group">
                        <div className="form-group__text">
                          <input
                            id="input-state-default"
                            name="reportName"
                            value={value?.reportName || ''}
                            type="text"
                            onChange={onInputChange}
                          />
                          <label
                            htmlFor="input-state-default"
                            className="text-weight-700 required "
                          >
                            Report Schedule Name
                          </label>
                        </div>
                        {isSubmitted && validation && validation?.reportName ? (
                          <div
                            className="form-group__help text-danger"
                            role="alert"
                          >
                            <span>{validation?.reportName}</span>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-6 half-margin-bottom half-margin-top">
                      <div className="form-group">
                        <div className="form-group__text">
                          <textarea
                            id="textarea-state-default"
                            name="reportDescription"
                            rows={1}
                            onChange={onInputChange}
                            value={value?.reportDescription || ''}
                          ></textarea>
                          <label
                            htmlFor="textarea-state-default"
                            className="text-weight-700"
                          >
                            Description
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 half-margin-bottom half-margin-top">
                      <div className="form-group form-group--inline form-group--compressed">
                        <label className="radio">
                          <input
                            type="radio"
                            checked={value?.frequency === 'oneTime'}
                            name="scheduleType"
                            value="oneTime"
                            onChange={onScheduleTypeChange}
                          />
                          <span className="radio__input"></span>
                          <span className="radio__label">One Time</span>
                        </label>
                      </div>

                      <div className="form-group form-group--inline form-group--compressed">
                        <label className="radio">
                          <input
                            type="radio"
                            name="scheduleType"
                            value="recurrence"
                            checked={value?.frequency !== 'oneTime'}
                            onChange={onScheduleTypeChange}
                          />
                          <span className="radio__input"></span>
                          <span className="radio__label">Recurrence</span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    {value?.frequency === 'oneTime' ? (
                      <div className="col-md-6 half-margin-bottom half-margin-top">
                        <div className="form-group">
                          <div
                            className="form-group__text form-datepicker"
                            id="startDateContainer"
                          >
                            <label
                              htmlFor="starDate"
                              className=" text-weight-700 required half-margin-right"
                            >
                              <strong> Date </strong>
                            </label>
                            <DatePicker
                              container={() =>
                                document.getElementById('startDateContainer')!
                              }
                              format="MM-dd-yyyy"
                              className="datepicker"
                              menuClassName="datepicker-menu"
                              onChange={onChangeStartDate}
                              value={value?.startDate || null}
                              oneTap
                              shouldDisableDate={disableOneTimeDates}
                              editable={false}
                            />
                            {isSubmitted &&
                            validation &&
                            validation?.startDate ? (
                              <div
                                className="form-group__help text-danger text-danger"
                                role="alert"
                              >
                                <span>{validation?.startDate}</span>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {value?.frequency !== 'oneTime' ? (
                      <>
                        <div className="col-md-6 half-margin-bottom half-margin-top">
                          <div className="form-group">
                            <div
                              className="form-group__text form-datepicker"
                              id="startDateContainer"
                            >
                              <label
                                htmlFor="starDate"
                                className=" text-weight-700 required half-margin-right"
                              >
                                <strong>Start Date </strong>
                              </label>
                              <DatePicker
                                editable={false}
                                container={() =>
                                  document.getElementById('startDateContainer')!
                                }
                                format="MM-dd-yyyy"
                                className="datepicker"
                                menuClassName="datepicker-menu"
                                onChange={onChangeStartDate}
                                value={value?.startDate || null}
                                oneTap
                                shouldDisableDate={disablePastDates}
                              />
                              {isSubmitted &&
                              validation &&
                              validation?.startDate ? (
                                <div
                                  className="form-group__help text-danger text-danger"
                                  role="alert"
                                >
                                  <span>{validation?.startDate}</span>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 half-margin-bottom half-margin-top">
                          <div className="form-group">
                            <div
                              className="form-group__text form-datepicker"
                              id="endDateContainer"
                            >
                              <label
                                htmlFor="select-companyType"
                                className="text-weight-700 required half-margin-right"
                              >
                                <strong>End Date </strong>
                              </label>
                              <DatePicker
                                editable={false}
                                container={() =>
                                  document.getElementById('endDateContainer')!
                                }
                                format="MM-dd-yyyy"
                                className="datepicker"
                                disabled={!value?.startDate}
                                menuClassName="datepicker-menu"
                                onChange={onChangeEndDate}
                                oneTap
                                value={value?.endDate || null}
                                shouldDisableDate={disableEndDate}
                              />
                            </div>
                            {isSubmitted &&
                            validation &&
                            validation?.endDate ? (
                              <div
                                className="form-group__help text-danger"
                                role="alert"
                              >
                                <span>{validation?.endDate}</span>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-12 half-margin-bottom half-margin-top">
                              <div className="form-group">
                                <div className="form-group__text select">
                                  <label
                                    htmlFor="repeat"
                                    className="text-weight-700 half-margin-right"
                                  >
                                    <strong>Frequency </strong>
                                  </label>
                                  <select
                                    id="repeat"
                                    value={value?.frequency}
                                    name="frequency"
                                    onChange={onInputChange}
                                  >
                                    <option value="daily">Daily</option>
                                    <option value="weekly">Weekly</option>
                                    <option value="monthly">Monthly</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            {value?.frequency === 'weekly' && (
                              <>
                                <div className="col-md-12 half-margin-bottom half-margin-top">
                                  <div className="form-group form-group--inline">
                                    <div className="form-group__text">
                                      <label
                                        htmlFor="weeks"
                                        className="text-weight-700 required half-margin-right"
                                      >
                                        <strong>On </strong>
                                      </label>
                                      {allWeeks.map((day) => (
                                        <div key={day}>
                                          <label
                                            className="checkbox half-margin-left"
                                            htmlFor={day}
                                            key={day}
                                          >
                                            <input
                                              type="checkbox"
                                              id={day}
                                              checked={value?.weekDays?.includes(
                                                day
                                              )}
                                              onChange={() =>
                                                handleDayToggle(day)
                                              }
                                              name="weekDays"
                                            />
                                            <span className="checkbox__input"></span>
                                            <span className="checkbox__label">
                                              {day.slice(0, 3)}
                                            </span>
                                          </label>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    {isSubmitted &&
                                    validation?.weekDays &&
                                    validation ? (
                                      <div
                                        className="form-group__help text-danger"
                                        role="alert"
                                      >
                                        <span>{validation?.weekDays}</span>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </>
                            )}

                            {value?.frequency === 'monthly' && (
                              <>
                                <div className="col-md-12 half-margin-bottom half-margin-top">
                                  <div className="form-group form-group--inline">
                                    <div className="form-group__text">
                                      <label
                                        htmlFor="selectMonth"
                                        className="text-weight-700 required half-margin-right"
                                      >
                                        <strong>On </strong>
                                      </label>
                                      <select
                                        id="selectMonth"
                                        value={value?.dayOnMonth}
                                        name="dayOnMonth"
                                        onChange={onInputChange}
                                      >
                                        {[...Array(31)].map((_, index) => (
                                          <option
                                            key={index + 1}
                                            value={index + 1}
                                          >
                                            {index + 1}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                            <div className="col-md-12 half-margin-bottom half-margin-top">
                              <div className="form-group form-group--inline">
                                <div className="form-group__text">
                                  {getMessage()?.htmlMessage}
                                </div>
                              </div>
                            </div>
                            {isSubmitted &&
                              validation &&
                              validation?.noDatesFound && (
                                <div className="col-md-12 half-margin-bottom half-margin-top">
                                  <div className="form-group form-group--inline">
                                    <div className="form-group__text">
                                      <div className="alert alert--warning">
                                        <div className="alert__message">
                                          {validation?.noDatesFound}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </>
                    ) : null}

                    <div className="col-md-6 half-margin-bottom half-margin-top">
                      <div className="form-group">
                        <div className="form-group__text">
                          <label
                            htmlFor="input-type-company-search-name"
                            className="text-weight-700"
                          >
                            Recipients
                            <span className="required"></span>{' '}
                            <span
                              className="text-weight-400"
                              style={{
                                position: 'relative'
                              }}
                            >
                              <span
                                className="icon-info-outline text-info"
                                data-tooltip-id={`tooltip-users`}
                                style={{ cursor: 'pointer' }}
                              >
                                <Tooltip id={`tooltip-users`}>
                                  <span style={{ zIndex: '111' }}>
                                    Search by first name,last name,email and
                                    user ID.{' '}
                                  </span>
                                </Tooltip>
                              </span>
                            </span>
                          </label>
                          <div>
                            <UserSelect
                              value={value?.recipients || []}
                              controlShouldRenderValue={false}
                              name="recipients"
                              isMulti
                              cacheOptions
                              placeholder={'Search CEC Users....'}
                              loadOptions={loadUsersOptions}
                              onChange={onRecipientsChange}
                              userSelectRendered={(userItem: SelectOption) => {
                                return <>{userItem?.value}</>;
                              }}
                            />
                          </div>
                        </div>
                        {isSubmitted && validation && validation?.recipients ? (
                          <div
                            className="form-group__help text-danger"
                            role="alert"
                          >
                            <span>{validation?.recipients}</span>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 half-margin-bottom half-margin-top">
                      <div className="card">
                        <div className="card-header">
                          <h5 className="text-primary">Filter</h5>
                        </div>
                        <div className="card-body">
                          {[
                            getEventActionListStatus,
                            getActorsListStatus,
                            getAuditLogCompaniesListStatus,
                            getVendorIdListStatus,
                            getSiteIdListStatus
                          ].includes('PENDING') ? (
                            <div className="center-holder-wrap">
                              <Loader />
                            </div>
                          ) : (
                            <div className="row">
                              <Filters
                                filters={value?.filters || []}
                                onChange={onChangeFilter}
                                validation={
                                  isSubmitted && validation?.filters
                                    ? validation?.filters
                                    : []
                                }
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {isSubmitted && validation && validation?.errorfilters ? (
                      <div
                        className="col-md-12 form-group__help text-danger"
                        role="alert"
                      >
                        <span>{validation?.errorfilters}</span>
                      </div>
                    ) : null}
                    {isSubmitted &&
                    validation?.filters &&
                    validation?.filters?.filter(
                      (filterItem: any) => filterItem?.name === 'filterCount'
                    )?.length ? (
                      <div
                        className="alert alert--warning"
                        style={{ marginBottom: '8px' }}
                      >
                        <div className="alert__icon icon-warning-outline"></div>
                        <div className="alert__message">
                          {
                            validation?.filters?.find(
                              (filterItem: any) =>
                                filterItem?.name === 'filterCount'
                            )?.message
                          }
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="flex flex-right base-margin-top">
                    {/* <button
                        type="button"
                        //     onClick={() => onChange(false)}
                        className="btn btn--ghost"
                      >
                        Cancel
                      </button> */}

                    <button
                      type="submit"
                      className="btn btn--ghost"
                      disabled={isLoading}
                    >
                      Save
                    </button>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-sm-12 col-md-5">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="text-primary">Scheduled Dates </h5>

                      <div className="text-weight-700">
                        {' '}
                        {scheduleDetails?.message}{' '}
                      </div>
                    </div>
                    <div className="card-body scroll-y">
                      <div className="responsive-table">
                        <table
                          className="table table--bordered table--compressed text-center"
                          aria-label="Compressed table"
                        >
                          <thead>
                            <tr>
                              <th>Schedule Date</th>
                              <th>Day</th>
                              <th>Schedule Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {scheduleDetails?.scRegAuditScheduleJobsDetails &&
                              scheduleDetails?.scRegAuditScheduleJobsDetails?.map(
                                (item: any) => {
                                  return (
                                    <tr
                                      key={`item$${item?.scheduledStartDate}`}
                                    >
                                      <td>
                                        {moment(
                                          item?.scheduledStartDate
                                        ).format('MM-DD-YYYY')}{' '}
                                      </td>
                                      <td>
                                        {' '}
                                        {moment(
                                          item?.scheduledStartDate
                                        ).format('ddd')}{' '}
                                      </td>
                                      <td>
                                        {item?.action?.toLowerCase() ===
                                        'cancelled'
                                          ? item?.action
                                          : item?.status}
                                      </td>

                                      <td>
                                        {!isExpired &&
                                        item?.status?.toLowerCase() !==
                                          'completed' &&
                                        item?.action?.toLowerCase() !==
                                          'cancelled' ? (
                                          <button
                                            type="button"
                                            className="btn btn--small btn--dark half-margin-left"
                                            onClick={() => {
                                              onclickConfirmDialogOpen(
                                                'cancel',
                                                item
                                              );
                                            }}
                                          >
                                            Cancel
                                          </button>
                                        ) : null}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </form>
      </div>
      <Dialog
        open={confirmDialogOpen}
        size="small"
        onDialogClose={confirmDialogClose}
        closeOnClickOutside={false}
        title={`Confirm`}
        className="report-schedule-confirm-dialog"
        body={
          <div>
            <h4>
              Are you sure to <span>{statusAction?.toLowerCase()}</span> the
              schedule on{' '}
              <b>
                {moment(actionRow?.scheduledStartDate).format('MM-DD-YYYY')}
              </b>
            </h4>
            {isLoading && (
              <div className="center-holder-wrap">
                <Loader />
              </div>
            )}
          </div>
        }
        footer={
          <div>
            <button
              type="button"
              onClick={confirmDialogClose}
              className="btn btn--ghost"
              disabled={isLoading}
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={() => updateScheduledDateClick(actionRow)}
              className="btn btn--primary"
              disabled={isLoading}
            >
              Confirm
            </button>
          </div>
        }
      />
    </div>
  );
};

export default AuditReportScheduleModify;
