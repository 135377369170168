import httpUtil from '../helpers/interceptor';
import notify from '../helpers/notification';

export const fetchEventActions = async (): Promise<string[]> => {
  try {
    const res = await httpUtil.get(
      `/api/am/scheduleJobs/v1/get/audit/eventActions`
    );
    return res?.data || [];
  } catch (error: any) {
    const errorMessage = `An error occurred while fetching Event Actions ${
      error?.response?.data?.error ? ': ' + error?.response?.data?.error : ''
    }`;
    notify(errorMessage, 'error');
    return Promise.reject(error);
  }
};

export const AuditReportScheduleCreate = async (body: object = {}) => {
  try {
    const res: any = await httpUtil.post(
      `/api/am/scheduleJobs/v1/audit/scheduleJobs`,
      body
    );

    if (res?.success?.toLowerCase() === 'success') {
      notify(`Audit Report Schedule has been created successfully.`, 'success');
    } else {
      notify(`An error occurred while scheduling Audit Report.`, 'error');
      throw new Error('An error occurred while scheduling Audit Report.');
    }
    return res;
  } catch (error: any) {
    let errorMessage = `An error occurred while scheduling Audit Report. ${
      error?.response?.data?.message ? '' + error?.response?.data?.message : ''
    }`;
    notify(errorMessage, 'error');
    return Promise.reject(error);
  }
};

export const fetchAuditReportScheduleDetails = async (body: object = {}) => {
  try {
    const res = await httpUtil.post(
      `/api/am/scheduleJobs/v1/view/audit/scheduleJobs`,
      body
    );
    return res?.data || [];
  } catch (error: any) {
    const errorMessage = `An error occurred while fetching Audit Report Schedule Details ${
      error?.response?.data?.error ? ': ' + error?.response?.data?.error : ''
    }`;
    notify(errorMessage, 'error');
    return Promise.reject(error);
  }
};

export const reportScheduleUpdate = async (body: object = {}) => {
  try {
    const res = await httpUtil.post(
      `/api/am/scheduleJobs/v1/update/audit/scheduleJobs/schedule`,
      body
    );
    notify(`Report Schedule has been  updated successfully.`, 'success');
    return res || [];
  } catch (error: any) {
    const errorMessage = `An error occurred while updating Report Schedule ${
      error?.response?.data?.message
        ? ': ' + error?.response?.data?.message
        : ''
    }`;
    notify(errorMessage, 'error');
    return Promise.reject(error);
  }
};

export const ScheduledDateUpdate = async (body: object = {}) => {
  try {
    const res = await httpUtil.post(
      `/api/am/scheduleJobs/v1/update/audit/scheduleJobs/dates`,
      body
    );
    notify(`Scheduled date has been cancelled successfully.`, 'success');
    return res || [];
  } catch (error: any) {
    const errorMessage = `An error occurred while cancelling scheduled date ${
      error?.response?.data?.error ? ': ' + error?.response?.data?.error : ''
    }`;
    notify(errorMessage, 'error');
    return Promise.reject(error);
  }
};

const generateDatesFromWeek = (
  startDate: Date,
  endDate: Date,
  days: number[]
) => {
  const dates = [];
  let currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    const dayOfWeek = currentDate.getDay();
    if (days.includes(dayOfWeek)) {
      dates.push(new Date(currentDate));
    }
    // Move to the next day
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
};

export const ScheduledReportDelete = async (body: object = {}) => {
  try {
    const res = await httpUtil.post(
      `/api/am/scheduleJobs/v1/audit/scheduleJobs/delete`,
      body
    );
    notify(`Report Schedule has been deleted successfully.`, 'success');
    return res || [];
  } catch (error: any) {
    const errorMessage = `An error occurred while deleting scheduled report ${
      error?.response?.data?.error ? ': ' + error?.response?.data?.error : ''
    }`;
    notify(errorMessage, 'error');
    return Promise.reject(error);
  }
};
