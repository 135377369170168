import { toast } from 'react-toastify';

const notify = (message, type, option) => {
  const selectedOption = {
    ...{ position: toast.POSITION.TOP_CENTER },
    ...option
  };
  switch (type) {
    case 'success':
      return toast.success(message, {
        ...{
          icon: (
            <span className="icon-check-outline text-success text-size-24"></span>
          )
        },
        ...selectedOption
      });
    case 'error':
      return toast.error(message, {
        ...{
          icon: (
            <span className="icon-error-outline text-danger text-size-24"></span>
          )
        },
        ...selectedOption
      });
    case 'warning':
      return toast.warning(message, {
        ...{
          icon: (
            <span className="icon-exclamation-triangle text-warning text-size-24"></span>
          )
        },
        ...selectedOption
      });
    default:
      return toast.info(message, {
        ...{
          icon: (
            <span className="icon-info-outline text-info text-size-24"></span>
          )
        },
        ...selectedOption
      });
  }
};
export default notify;
