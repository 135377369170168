import React, { useState } from 'react';

import HTMLDisplay from './../../../helpers/htmlDisplay';

const BannerMessage = () => {
  const [show, setShow] = useState(true);
  const [message, setMessage] = useState('');

  const closeAlert = () => {
    setShow(false);
  };

  return (
    <>
      {show && message ? (
        <div className="container">
          <div className="alert alert--danger">
            <div className="alert__message" style={{ width: '100%' }}>
              {message ? <div>{HTMLDisplay(message)}</div> : null}
            </div>

            <span className="alert-close" onClick={closeAlert}>
              <span>&times;</span>
            </span>
          </div>
        </div>
      ) : null}
    </>
  );
};
export default BannerMessage;
