import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { components, OptionProps } from 'react-select';

import InlineEdit from '../../../molecules/InlineEdit';
import { companyOperationalAdminUpdate } from '../../../../services';
import { fetchOperationalAdmin } from '../../../../actions';
import { SelectOption } from '../../../molecules/Select';
import OrgSelect from '../../../molecules/OrgSelect/OrgSelect';
import UsersListValue from '../../../atoms/UsersListValue';
import { arraysContainSameElements } from '../../../../helpers/common';

const UserSelectOption = (props: OptionProps<SelectOption>) => {
  const { data } = props;
  return (
    <div>
      <components.Option {...props}>
        {data?.firstName + ' ' + data?.lastName} ({data?.userId}){' '}
      </components.Option>
    </div>
  );
};

export interface OperationalAdminEditProps {
  isEditable?: boolean;
  title?: React.ReactNode;
  open?: boolean;
  onOpenChange?: Function;
  companyType?: string;
  value?: any;
  onSave?: Function;
  onChange?: Function;
  isLoading?: boolean;
  companyId?: string;
  companyNodeId?: string;
  pamList: string[];
  sponsorList: string[];
}

const OperationalAdminEdit = ({
  isEditable = true,
  title,
  open,
  onOpenChange,
  companyType,
  value,
  onSave,
  onChange,
  isLoading,
  companyId,
  companyNodeId,
  pamList,
  sponsorList
}: OperationalAdminEditProps) => {
  const dispatch = useDispatch();
  const authData = useSelector((state: any) => state?.auth);
  const { actualUserType } = authData;
  const operationalAdminList = useSelector(
    (state: any) => state.onboardCompany?.operationalAdmins
  );
  const getOperationalAdminStatus = useSelector(
    (state: any) => state.onboardCompany?.getOperationalAdminStatus
  );

  const [edit, setEdit] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [status, setStatus] = useState<string | undefined>();
  const [editValue, setEditValue] = useState<any>();
  const [error, setError] = useState<any | undefined>();

  useEffect(() => {
    setEdit(!!open);
  }, [open]);

  useEffect(() => {
    if (companyType && edit) {
      dispatch(fetchOperationalAdmin(companyType));
    }
  }, [companyType, edit]);

  useEffect(() => {
    setEditValue(value);
  }, [value]);

  useEffect(() => {
    validate();
  }, [editValue]);

  const onEditChange = () => {
    setEdit(true);
    if (onOpenChange) {
      onOpenChange(true);
    }
  };

  const onEditCancel = () => {
    setEditValue(value);
    setEdit(false);
    if (onOpenChange) {
      onOpenChange(false);
    }
  };

  const userExistsInAllRole = (val: string) => {
    return pamList?.includes(val) && sponsorList?.includes(val);
  };

  const validate = (): Boolean => {
    if (
      !editValue ||
      !editValue?.primaryOperationalAdmin ||
      editValue?.primaryOperationalAdmin?.length === 0
    ) {
      setError({ message: 'Primary Operational Admin is required' });
      return false;
    } else {
      let userHas3roles: SelectOption[] = [];
      editValue?.primaryOperationalAdmin?.forEach((item: SelectOption) => {
        if (userExistsInAllRole(item?.value)) {
          userHas3roles.push(item);
        }
      });

      editValue?.secondaryOperationalAdmin?.forEach((item: SelectOption) => {
        if (userExistsInAllRole(item?.value)) {
          userHas3roles.push(item);
        }
      });

      if (userHas3roles.length) {
        setError({
          message: 'Not allowed to add more than 2 roles to the same user',
          users: userHas3roles
        });
        return false;
      } else {
        setError(undefined);
      }
    }
    return true;
  };

  const onClickSave = async () => {
    const editedValue = editValue;

    setIsSubmitted(true);

    if (!validate()) {
      return;
    }

    setStatus('PENDING');

    try {
      const result = await companyOperationalAdminUpdate(
        actualUserType
          ? actualUserType?.map((item: string) => item?.toUpperCase())
          : [],
        {
          ...editedValue,
          ...{
            companyId: companyId,
            companyNodeId: companyNodeId
          }
        }
      );

      setEdit(false);
      if (onOpenChange) {
        onOpenChange(false);
      }
      setStatus('SUCCESS');
      if (onSave) onSave(editedValue);
    } catch (err) {
      setStatus('ERROR');
    }
  };

  const primaryOperationalAdminChange = (
    newValue: SelectOption | undefined
  ) => {
    const selectedAdmin = newValue ? [newValue] : [];
    let selectedSecondaryAdmin = editValue?.secondaryOperationalAdmin || [];
    selectedSecondaryAdmin = operationalAdminList?.filter(
      (item: SelectOption) =>
        !selectedAdmin.find(
          (selectedItem: SelectOption) => selectedItem.value === item.value
        )
    );
    setEditValue({
      ...editValue,
      ...{
        primaryOperationalAdmin: selectedAdmin,
        secondaryOperationalAdmin: selectedSecondaryAdmin
      }
    });

    if (onChange) onChange(editValue);
  };

  return (
    <div>
      {isSubmitted && edit && error?.message ? (
        <div className="alert alert--warning">
          <div className="alert__icon icon-warning-outline"></div>
          <div className="alert__message">
            {error?.message}

            {error?.users
              ? ' - ' +
                error?.users?.map((item: SelectOption) => item.value).join(' ,')
              : null}
          </div>
        </div>
      ) : null}

      <InlineEdit
        isDisableSave={arraysContainSameElements(
          value?.primaryOperationalAdmin?.map(({ email }: any) => email),
          editValue?.primaryOperationalAdmin?.map(({ email }: any) => email)
        )}
        isEditable={isEditable}
        title={title}
        readContent={
          <div className="edit-form-value-holder">
            <UsersListValue users={editValue?.primaryOperationalAdmin || []} />
          </div>
        }
        isEditing={edit}
        onChangeEdit={onEditChange}
        onCancelEdit={onEditCancel}
        isLoading={
          status === 'PENDING' ||
          isLoading ||
          getOperationalAdminStatus === 'PENDING'
        }
        editContent={
          <div className="edit-form-value-holder">
            <OrgSelect
              value={
                editValue?.primaryOperationalAdmin?.length
                  ? editValue?.primaryOperationalAdmin[0]
                  : undefined
              }
              name="primaryOperationalAdmin"
              options={operationalAdminList?.length ? operationalAdminList : []}
              components={{
                Option: UserSelectOption
              }}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={primaryOperationalAdminChange}
              isLoading={status === 'PENDING'}
            />
          </div>
        }
        onSave={onClickSave}
      />
    </div>
  );
};

export default OperationalAdminEdit;
