import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import DOMPurify from 'dompurify';

function HTMLDisplay(htmlContent) {
  const sanitizedHtml = DOMPurify.sanitize(htmlContent);
  return <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
}

const Dashboard = () => {
  const userDetailsData = useSelector((state) => state.auth);
  const { userActiveRolePermissions } = userDetailsData;

  return (
    <div className="section">
      <div className="">
        <div className="row">
          <div className="col-lg-12">
            <div className="text-center base-padding-bottom">
              <h4>Welcome to Supply Chain Partner Registration</h4>
            </div>

            <p>
              We have made some changes! Our updated registration tool features
              a modern design guiding you effortlessly through every step. The
              fusion of aesthetics and functionality sculpts a new engaging user
              experience.
            </p>
            {userActiveRolePermissions &&
            userActiveRolePermissions?.length &&
            userActiveRolePermissions[0]?.displayContent ? (
              <div>
                {HTMLDisplay(userActiveRolePermissions[0]?.displayContent)}
              </div>
            ) : null}
            <p>
              Ready to get started? Click a tab in the navigation bar above to
              start your journey.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
