import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';

import FullScreenLoader from '../../molecules/FullScreenLoader';
import {
  fetchProfile,
  fetchUserType,
  fetchRolePermissions,
  getIsFederatedUser
} from '../../../actions';

const UserWrapper = () => {
  const dispatch = useDispatch();
  const userDetailsData = useSelector((state) => state.auth);
  const {
    getUserTypeStatus,
    getUserTypeErrorMessage,
    getRolePermissionsStatus,
    getRolePermissionsErrorMessage,
    getIsFederatedUserStatus,
    mainUserType
  } = userDetailsData;

  useEffect(() => {
    dispatch(fetchUserType());

    dispatch(fetchRolePermissions());
    dispatch(getIsFederatedUser());
  }, []);

  useEffect(() => {
    if (mainUserType === 'EXTERNAL') {
      dispatch(fetchProfile());
    }
  }, [mainUserType]);

  // if (
  //   getUserTypeStatus === 'SUCCESS' &&
  //   mainUserType === 'INTERNAL' &&
  //   userType &&
  //   !userType?.includes('pa') &&
  //   userType &&
  //   !userType?.includes('pam')
  // ) {
  //   return <OneAccessLink />;
  // } else {
  return (
    <>
      {(getUserTypeStatus === 'PENDING' ||
        getRolePermissionsStatus === 'PENDING' ||
        getIsFederatedUserStatus === 'PENDING') && (
        <FullScreenLoader title={'Fetching user details'} />
      )}
      {getUserTypeStatus === 'SUCCESS' &&
        getRolePermissionsStatus === 'SUCCESS' &&
        getIsFederatedUserStatus === 'SUCCESS' && <Outlet />}

      {getUserTypeStatus === 'FAILURE' && (
        <div className="base-margin-top">
          <div className="alert alert--warning">
            <div className="alert__message">{getUserTypeErrorMessage}</div>
          </div>
        </div>
      )}

      {getRolePermissionsStatus === 'FAILURE' && (
        <div className="base-margin-top">
          <div className="alert alert--warning">
            <div className="alert__message">
              {getRolePermissionsErrorMessage}
            </div>
          </div>
        </div>
      )}
    </>
  );
  //}
};

export default UserWrapper;
