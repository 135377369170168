import React, { useEffect, useState } from 'react';

import {
  theme,
  colourStyles,
  DropdownIndicator,
  IndicatorSeparator,
  SingleValue
} from '../Select';
import '../Select/index.css';
import { components, NoticeProps, OptionProps } from 'react-select';
import { SelectOption } from '../Select/Select';
import PaginateSelect from '../Select/PaginateSelect';

export const UserSelectOption = (props: OptionProps<SelectOption>) => {
  const { data } = props;
  return (
    <div>
      <components.Option className="half-padding" {...props}>
        <div>{data?.firstName + ' ' + data?.lastName}</div>
        <div className="text-size-12">{data?.email}</div>
      </components.Option>
    </div>
  );
};

const noOptionsMessage = (noOptionsMessageProps: NoticeProps) => {
  const MIN_INPUT_LENGTH = 3;
  const [noOptionsMsg, setNoOptionsMsg] = useState('');

  useEffect(() => {
    if (!noOptionsMessageProps?.selectProps?.inputValue) {
      setNoOptionsMsg('Start typing to find');
    } else if (
      noOptionsMessageProps?.selectProps?.inputValue?.length < MIN_INPUT_LENGTH
    ) {
      setNoOptionsMsg(
        `Search input must be at least ${MIN_INPUT_LENGTH} characters`
      );
    } else {
      setNoOptionsMsg('');
    }
  }, [noOptionsMessageProps]);
};

const UserPaginateSelect = ({ value, onChange, ...props }: any) => {
  const [type, setType] = useState({
    value: Math.random().toString(16),
    label: Math.random().toString(16)
  });
  const removeValue = (rowValue: string) => {
    const existingValue = value || [];
    const filteredValue = existingValue.filter(
      (item: SelectOption) => item.value !== rowValue
    );

    if (onChange) onChange(filteredValue);
  };

  if (value) value = Array.isArray(value) ? value : [value];

  return (
    <>
      <div className="flex flex-between flex-middle">
        <div style={{ flex: 1 }}>
          <PaginateSelect
            controlShouldRenderValue={false}
            theme={theme}
            styles={{
              ...colourStyles
            }}
            value={value}
            onChange={onChange}
            components={{ Option: UserSelectOption, noOptionsMessage }}
            onInputChange={() => {
              setType({
                value: Math.random().toString(16),
                label: Math.random().toString(16)
              });
            }}
            cacheUniqs={[type]}
            placeholder={<div>Search PA....</div>}
            {...props}
          />
        </div>
      </div>
      {value && value?.length ? (
        <div className="qtr-margin-top card">
          <div className="responsive-table" style={{ maxHeight: '215px' }}>
            <table className="table table--lined table--compressed">
              <tbody>
                {value?.map((item: SelectOption) => (
                  <tr key={item.value}>
                    <td style={{ width: `calc(100% - 40px)` }}>
                      {item?.firstName + ' ' + item?.lastName} ({item?.email}){' '}
                      <br />
                    </td>
                    <td className="text-center" style={{ width: '40px' }}>
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => removeValue(item?.value)}
                        className="icon-exit-contain"
                      ></span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default UserPaginateSelect;
