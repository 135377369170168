import React, { useState, useEffect, useRef, useMemo } from 'react';
import moment from 'moment';
import { AgGridReact } from 'ag-grid-react';
import { Tooltip } from 'react-tooltip';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import httpUtil from '../../../helpers/interceptor';
import { fetchOnboadingCompanies } from '../../../services';

import Loader from '../../atoms/Loader';
import CompanyOnboardView from '../../molecules/CompanyOnboardView/CompanyOnboardView';

const AddressTooltip = (props) => {
  const data = useMemo(
    () => props.api.getDisplayedRowAtIndex(props.rowIndex).data,
    []
  );

  return (
    <div
      className="custom-tooltip"
      style={{ backgroundColor: props.color || 'white' }}
    >
      <p>
        <span>Address Line 1: {data?.addressLine1 || '-'}</span>
      </p>
      <p>
        <span>Address Line 2: {data?.addressLine2 || '-'}</span>
      </p>
      <p>
        <span>City: {data?.city || '-'}</span>
      </p>
      <p>
        <span>State: {data?.state || '-'}</span>
      </p>
      <p>
        <span>Country: {data?.country || '-'}</span>
      </p>
      <p>
        <span>Postal Code: {data?.postalCode || '-'}</span>
      </p>
    </div>
  );
};

const DateTooltip = (props) => {
  const data = useMemo(
    () => props.api.getDisplayedRowAtIndex(props.rowIndex).data,
    []
  );

  return (
    <div
      className="custom-tooltip"
      style={{ backgroundColor: props.color || 'white' }}
    >
      {data?.endActiveDate
        ? moment(new Date(data?.endActiveDate)).format('MM-DD-YYYY')
        : '-'}
    </div>
  );
};

const SelectCompaniesTable = ({ value: selectedValue, onSelect }) => {
  const gridRef = useRef(null);
  const [gridApi, setGridApi] = useState(null);

  const [rows, setRows] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [company, setCompany] = useState();
  const [status, setStatus] = useState(undefined);
  const [viewLoader, setViewLoader] = useState(false);
  const [value, setValue] = useState({
    searchBy: 'companyName'
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [getCompanyErrorMessage, setGetCompanyErrorMessage] =
    useState(undefined);
  const [validation, setValidation] = useState({});

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const handleChange = (event) => {
    if (event.target.name === 'searchBy') {
      setIsSubmitted(false);
      setRows([]);
      setValue({
        ...value,
        ...{ [event.target.name]: event.target.value, search: undefined }
      });
    } else {
      setValue({ ...value, ...{ [event.target.name]: event.target.value } });
    }
  };

  const handleIncludeOnboardAvailableCompChange = (event) => {
    const { checked } = event.target;
    setValue({ ...value, ...{ [event.target.name]: checked } });
  };
  const onCompanySelect = (company) => {
    company.addressLine2 =
      company.addressLine2 == 'null' ? '' : company?.addressLine2;
    if (onSelect) onSelect(company);
  };

  useEffect(() => {
    if (gridApi) {
      if (viewLoader) gridApi.showLoadingOverlay();
      else gridApi.hideOverlay();
    }
  }, [viewLoader]);

  const onClickViewDialogOpen = (companyRow) => {
    const { companyIdRef } = companyRow;
    httpUtil
      .post(`/api/am/dashboard/v1/company/existingCompanySearch`, {
        companyId: companyIdRef
      })
      .then((res) => {
        setCompany(res);
        setDialogOpen(true);
        setViewLoader(false);
      })
      .catch((error) => {
        setGetCompanyErrorMessage(error);
        setStatus('ERROR');
        setViewLoader(false);
      });
  };

  useEffect(() => {
    const columnsList = [
      {
        headerName: 'Company Name',
        field: 'companyName',
        filter: false,
        tooltipField: 'companyName',
        width: 200
      },
      {
        headerName: 'Vendor Id',
        field: 'vendorId',
        tooltipField: 'vendorId',
        filter: false,
        width: 100
      },
      {
        headerName: 'Site Id',
        field: 'supplierSiteId',
        filter: false,
        tooltipField: 'supplierSiteId',
        width: 100
      },
      {
        headerName: 'Site Address',
        field: 'supplierSiteId',
        filter: false,
        tooltipField: 'supplierSiteId',
        flex: 1,
        minWidth: 100,
        tooltipComponent: AddressTooltip,
        cellRenderer: (params) => {
          const itemData = params.data;
          return (
            <div>
              <div>
                {itemData?.addressLine1}
                {itemData?.addressLine2 ? `, ${itemData?.addressLine2}` : null}
                {itemData?.city ? `, ${itemData?.city}` : null}
                {itemData?.state ? `, ${itemData?.state}` : null}{' '}
                {itemData?.country ? `, ${itemData?.country}` : null}{' '}
                {itemData?.postalCode ? `, ${itemData?.postalCode}` : null}
              </div>
            </div>
          );
        }
      },
      {
        headerName: 'End Active Date',
        field: 'endActiveDate',
        tooltipField: 'endActiveDate',
        width: 130,
        tooltipComponent: DateTooltip,
        cellRenderer: (params) => {
          return params?.value
            ? moment(new Date(params?.value)).format('MM-DD-YYYY')
            : '-';
        }
      },
      {
        headerName: 'CFN/AP Status',
        field: 'status',
        width: 120,
        cellRenderer: (params) => {
          if (params.data?.endActiveDate) {
            return (
              <span className="label label--warning label--small label--bordered">
                Inactive
              </span>
            );
          } else {
            return (
              <span className="label label--success label--small label--bordered">
                Active
              </span>
            );
          }
        }
      },
      {
        headerName: 'Action',
        field: 'status',
        width: 200,
        cellRenderer: (params) => {
          const value = params?.value?.toLowerCase();
          if (params.data?.isOnboarded) {
            return (
              <div>
                <span>
                  <a
                    onClick={() => {
                      setViewLoader(true);
                      onClickViewDialogOpen(params.data);
                    }}
                  >
                    {params.data?.onboardingStatus?.toLowerCase() !==
                    'completed' ? (
                      <>
                        {params.data?.onBoardedStatus
                          ?.toLowerCase()
                          .includes('offboarding') ? (
                          <span className="qtr-margin-left">
                            Off-boarding:{' '}
                            <span className="">
                              {params.data?.onBoardedStatus &&
                              params.data?.onBoardedStatus?.toLowerCase() ===
                                'offboarding - inprogress'
                                ? 'In Process'
                                : null}
                              {params.data?.onBoardedStatus &&
                              params.data?.onBoardedStatus?.toLowerCase() ===
                                'offboarding - completed'
                                ? 'Completed'
                                : null}
                            </span>
                          </span>
                        ) : (
                          <span className="qtr-margin-left">
                            On-boarding:{' '}
                            <span className="">
                              {params.data?.onBoardedStatus?.toLowerCase() ===
                              'inprogress'
                                ? 'In Process'
                                : null}
                              {params.data?.onBoardedStatus &&
                              params.data?.onBoardedStatus?.toLowerCase() !==
                                'inprogress'
                                ? params.data?.onBoardedStatus
                                    .charAt(0)
                                    .toUpperCase() +
                                  params.data?.onBoardedStatus
                                    .slice(1)
                                    .toLowerCase()
                                : null}
                            </span>
                          </span>
                        )}
                      </>
                    ) : null}
                    {/* <span className="">
                      On-boarding:{' '}
                      {params.data?.onBoardedStatus?.toLowerCase() ===
                        'inprogress'
                        ? 'In Process'
                        : null}
                      {params.data?.onBoardedStatus &&
                        params.data?.onBoardedStatus?.toLowerCase() !==
                        'inprogress'
                        ? params.data?.onBoardedStatus.charAt(0).toUpperCase() +
                        params.data?.onBoardedStatus.slice(1).toLowerCase()
                        : null}
                    </span> */}
                  </a>
                </span>
              </div>
            );
          }

          if (params.data?.endActiveDate) {
            return 'Contact AP for Onboarding';
          } else {
            if (selectedValue?.supplierSiteId === params.data?.supplierSiteId) {
              return (
                <button type="button" className="btn btn-primary btn--small">
                  Selected
                </button>
              );
            } else {
              return (
                <button
                  type="button"
                  className="btn btn--small btn--ghost flex flex-center flex-middle"
                  onClick={() => onCompanySelect(params?.data)}
                >
                  Select for Onboarding
                  <span className="icon-step-next qtr-margin-left"></span>
                </button>
              );
            }
          }
        }
      }
    ].map((item) => {
      let itemTemp = item;
      itemTemp.headerClass = 'text-weight-900';
      itemTemp.resizable = true;
      // itemTemp.flex = 1;
      itemTemp.suppressMenu = true;
      itemTemp.sortable = false;

      return itemTemp;
    });
    setColumns(columnsList);
  }, [gridApi, selectedValue]);

  const getCompanies = async (filter) => {
    setStatus('PENDING');
    try {
      const response = await fetchOnboadingCompanies(filter);
      let result = response?.map((item) => {
        let itemTemp = item;

        return {
          ...itemTemp,
          companyName: item?.vendorName,
          endActiveDate: item?.endDateActive,
          postalCode: item?.zip,
          addressLine1: item?.address1,
          addressLine2: item?.address2
        };
      });

      const { includeCompanyAvailable } = filter;
      if (includeCompanyAvailable) {
        result = result?.filter(({ isOnboarded }) => !isOnboarded);
      }

      setRows(result || []);
      setTimeout(() => setStatus('SUCCESS'), 500);
    } catch (err) {
      setGetCompanyErrorMessage(err);
      setStatus('ERROR');
    }
  };

  useEffect(() => {}, [rows]);

  const handleSubmit = (event) => {
    event.preventDefault();

    setIsSubmitted(false);
    if (validation && Object.keys(validation).length) {
      setIsSubmitted(true);
      return;
    }

    let { search, searchBy, includeCompanyNotAvailable } = value;

    let obj = {
      includeCompanyAvailable:
        typeof includeCompanyNotAvailable !== 'undefined'
          ? Boolean(!includeCompanyNotAvailable)
          : true
    };

    if (searchBy == 'vendorId' && +search) {
      obj['vendorId'] = search;
    } else if (searchBy == 'companyName') {
      obj['companyName'] = search;
    } else {
      obj['companyName'] = search;
    }
    if (onSelect) onSelect(undefined);
    setIsSubmitted(true);
    getCompanies(obj);
  };

  useEffect(() => {
    checkValidation();
  }, [value]);

  const checkValidation = () => {
    let errors = {};

    if (!value?.search?.trim()) {
      errors.search = 'Search is required';
    } else if (value?.searchBy == 'vendorId' && !+value?.search?.trim()) {
      errors.search = 'Only Numeric Input Expected';
    } else if (
      value?.search?.trim().length < 3 &&
      value?.searchBy !== 'vendorId'
    ) {
      errors.search = 'Minimum 3 character required';
    } else {
      delete errors.search;
    }
    setValidation(errors);
  };

  useEffect(() => {
    if (gridApi) {
      if (status === 'PENDING') {
        gridApi.showLoadingOverlay();
      } else {
        if (rows && rows.length === 0) {
          gridApi.showNoRowsOverlay();
        } else {
          gridApi.hideOverlay();
        }
      }
    }
  }, [rows, status, gridApi]);

  const getRowStyle = (params) => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: '#f2f2f2' };
    }
  };

  const defaultColDef = useMemo(() => {
    return {
      editable: false,
      sortable: false,
      minWidth: 100,
      filter: false,
      resizable: true
    };
  }, []);

  return (
    <div className="">
      <div className="base-padding base-margin-bottom">
        <div className="row">
          <div className="col-md-12">
            <form method="POST" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-auto">
                  <div className="base-margin-top">
                    <div className="form-group form-group--inline">
                      <div className="form-group__text select">
                        <select
                          name="searchBy"
                          value={value.searchBy || 'companyName'}
                          onChange={handleChange}
                          id="select-companySearchBy"
                        >
                          <option value="companyName">Company Name</option>
                          <option value="vendorId">Vendor Id</option>
                        </select>
                        <label htmlFor="select-companySearchBy">
                          Search Company By:
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div
                    className={`form-group form-group--inline base-margin-top ${
                      validation?.search && isSubmitted
                        ? 'form-group--error'
                        : ''
                    }`}
                  >
                    <div className=" row">
                      <div className="col-md-12">
                        <div className="form-group__text">
                          <input
                            name="search"
                            type="text"
                            placeholder=""
                            value={value.search || ''}
                            onChange={handleChange}
                          />
                        </div>
                        {validation?.search && isSubmitted ? (
                          <div className="help-block text-danger" role="alert">
                            <span>{validation?.search}</span>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>

                      {value?.searchBy === 'vendorId' ? (
                        <div className="col-md-12">
                          <div style={{ position: 'relative', zIndex: 10 }}>
                            <span className="text-size-12">
                              (Example : 324234){' '}
                            </span>
                            <span
                              className="icon-info-outline text-info"
                              data-tooltip-id={`tooltip-search-example`}
                              style={{ cursor: 'pointer' }}
                            >
                              <Tooltip
                                id={`tooltip-search-example`}
                                content={
                                  <span style={{ zindex: 11111 }}>
                                    Filter out with proper Vendor Id (Example :
                                    324234){' '}
                                  </span>
                                }
                              />
                            </span>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn--primary base-margin-left base-margin-top"
                    disabled={
                      value?.search === undefined ||
                      value?.search?.trim() === '' ||
                      status === 'PENDING'
                    }
                  >
                    Search
                  </button>
                </div>
                <div className="col-md-12">
                  <div className="base-margin-top">
                    <div className="form-group">
                      <label className="checkbox">
                        <input
                          name="includeCompanyNotAvailable"
                          onChange={handleIncludeOnboardAvailableCompChange}
                          value={true}
                          type="checkbox"
                        />
                        <span className="checkbox__input"></span>
                        <span className="checkbox__label">
                          Include companies not available for onboarding
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {isSubmitted && (
        <div className="ag-theme-alpine" style={{ height: 470, width: '100%' }}>
          <AgGridReact
            ref={gridRef}
            rowData={rows}
            columnDefs={columns}
            defaultColDef={defaultColDef}
            pagination
            paginationPageSize={10}
            gridOptions={{ suppressContextMenu: true }}
            onGridReady={onGridReady}
            overlayNoRowsTemplate={`<span style="padding: 10px;">No companies found</span>`}
            loadingOverlayComponent={Loader}
            enableCellTextSelection={true}
            suppressCellFocus={true}
            getRowStyle={getRowStyle}
            headerHeight={38}
            rowHeight={38}
            tooltipShowDelay={0}
          ></AgGridReact>
        </div>
      )}
      {!viewLoader && (
        <CompanyOnboardView
          companyDetail={company || {}}
          open={dialogOpen}
          onChange={(dialogStatus) => {
            setDialogOpen(dialogStatus);
          }}
        />
      )}
    </div>
  );
};
export default SelectCompaniesTable;
