import { Action } from '../../actions/ActionTypes.interface';

const initialState = {};

export const invitationReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case 'GET_INVITATION_LIST_COMPLETE': {
      return {
        ...state,
        ...{
          getInvitationsListStatus: 'SUCCESS',
          invitations: action.payload,
          getInvitationListErrorMessage: null
        }
      };
    }
    case 'GET_INVITATION_LIST_START': {
      return {
        ...state,
        ...{
          getInvitationsListStatus: 'PENDING',
          getInvitationListErrorMessage: null
        }
      };
    }
    case 'GET_INVITATION_LIST_FAILURE': {
      return {
        ...state,
        ...{
          getInvitationsListStatus: 'FAILURE',
          getInvitationListErrorMessage: action.errorMessage
        }
      };
    }

    case 'GET_INVITATION_LIST_RESET': {
      return {
        ...state,
        ...{
          getInvitationsListStatus: undefined,
          invitations: undefined,
          getNominationsListErrorMessage: null
        }
      };
    }

    default:
      return state;
  }
};
