import React, { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useSearchParams } from 'react-router-dom';

const Login = () => {
  const { oktaAuth } = useOktaAuth();
  const [loading, setLoading] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const redirectUrl = searchParams.get('redirectUrl');

  const handleLogin = async () => {
    setLoading(true);
    await oktaAuth.signInWithRedirect({ originalUri: redirectUrl || '/' });
    setLoading(false);
  };

  useEffect(() => {
    handleLogin();
  }, []);

  return (
    <div className="vertical-center">
      <div className="container">
        <div className="section">
          <div className="text-center">
            {loading ? <h5>Redirecting for login...</h5> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
