import httpUtil from '../helpers/interceptor';
import notify from '../helpers/notification';

export const fetchRequesterActiveRequest = (isUpdate = false) => {
  return (dispatch) => {
    if (isUpdate) {
      dispatch({
        type: 'UPDATE_REQUEST_START'
      });
    } else {
      dispatch({ type: 'REQUESTOR_REQUEST_START' });
    }
    return httpUtil
      .post(`/api/user/v1/request`)
      .then((res) => {
        dispatch({
          type: 'REQUESTOR_REQUEST_COMPLETE',
          payload: res || undefined
        });

        return res;
      })
      .catch((error) => {
        let errorMessage = '';
        if (error?.response?.status != 401) {
          errorMessage = `An error occurred while fetching user active request ${
            error?.response?.data?.error
              ? ': ' + error?.response?.data?.error
              : ''
          }`;
          notify(errorMessage, 'error');
        }

        dispatch({
          type: 'REQUESTOR_REQUEST_FAILURE',
          payload: errorMessage
        });
      });
  };
};

export const fetchUserActiveRequest = (payload = {}, isUpdate = false) => {
  return (dispatch) => {
    if (isUpdate) {
      dispatch({
        type: 'UPDATE_REQUEST_START'
      });
    } else {
      dispatch({ type: 'ACTIVE_REQUEST_START' });
    }
    return httpUtil
      .post(`/api/user/v1/approver/request`, payload)
      .then((res) => {
        if (isUpdate) {
          dispatch({
            type: 'UPDATE_REQUEST_COMPLETE',
            payload: res || undefined
          });
        } else {
          dispatch({
            type: 'ACTIVE_REQUEST_COMPLETE',
            payload: res || undefined
          });
        }
        return res;
      })
      .catch((error) => {
        let errorMessage = '';
        if (error?.response?.status != 401) {
          errorMessage = `An error occurred while fetching user active request ${
            error?.response?.data?.error
              ? ': ' + error?.response?.data?.error
              : ''
          }`;
          notify(errorMessage, 'error');
        }

        if (isUpdate) {
          dispatch({
            type: 'UPDATE_REQUEST_FAILURE',
            payload: errorMessage
          });
        } else {
          dispatch({
            type: 'ACTIVE_REQUEST_FAILURE',
            payload: errorMessage
          });
        }
      });
  };
};

export const fetchRequestHistory = (userType) => {
  return (dispatch) => {
    dispatch({
      type: 'REQUEST_HISTORY_START'
    });
    return httpUtil
      .post(`/api/v1/approver/history`, {
        userType: userType
      })
      .then((res) => {
        dispatch({
          type: 'REQUEST_HISTORY_COMPLETE',
          payload: res || undefined
        });

        return res;
      })
      .catch((error) => {
        let errorMessage = '';
        if (error?.response?.status != 401) {
          errorMessage = `An error occurred while fetching request history ${
            error?.response?.data?.error
              ? ': ' + error?.response?.data?.error
              : ''
          }`;
          notify(errorMessage, 'error');
        }
        dispatch({
          type: 'REQUEST_HISTORY_FAILURE',
          payload: errorMessage
        });
      });
  };
};
