import React, { useState, useEffect } from 'react';
import Dialog from '../../../atoms/Dialog/Dialog';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchAllOrganization,
  fetchCountries,
  fetchCustomers
} from '../../../../actions';
import Select, { SelectOption } from '../../../molecules/Select';
import Loader from '../../../atoms/Loader/Loader';
import notify from '../../../../helpers/notification';
import {
  fetchExistingCompanySearch,
  updateCountries,
  updateCustomers,
  updateOrgs
} from '../../../../services';
import {
  getAddRemovedPayload,
  checkIsChanged
} from '../../../../helpers/common';

type CompanyDetailTypes = {
  companyType: string;
  organizations: [];
  countries: [];
  customers: [];
};

export interface CompanyTypeDialogProps {
  companyDetail: CompanyDetailTypes;
  companyId: string | undefined;
  open: boolean;
  onChange: Function;
  updateFormValue: Function;
  mandatory?: string[] | null;
  userType: any;
  companyNodeId: any;
}

const validationData = {
  companyType: '',
  countries: '',
  organizations: '',
  customers: '',
  primaryOperationalAdmin: ''
};

const CompanyTypeMandatoryDialog = ({
  companyDetail,
  open: isDialogOpen = false,
  companyId,
  onChange = (isVisble: boolean) => {},
  updateFormValue,
  mandatory,
  userType,
  companyNodeId
}: CompanyTypeDialogProps) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [validation, setValidation] = useState<any>(validationData);
  const [loading, setLoading] = useState(false);
  const [formValue, setFormValue] = React.useState<any>({
    companyType: ''
  });
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const companyOnboardData = useSelector((state: any) => state.onboardCompany);

  const {
    countries = [],
    customers = [],
    organizations: organizationsOptions = [],
    getOrganizationStatus,
    getCustomersStatus,
    getCountriesStatus,
    getAllCompanyTypesStatus
  } = companyOnboardData;

  const getOperationalAdminStatus = useSelector(
    (state: any) => state.onboardCompany?.getOperationalAdminStatus
  );

  useEffect(() => {
    if (formValue?.isOrgShow && getOrganizationStatus !== 'SUCCESS') {
      dispatch(fetchAllOrganization());
    }

    if (formValue?.isCountryShow && getCountriesStatus !== 'SUCCESS') {
      dispatch(fetchCountries());
    }

    if (formValue?.isCustomerShow && getCustomersStatus !== 'SUCCESS') {
      dispatch(fetchCustomers());
    }
  }, [formValue?.companyType]);

  const onSelectorChange = (
    newValue:
      | SelectOption
      | SelectOption[]
      | string[]
      | string
      | undefined
      | number,
    name: string
  ) => {
    setFormValue({
      ...formValue,
      ...{
        [name]: newValue
      }
    });
  };

  const onCountriesChange = (newValue = []) => {
    setFormValue((prev: any) => ({
      ...prev,
      ...{
        countries: newValue
      }
    }));
  };

  useEffect(() => {
    setOpen(isDialogOpen);
  }, [isDialogOpen]);

  const dialogClose = () => {
    if (!loading) {
      setOpen(false);
      if (onChange) onChange(false);
    }
  };

  useEffect(() => {
    setFormValue((prev: any) => ({
      ...prev,
      ...companyDetail
    }));
  }, [companyDetail]);

  const organizationsChange = (newValue = []) => {
    const selectedOrganizations: string[] =
      newValue?.map((item: SelectOption) => item.value) || [];

    setFormValue((prev: any) => ({
      ...prev,
      ...{
        organizations: selectedOrganizations
      }
    }));
  };

  useEffect(() => {
    checkValidation();
  }, [formValue]);

  const checkValidation = () => {
    let errors: any = {};

    if (formValue?.isCountryShow && formValue?.isCountryMandatory) {
      if (!formValue?.countries?.length) {
        errors.countries = 'Countries is required';
      }
    }

    if (formValue?.isCustomerShow && formValue?.isCustomerMandatory) {
      if (!formValue?.customers?.length) {
        errors.customers = 'Customers is required';
      }
    }

    if (formValue?.isOrgShow && formValue?.isOrgMandatory) {
      if (!formValue?.organizations || !formValue?.organizations?.length) {
        errors.organizations = 'Company Orgs is required';
      }
    }

    setValidation(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    setIsSubmitted(true);

    if (!checkValidation()) {
      return;
    }

    const orgsAddRemovedPayload = getAddRemovedPayload(
      companyDetail?.organizations || [],
      formValue?.organizations || []
    );

    const countriesAddRemovedPayload = getAddRemovedPayload(
      companyDetail?.countries?.map(({ value }: SelectOption) => value) || [],
      formValue?.countries?.map(({ value }: SelectOption) => value) || []
    );

    const customersAddRemovedPayload = getAddRemovedPayload(
      companyDetail?.customers?.map(({ value }: SelectOption) => value) || [],
      formValue?.customers?.map(({ value }: SelectOption) => value) || []
    );

    const updateCountriesPayload = {
      companyId,
      companyNodeId,
      userType: userType
        ? userType?.map((item: string) => item?.toUpperCase())
        : [],
      countries:
        formValue?.countries?.map(({ value }: SelectOption) => value) || [],
      addCountriesList: countriesAddRemovedPayload.addedList,
      removeCountriesList: countriesAddRemovedPayload.removedList
    };

    const updateCustomersPayload = {
      companyId,
      companyNodeId,
      userType: userType
        ? userType?.map((item: string) => item?.toUpperCase())
        : [],
      customers:
        formValue?.customers?.map(({ value }: SelectOption) => value) || [],
      addCustomersList: customersAddRemovedPayload.addedList,
      removeCustomersList: customersAddRemovedPayload.removedList
    };

    const updateOrgsPayload = {
      companyId,
      companyNodeId,
      userType: userType
        ? userType?.map((item: string) => item?.toUpperCase())
        : [],
      requestType: 'ORG',
      organizations: formValue?.organizations || [],
      addOrgsList: orgsAddRemovedPayload.addedList,
      removeOrgsList: orgsAddRemovedPayload.removedList
    };

    setLoading(true);

    const updatePromise = [];
    const messageLabel = [];
    if (
      formValue?.isOrgShow &&
      checkIsChanged(
        companyDetail?.organizations || [],
        formValue?.organizations || []
      )
    ) {
      updatePromise.push(updateOrgs(updateOrgsPayload));
      messageLabel.push('Company Orgs');
    }
    if (
      formValue?.isCountryShow &&
      checkIsChanged(
        companyDetail?.countries?.map(({ value }: SelectOption) => value) || [],
        formValue?.countries?.map(({ value }: SelectOption) => value) || []
      )
    ) {
      updatePromise.push(updateCountries(updateCountriesPayload));
      messageLabel.push('Countries');
    }
    if (
      formValue?.isCustomerShow &&
      checkIsChanged(
        companyDetail?.customers?.map(({ value }: SelectOption) => value) || [],
        formValue?.customers?.map(({ value }: SelectOption) => value) || []
      )
    ) {
      updatePromise.push(updateCustomers(updateCustomersPayload));
      messageLabel.push('Customers');
    }

    try {
      const response = await Promise.all(updatePromise);
      if (companyId) {
        const companyData = await fetchExistingCompanySearch(companyId);
        setLoading(false);
        updateFormValue(companyData);
        notify(
          `${messageLabel?.join(', ')} has been successfully updated`,
          'success'
        );
      }
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        // showCloseButton={!mandatory}
        size={''}
        onDialogClose={dialogClose}
        closeOnClickOutside={false}
        title={`Update Mandatory Fields`}
        body={
          <form onSubmit={handleSubmit}>
            {loading ||
            getCustomersStatus === 'PENDING' ||
            getOrganizationStatus === 'PENDING' ||
            getCountriesStatus === 'PENDING' ||
            getAllCompanyTypesStatus === 'PENDING' ||
            getOperationalAdminStatus === 'PENDING' ? (
              <Loader />
            ) : (
              <>
                <div className="row">
                  <div className="col-md-12 half-margin-bottom half-margin-top">
                    <div className="alert alert--warning">
                      <div className="alert__icon icon-warning-outline"></div>
                      <div className="alert__message">
                        Please add mandatory fields ({mandatory?.join(', ')})
                      </div>
                    </div>
                  </div>
                  {formValue?.isCountryShow ? (
                    <>
                      <div className="col-md-6 half-margin-bottom half-margin-top">
                        <div className={`form-group`}>
                          <div className="form-group__text">
                            <label
                              htmlFor="input-type-countries-name"
                              className={`text-weight-700 ${
                                formValue?.isCountryMandatory
                                  ? 'required'
                                  : null
                              }`}
                            >
                              Countries
                            </label>

                            <Select
                              value={formValue?.countries}
                              isMulti
                              name="countries"
                              options={
                                countries?.length
                                  ? countries?.map((item: any) => {
                                      return {
                                        value: item,
                                        label: item
                                      };
                                    })
                                  : []
                              }
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={onCountriesChange}
                            />
                            {isSubmitted &&
                              validation &&
                              validation['countries'] && (
                                <span className="text-danger text-size-12">
                                  {validation['countries']}
                                </span>
                              )}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}

                  {formValue?.isCustomerShow ? (
                    <>
                      <div className="col-md-6 half-margin-bottom half-margin-top">
                        <div className={`form-group`}>
                          <div className="form-group__text">
                            <label
                              htmlFor="input-type-customers-name"
                              className={`text-weight-700 ${
                                formValue?.isCustomerMandatory
                                  ? 'required'
                                  : null
                              }`}
                            >
                              Customers
                            </label>

                            <Select
                              value={formValue?.customers}
                              isMulti
                              name="customers"
                              options={
                                customers?.length
                                  ? customers?.map((item: any) => {
                                      return {
                                        value: item,
                                        label: item
                                      };
                                    })
                                  : []
                              }
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={(selectedValues: any) =>
                                onSelectorChange(
                                  selectedValues || [],
                                  'customers'
                                )
                              }
                            />
                            {isSubmitted &&
                              validation &&
                              validation['customers'] && (
                                <span className="text-danger text-size-12">
                                  {validation['customers']}
                                </span>
                              )}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {formValue?.isOrgShow ? (
                    <div className="col-md-6 half-margin-bottom half-margin-top">
                      <div className="form-group">
                        <div className="form-group__text">
                          <label
                            htmlFor="select-additionalOrganization"
                            className={`text-weight-700 ${
                              formValue?.isOrgMandatory ? 'required' : ''
                            }`}
                          >
                            Company Orgs
                          </label>
                          <Select
                            value={
                              formValue?.organizations?.map((item: any) => {
                                return {
                                  value: item,
                                  label: item
                                };
                              }) || []
                            }
                            isMulti
                            name="organizations"
                            options={
                              organizationsOptions?.length
                                ? organizationsOptions.map((item: any) => {
                                    return {
                                      value: item?.organizationCode,
                                      label: `${item?.organizationName} (${item?.organizationCode})`
                                    };
                                  })
                                : []
                            }
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={organizationsChange}
                          />

                          {isSubmitted &&
                            validation &&
                            validation['organizations'] && (
                              <span className="text-danger text-size-12">
                                {validation['organizations']}
                              </span>
                            )}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="flex flex-right base-margin-top">
                  <button
                    type="submit"
                    className="btn btn--ghost"
                    disabled={loading}
                  >
                    Save
                  </button>
                </div>
              </>
            )}
          </form>
        }
        footer={<></>}
      />
    </>
  );
};

export default CompanyTypeMandatoryDialog;
