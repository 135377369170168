import React from 'react';

import Loader from '../../atoms/Loader';

const FullScreenLoader = ({ title = '', height = '' }) => {
  return (
    <div className="fullscreen-loader" style={{ height: height }}>
      <div>
        <Loader />
        {title && (
          <div className="base-margin-top">
            <h6>{title}</h6>
          </div>
        )}
      </div>
    </div>
  );
};

export default FullScreenLoader;
