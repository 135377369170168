import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, Navigate } from 'react-router-dom';

const ProfileStatusCheckWrapper = () => {
  const { pathname } = useLocation();
  const userDetailsData: any = useSelector<any>((state) => state.auth);
  const { mainUserType } = userDetailsData;
  const companyRegisterData = useSelector(
    (state: any) => state?.companyRegisterData
  );
  const { profile, getProfileStatus } = companyRegisterData;

  const [isAccessible, setIsAccessible] = useState<boolean | undefined>();

  useEffect(() => {
    if (mainUserType === 'EXTERNAL') {
      if (getProfileStatus === 'SUCCESS') {
        if (profile?.profileStatus?.toLowerCase() === 'inactive') {
          setIsAccessible(false);
        } else {
          setIsAccessible(true);
        }
      }
    } else {
      setIsAccessible(true);
    }
  }, [mainUserType, profile, getProfileStatus]);

  if (typeof isAccessible === 'undefined') {
    return <></>;
  } else if (typeof isAccessible !== 'undefined' && isAccessible === true) {
    return <Outlet />;
  } else if (typeof isAccessible !== 'undefined' && isAccessible === false) {
    // inactive user redirecting to home page
    if (pathname !== '/') {
      return <Navigate to={'/'} replace={true} />;
    } else if (pathname === '/') {
      return <Outlet />;
    } else {
      return <></>;
    }
  }
  return <></>;
};

export default ProfileStatusCheckWrapper;
