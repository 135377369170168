import React, { useEffect, useState } from 'react';

import Loader from '../../atoms/Loader';
import Dialog from '../../atoms/Dialog';

export interface PARemoveDialogProps {
  details?: any;
  action: string;
  onSuccess?: Function;
  open: boolean;
  onChange?: Function;
  isLoading?: boolean;
}

const PARemoveDialog = ({
  details,
  action,
  onSuccess,
  open,
  onChange,
  isLoading
}: PARemoveDialogProps) => {
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [statusAction, setStatusAction] = useState('');

  useEffect(() => {
    setConfirmDialogOpen(!!open);
  }, [open]);

  useEffect(() => {
    setStatusAction(action);
  }, [action]);

  const onConfirmClick = (action: string) => {
    if (onSuccess) {
      onSuccess(details, action);
    }
  };

  const confirmDialogClose = () => {
    setConfirmDialogOpen(false);
    if (onChange) {
      onChange(false);
    }
  };

  return (
    <div className="">
      <Dialog
        open={confirmDialogOpen}
        size="small"
        onDialogClose={confirmDialogClose}
        closeOnClickOutside={false}
        title={`Confirm`}
        body={
          <div>
            <h4>
              Are you sure to{' '}
              <span>
                {statusAction?.toLowerCase() === 'resend' ? (
                  <span>resend </span>
                ) : (
                  statusAction?.toLowerCase()
                )}{' '}
                the invite of the user {details?.email}?
              </span>
            </h4>

            {isLoading && (
              <div className="center-holder-wrap">
                <Loader />
              </div>
            )}
          </div>
        }
        footer={
          <div>
            <button
              type="button"
              onClick={confirmDialogClose}
              className="btn btn--ghost"
              disabled={isLoading}
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={() => onConfirmClick(statusAction)}
              className="btn btn--primary"
              disabled={isLoading}
            >
              Confirm
            </button>
          </div>
        }
      />
    </div>
  );
};
export default PARemoveDialog;
