import { Action } from '../../actions/ActionTypes.interface';

const initialState = {};

export const auditReportReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case 'GET_EVENT_ACTION_LIST_COMPLETE': {
      return {
        ...state,
        ...{
          getEventActionListStatus: 'SUCCESS',
          eventAction: action.payload,
          getEventActionListErrorMessage: null
        }
      };
    }
    case 'GET_EVENT_ACTION_LIST_START': {
      return {
        ...state,
        ...{
          getEventActionListStatus: 'PENDING',
          getEventActionListErrorMessage: null
        }
      };
    }
    case 'GET_EVENT_ACTION_LIST_FAILURE': {
      return {
        ...state,
        ...{
          getEventActionListStatus: 'FAILURE',
          getEventActionListErrorMessage: action.errorMessage
        }
      };
    }

    case 'GET_EVENT_ACTION_LIST_RESET': {
      return {
        ...state,
        ...{
          getEventActionListStatus: undefined,
          eventAction: undefined,
          getEventActionListErrorMessage: null
        }
      };
    }

    case 'GET_ACTORS_LIST_COMPLETE': {
      return {
        ...state,
        ...{
          getActorsListStatus: 'SUCCESS',
          actors: action.payload,
          getActorsListErrorMessage: null
        }
      };
    }
    case 'GET_ACTORS_LIST_START': {
      return {
        ...state,
        ...{
          getActorsListStatus: 'PENDING',
          getActorsListErrorMessage: null
        }
      };
    }
    case 'GET_ACTORS_LIST_FAILURE': {
      return {
        ...state,
        ...{
          getActorsListStatus: 'FAILURE',
          getActorsListErrorMessage: action.errorMessage
        }
      };
    }

    case 'GET_AUDITLOG_COMPANIES_LIST_COMPLETE': {
      return {
        ...state,
        ...{
          getAuditLogCompaniesListStatus: 'SUCCESS',
          auditLogCompanies: action.payload,
          getAuditLogCompaniesListErrorMessage: null
        }
      };
    }
    case 'GET_AUDITLOG_COMPANIES_LIST_START': {
      return {
        ...state,
        ...{
          getAuditLogCompaniesListStatus: 'PENDING',
          getAuditLogCompaniesListErrorMessage: null
        }
      };
    }
    case 'GET_AUDITLOG_COMPANIES_FAILURE': {
      return {
        ...state,
        ...{
          getAuditLogCompaniesListStatus: 'FAILURE',
          getAuditLogCompaniesListErrorMessage: action.errorMessage
        }
      };
    }

    case 'GET_VENDORID_LIST_COMPLETE': {
      return {
        ...state,
        ...{
          getVendorIdListStatus: 'SUCCESS',
          vendorIds: action.payload,
          getVendorIdListErrorMessage: null
        }
      };
    }
    case 'GET_VENDORID_LIST_START': {
      return {
        ...state,
        ...{
          getVendorIdListStatus: 'PENDING',
          getVendorIdListErrorMessage: null
        }
      };
    }
    case 'GET_VENDORID_LIST_FAILURE': {
      return {
        ...state,
        ...{
          getVendorIdListStatus: 'FAILURE',
          getVendorIdListErrorMessage: action.errorMessage
        }
      };
    }

    case 'GET_SITEID_LIST_COMPLETE': {
      return {
        ...state,
        ...{
          getSiteIdListStatus: 'SUCCESS',
          siteIds: action.payload,
          getSiteIdListErrorMessage: null
        }
      };
    }
    case 'GET_SITEID_LIST_START': {
      return {
        ...state,
        ...{
          getSiteIdListStatus: 'PENDING',
          getSiteIdListErrorMessage: null
        }
      };
    }
    case 'GET_SITEID_LIST_FAILURE': {
      return {
        ...state,
        ...{
          getSiteIdListStatus: 'FAILURE',
          getSiteIdListErrorMessage: action.errorMessage
        }
      };
    }

    default:
      return state;
  }
};
