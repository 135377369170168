import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import RequestsHandle from '../../organisms/RequestsHandle';

const RequestHistory = () => {
  const location = useLocation();

  return (
    <div className="container">
      <div className="section">
        <RequestsHandle
          title={'Request History'}
          requestTableType="requestHistory"
        />
      </div>
    </div>
  );
};
export default RequestHistory;
