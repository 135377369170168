import httpUtil from '../helpers/interceptor';
import notify from '../helpers/notification';

export const checkDomainCompanyRegistered = () => {
  return (dispatch) => {
    dispatch({ type: 'CHECK_DOMAIN_REGISTERED_START' });
    return httpUtil
      .get(`/api/v1/email/check/domain-registration`)
      .then((res) => {
        dispatch({ type: 'CHECK_DOMAIN_REGISTERED_COMPLETE', payload: res });
        return res;
      })
      .catch((error) => {
        let errorMessage = '';
        if (error?.response?.status != 401) {
          errorMessage = `An error occurred while fetching user details ${
            error?.response?.data?.error
              ? ': ' + error?.response?.data?.error
              : ''
          }`;
        }
        dispatch({
          type: 'CHECK_DOMAIN_REGISTERED_FAILURE',
          payload: errorMessage
        });
      });
  };
};

export const getIsFederatedUser = () => {
  return (dispatch) => {
    dispatch({ type: 'GET_IS_FEDERATED_USER_START' });
    return httpUtil
      .get(`/api/v1/user/check/federated`)
      .then((res) => {
        dispatch({ type: 'GET_IS_FEDERATED_USER_COMPLETE', payload: res });
        return res;
      })
      .catch((error) => {
        let errorMessage = '';
        if (error?.response?.status != 401) {
          errorMessage = `An error occurred while fetching user details ${
            error?.response?.data?.error
              ? ': ' + error?.response?.data?.error
              : ''
          }`;
        }
        dispatch({
          type: 'GET_IS_FEDERATED_USER_FAILURE',
          payload: errorMessage
        });
        notify(errorMessage, 'error');
      });
  };
};

export const fetchUserType = () => {
  const sessionId = localStorage.getItem('sessionId');
  return (dispatch) => {
    dispatch({ type: 'GET_USERTYPE_START' });
    return httpUtil
      .post(
        `/api/am/dashboard/v1/user/getUserRole`,
        sessionId ? { sessionId } : {}
      )
      .then((res) => {
        dispatch({ type: 'GET_USERTYPE_COMPLETE', payload: res });
        return res;
      })
      .catch((error) => {
        let errorMessage = '';
        if (error?.response?.status != 401) {
          errorMessage = `An error occurred while fetching user types ${
            error?.response?.data?.error
              ? ': ' + error?.response?.data?.error
              : ''
          }`;
          notify(errorMessage, 'error');
          dispatch({
            type: 'GET_USERTYPE_FAILURE',
            payload: errorMessage
          });
        }
      });
  };
};

export const fetchRolePermissions = () => {
  return (dispatch) => {
    dispatch({ type: 'GET_ROLE_PERMISSION_START' });
    return httpUtil
      .post(`/api/am/dashboard/v1/user/getGroupNameAndPermission`, {})
      .then((res) => {
        dispatch({ type: 'GET_ROLE_PERMISSION_COMPLETE', payload: res });
        return res;
      })
      .catch((error) => {
        let errorMessage = '';
        if (error?.response?.status != 401) {
          errorMessage = `An error occurred while fetching role permissions ${
            error?.response?.data?.error
              ? ': ' + error?.response?.data?.error
              : ''
          }`;
          notify(errorMessage, 'error');
          dispatch({
            type: 'GET_ROLE_PERMISSION_FAILURE',
            errorMessage: errorMessage
          });
        }
      });
  };
};

export const fetchSCOUserGroup = () => {
  return (dispatch) => {
    dispatch({ type: 'GET_SCOUSER_GROUP_START' });
    return httpUtil
      .post(`/api/am/dashboard/v1/user/sco/getGroupNameAndUserRole`, {})
      .then((res) => {
        dispatch({ type: 'GET_SCOUSER_GROUP_COMPLETE', payload: res });
        return res;
      })
      .catch((error) => {
        let errorMessage = '';
        if (error?.response?.status != 401) {
          errorMessage = `An error occurred while fetching SCO user group ${
            error?.response?.data?.error
              ? ': ' + error?.response?.data?.error
              : ''
          }`;
          notify(errorMessage, 'error');
          dispatch({
            type: 'GET_SCOUSER_GROUP_FAILURE',
            payload: errorMessage
          });
        }
      });
  };
};
