import React, { useEffect, useState } from 'react';

import Loader from '../../atoms/Loader';
import httpUtil from '../../../helpers/interceptor';
import notify from '../../../helpers/notification';
import Dialog from '../../atoms/Dialog';

export interface InvitationApprovalProps {
  invitation: any;
  action: string;
  onSuccess?: Function;
  open: boolean;
  onChange?: Function;
}

const UserInvitationApproval = ({
  invitation,
  action,
  onSuccess,
  open,
  onChange
}: InvitationApprovalProps) => {
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [remark, setRemark] = useState('');
  const [validation, setValidation] = useState<{ remark?: string }>({});
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [statusAction, setStatusAction] = useState('');

  useEffect(() => {
    setConfirmDialogOpen(!!open);
  }, [open]);

  useEffect(() => {
    return () => {
      setRemark('');
    };
  }, [invitation]);

  useEffect(() => {
    setStatusAction(action);
  }, [action]);

  useEffect(() => {
    checkValidation(statusAction === 'DENY');
  }, [remark, statusAction]);

  const onRemarkChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setRemark(event.target.value);
  };
  const checkValidation = (isRemarkRequired = false) => {
    let errors: { remark?: string } = { ...validation };
    let remarkValue = remark?.trim();

    if (!remarkValue && isRemarkRequired) {
      errors.remark = 'Required for deny';
    } else if (isRemarkRequired && remarkValue && remarkValue.length < 25) {
      errors.remark = 'Remark must be at least 25 characters';
    } else if (isRemarkRequired && remarkValue && remarkValue.length > 250) {
      errors.remark = "You can't write more than 250 characters";
    } else {
      delete errors.remark;
    }
    setValidation(errors);
    return Object.keys(errors).length === 0;
  };

  const updatedInvite = (action: string, inviteUpdateData = {}) => {
    if (checkValidation(action === 'DENY')) {
      let apiUrl = '/api/am/dashboard/v1/onBoardingApproveEmail';
      setIsUpdateLoading(true);
      httpUtil
        .put(apiUrl, inviteUpdateData)
        .then((res: any) => {
          setIsUpdateLoading(false);

          let actionSuccessText = '';
          let actionFailedText = '';
          switch (true) {
            case action === 'ACCEPT':
              actionSuccessText = 'accepted';
              actionFailedText = 'accepting';
              break;

            case action === 'DENY':
              actionSuccessText = 'denied';
              actionFailedText = 'denying';
              break;

            default:
              actionSuccessText = 'updated';
              actionFailedText = 'updating';
              break;
          }
          const responseStatus = res?.status?.toLowerCase();
          if (responseStatus === 'success') {
            notify(
              `Invite has been ${actionSuccessText} successfully`,
              'success'
            );
            if (onSuccess) {
              onSuccess();
            }
            setConfirmDialogOpen(false);
            if (onChange) onChange(false);
          } else {
            notify(
              `An error occurred while ${actionFailedText} invite, Please try again`,
              'error'
            );
          }
          return res;
        })
        .catch((error) => {
          setIsUpdateLoading(false);
          let errorMessage = '';
          if (error?.response?.status != 401) {
            errorMessage = `An error occurred while updating invite ${
              error?.response?.data?.error
                ? ': ' + error?.response?.data?.error
                : ''
            }`;
            notify(errorMessage, 'error');
          }
        });
    }
  };

  const updateInviteClick = (action: string) => {
    const inviteUpdateData: {
      inviteId: string;
      remark?: string;
      action: string;
    } = {
      inviteId: invitation?.id,
      action: action.toLocaleLowerCase()
    };

    if (action === 'DENY') {
      inviteUpdateData.remark = remark;
    }
    updatedInvite(action, inviteUpdateData);
  };

  const confirmDialogClose = () => {
    setConfirmDialogOpen(false);
    if (onChange) {
      onChange(false);
    }
  };

  const statusSubmit = (event: React.FormEvent) => {
    event.preventDefault();
  };

  return (
    <div className="">
      <Dialog
        open={confirmDialogOpen}
        size="small"
        onDialogClose={confirmDialogClose}
        closeOnClickOutside={false}
        title={`Confirm`}
        body={
          <div>
            <form name="statusform" onSubmit={statusSubmit}>
              <h4>
                Are you sure to <span>{statusAction?.toLowerCase()}</span>
              </h4>

              {statusAction === 'DENY' ? (
                <div>
                  <div
                    className={`form-group text-left ${
                      validation?.remark ? 'form-group--error' : ''
                    }`}
                  >
                    <div className="form-group__text">
                      <textarea
                        id="approver-comment"
                        rows={2}
                        value={remark || ''}
                        onChange={onRemarkChange}
                      ></textarea>
                      <label htmlFor="approver-comment">
                        <b>
                          Remark{' '}
                          {validation?.remark && (
                            <>
                              <span
                                className="text-danger"
                                title="this is a required field"
                              >
                                *
                              </span>
                              <span className="text-danger text-size-12">
                                {' '}
                                {validation?.remark}
                              </span>
                            </>
                          )}
                        </b>
                      </label>
                    </div>
                  </div>
                </div>
              ) : null}
              {isUpdateLoading && (
                <div className="center-holder-wrap">
                  <Loader />
                </div>
              )}
            </form>
          </div>
        }
        footer={
          <div>
            <button
              type="button"
              onClick={confirmDialogClose}
              className="btn btn--ghost"
              disabled={isUpdateLoading}
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={() => updateInviteClick(statusAction)}
              className="btn btn--primary"
              disabled={isUpdateLoading}
            >
              Confirm
            </button>
          </div>
        }
      />
    </div>
  );
};
export default UserInvitationApproval;
