import React, { useState, useEffect, useRef } from 'react';
import cloneDeep from 'lodash/cloneDeep';

import './index.css';

export interface LimitDropdownProps {
  data: any[];
  limitToShow?: number;
}

const LimitDropdown = ({ data = [], limitToShow = 1 }: LimitDropdownProps) => {
  const wrapperRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [visibleData, setVisibleData] = useState<any[]>([]);
  const [dropdownData, setDropdownData] = useState<any[]>([]);

  useEffect(() => {
    setVisibleData(cloneDeep(data).splice(0, limitToShow));
    setDropdownData(cloneDeep(data).splice(limitToShow, data.length));
  }, [data, limitToShow]);

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
    function handleClick(e: any) {
      if (wrapperRef && wrapperRef.current) {
        const ref: any = wrapperRef.current;
        if (!ref.contains(e.target)) {
          setIsActive(false);
        }
      }
    }
  }, []);

  return (
    <div ref={wrapperRef} className="symbol-group">
      {visibleData &&
        visibleData.map((item, index) => {
          return (
            <div
              key={`symbol${index}`}
              className="symbol label label--light label--bordered label--small"
            >
              {item}
            </div>
          );
        })}

      {dropdownData && dropdownData.length ? (
        <div className="symbol">
          <span
            className="label label--light label--bordered label--small symbol-hover"
            onClick={() => setIsActive(!isActive)}
          >
            {dropdownData.length} +
          </span>
          {isActive && (
            <div className="symbol-dropdown">
              <div className="symbol-content">
                {dropdownData &&
                  dropdownData.map((item, index) => {
                    return (
                      <div key={`symbol${index}`} className="symbol">
                        <span className="label label--light label--bordered label--small">
                          {item}
                        </span>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default LimitDropdown;
