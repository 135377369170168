import React from 'react';

import { config } from '../../../config/config';

export interface OneAccessRedirectProps {
  roleRequest?: string[];
}

const OneAccessRedirect = ({ roleRequest = [] }: OneAccessRedirectProps) => {
  const { oneAccessUrl } = config;

  return (
    <div className="vertical-center">
      <div className="container">
        <div className="section">
          <div className="text-center">
            <h5 className="base-margin-bottom">
              <span className="text-warning text-weight-600">
                IMPORTANT NOTICE
              </span>
            </h5>

            <p>
              It appears, you do not currently have the necessary role to access
              the Supply Chain Partner Registration tool through One Access. Go
              to{' '}
              <a target="_blank" href={oneAccessUrl} rel="noreferrer">
                oneaccess.cisco.com
              </a>{' '}
              to request a{' '}
              <span className="text-weight-700">
                {'"'}

                {roleRequest?.join(' & ')}
                {'"'}
              </span>{' '}
              role.{' '}
            </p>
            <p>
              If you encounter any system-related problems{' '}
              <a
                target="_blank"
                href="http://go2.cisco.com/sciam"
                rel="noreferrer"
              >
                open
              </a>{' '}
              a case with the Supply Chain Partner Registration Support Team.{' '}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OneAccessRedirect;
