import React, { useState, useEffect, useRef } from 'react';
import { useLocation, NavLink } from 'react-router-dom';

import './index.css';

export interface DropdownProps {
  name: string;
  link?: string;
  childMenu?: any[];
  open?: boolean;
  hasAccess?: boolean;
}

const MenuDisplay = ({
  name,
  link,
  childMenu,
  open,
  hasAccess
}: DropdownProps) => {
  const { pathname } = useLocation();
  const wrapperRef = useRef(null);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (typeof open !== 'undefined') {
      setIsActive(!!open);
    }
  }, [open]);

  useEffect(() => {
    setIsActive(false);
  }, [pathname]);

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
    function handleClick(e: any) {
      if (wrapperRef && wrapperRef.current) {
        const ref: any = wrapperRef.current;
        if (!ref.contains(e.target)) {
          setIsActive(false);
        }
      }
    }
  }, []);

  return hasAccess === undefined || hasAccess === true ? (
    <div ref={wrapperRef}>
      <div className="child-menu-title" onClick={() => setIsActive(!isActive)}>
        {link ? (
          <NavLink
            to={link}
            className={(navData) => (navData.isActive ? 'active' : '')}
          >
            {name}
          </NavLink>
        ) : (
          ''
        )}
        {!link ? (
          <a
            className={`
              ${
                childMenu?.find((item) => item.link == pathname) ? 'active' : ''
              } 
            `}
          >
            {name}{' '}
            {childMenu?.length ? (
              <span
                className={
                  isActive ? 'icon icon-chevron-up' : 'icon icon-chevron-down'
                }
              ></span>
            ) : (
              ''
            )}{' '}
          </a>
        ) : (
          ''
        )}
      </div>
      {isActive && childMenu?.length ? (
        <div className="dropdown_child__menu">
          {childMenu?.map((item, index) => {
            return item?.hasAccess === undefined || item?.hasAccess === true ? (
              <React.Fragment key={`inner-child-menu${item?.name}`}>
                {item?.childMenu ? (
                  <MenuDisplay
                    name={item?.name}
                    childMenu={item?.childMenu}
                    link={item?.link}
                  />
                ) : (
                  <NavLink
                    className={(navData) => (navData.isActive ? 'active' : '')}
                    to={item?.link}
                  >
                    {item?.name}
                  </NavLink>
                )}
              </React.Fragment>
            ) : (
              ''
            );
          })}
        </div>
      ) : (
        ''
      )}
    </div>
  ) : null;
};

const Dropdown = ({
  name,
  link,
  childMenu = [],
  open,
  hasAccess
}: DropdownProps) => {
  const { pathname } = useLocation();
  const wrapperRef = useRef(null);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (typeof open !== 'undefined') {
      setIsActive(!!open);
    }
  }, [open]);

  useEffect(() => {
    setIsActive(false);
  }, [pathname]);

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
    function handleClick(e: any) {
      if (wrapperRef && wrapperRef.current) {
        const ref: any = wrapperRef.current;
        if (!ref.contains(e.target)) {
          setIsActive(false);
        }
      }
    }
  }, []);

  const isItemActive = (item: DropdownProps) => {
    if (item.link === pathname) {
      return true;
    } else if (item?.childMenu?.length) {
      let isActive = false;
      item.childMenu.forEach((menuItem: DropdownProps) => {
        if (isItemActive(menuItem)) {
          isActive = true;
        }
      });
      return isActive;
    }
    return false;
  };

  return hasAccess === undefined || hasAccess === true ? (
    <div
      ref={wrapperRef}
      className={`dropdown-menu dropdown ${isActive ? 'active' : ''}`}
    >
      <div className="dropdown-title" onClick={() => setIsActive(!isActive)}>
        {link ? (
          <NavLink
            to={link}
            className={(navData) => (navData.isActive ? 'active' : '')}
          >
            {name}
          </NavLink>
        ) : (
          ''
        )}
        {!link ? (
          <a
            className={
              childMenu?.find((item) => isItemActive(item)) ? 'active' : ''
            }
          >
            {name}{' '}
            {childMenu?.length ? (
              <span
                className={
                  isActive ? 'icon icon-chevron-up' : 'icon icon-chevron-down'
                }
              ></span>
            ) : (
              ''
            )}{' '}
          </a>
        ) : (
          ''
        )}
      </div>
      {isActive && childMenu?.length ? (
        <div className="dropdown__menu">
          {childMenu?.map((item, index) => {
            return item?.hasAccess === undefined || item?.hasAccess === true ? (
              <React.Fragment key={`dropdown-menu${index}`}>
                {item?.childMenu ? (
                  <ul key={`child-menu${item?.name}`}>
                    <li className="nav-item active">
                      <MenuDisplay
                        name={item?.name}
                        childMenu={item?.childMenu}
                        link={item?.link}
                      />
                    </li>
                  </ul>
                ) : (
                  <NavLink
                    className={(navData) => (navData.isActive ? 'active' : '')}
                    key={`child-menu${item?.name}`}
                    to={item?.link}
                  >
                    {item?.name}
                  </NavLink>
                )}
              </React.Fragment>
            ) : (
              ''
            );
          })}
        </div>
      ) : (
        ''
      )}
    </div>
  ) : null;
};

export default Dropdown;
