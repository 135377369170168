import React from 'react';

import AuditReportScheduleCreateComponent from '../../organisms/AuditReportScheduleCreate';

const AuditReportScheduleCreate = () => {
  return (
    <div className="container">
      <AuditReportScheduleCreateComponent />
    </div>
  );
};
export default AuditReportScheduleCreate;
