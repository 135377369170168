import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer footer--default">
      <div className="footer__links">
        <ul className="list list--inline">
          <li>
            <a
              href="https://cisco.sharepoint.com/sites/SCTDataSecurityandPrivacy/SitePages/Identity-and-Access-Governance.aspx"
              target="_blank"
              rel="noreferrer"
            >
              Contacts
            </a>
          </li>
          <li>
            <Link to={'/feedback'}>Feedback</Link>
          </li>
          <li>
            <a
              href="https://cisco.sharepoint.com/sites/SCTDataSecurityandPrivacy/SitePages/Identity-and-Access-Governance.aspx"
              target="_blank"
              rel="noreferrer"
            >
              Help
            </a>
          </li>
          <li>
            <a
              href="http://www.cisco.com/c/en/us/about/sitemap.html"
              target="_blank"
              rel="noreferrer"
            >
              Site Map
            </a>
          </li>
          <li>
            <a
              href="https://www.cisco.com/c/en/us/about/legal/terms-conditions.html"
              target="_blank"
              rel="noreferrer"
            >
              Terms & Conditions
            </a>
          </li>
          <li>
            <a
              href="https://www.cisco.com/c/en/us/about/legal/privacy-full.html"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Statement
            </a>
          </li>
          <li>
            <a
              href="https://www.cisco.com/c/en/us/about/legal/privacy-full.html#cookies"
              target="_blank"
              rel="noreferrer"
            >
              Cookie Policy
            </a>
          </li>
          <li>
            <a
              href="https://www.cisco.com/c/en/us/about/legal/trademarks.html"
              target="_blank"
              rel="noreferrer"
            >
              Trademarks
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};
export default Footer;
