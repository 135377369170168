import React, { useEffect, useState } from 'react';

import Select, { SelectOption } from '../../../molecules/Select';
import InlineEdit from '../../../molecules/InlineEdit/InlineEdit';
import notify from '../../../../helpers/notification';
import CompanyTypeDialog from './CompanyTypeDialog';

export interface OrgEditProps {
  open?: boolean;
  onOpenChange?: Function;
  companyType?: string;
  value?: any;
  userType?: string[];
  isLoading?: boolean;
  companyId?: string;
  companyNodeId?: string;
  updateCompanyTypeFormValue?: Function;
  title?: React.ReactNode;
}

function CompanyTypeEdit({
  open,
  title,
  onOpenChange,
  companyType,
  value,
  companyId,
  companyNodeId,
  isLoading,
  userType,
  updateCompanyTypeFormValue
}: OrgEditProps) {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [status, setStatus] = useState<string | undefined>();
  const [error, setError] = useState<any | undefined>();
  const [edit, setEdit] = useState<boolean>(false);
  const [editValue, setEditValue] = useState<any>();
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    setEdit(!!open);
  }, [open]);

  useEffect(() => {
    setEditValue(value);
  }, [value]);

  const onEditChange = () => {
    setEdit(true);
    setDialogOpen(true);
    if (onOpenChange) {
      onOpenChange(true);
    }
  };

  const onEditCancel = () => {
    setEditValue(value);
    setEdit(false);
    setDialogOpen(false);
    if (onOpenChange) {
      onOpenChange(false);
    }
    setIsSubmitted(false);
  };

  const updateFormValue = (formValue: any) => {
    setStatus('SUCCESS');
    setEdit(false);
    setDialogOpen(false);

    if (onOpenChange) {
      onOpenChange(false);
    }

    if (updateCompanyTypeFormValue) updateCompanyTypeFormValue(formValue);

    notify('Company Type is updated successfully.', 'success');
  };

  return (
    <>
      {isSubmitted && edit && error?.message ? (
        <div className="alert alert--warning">
          <div className="alert__icon icon-warning-outline"></div>
          <div className="alert__message">{error?.message}</div>
        </div>
      ) : null}

      <InlineEdit
        title={title}
        hideSaveCancelButton={edit}
        readContent={
          <div className="row half-padding-top half-padding-bottom">
            <div className="col-md-12">
              <div className="form-group">
                <div className="form-group__text">
                  <div>
                    <div className="edit-form-value-holder">
                      {editValue?.companyType}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        isEditing={edit}
        onChangeEdit={onEditChange}
        onCancelEdit={onEditCancel}
        isLoading={status === 'PENDING' || isLoading}
        onSave={updateFormValue}
        editContent={
          <div className="row half-padding-top half-padding-bottom">
            <div className="col-md-12">
              <div className="form-group">
                <div className="form-group__text">
                  <div>
                    <div className="edit-form-value-holder">
                      {editValue?.companyType}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />

      {dialogOpen && (
        <CompanyTypeDialog
          companyDetail={editValue}
          open={dialogOpen}
          companyId={companyId}
          updateFormValue={updateFormValue}
          onChange={(dialogStatus: boolean) => {
            onEditCancel();
            setDialogOpen(dialogStatus);
          }}
        />
      )}
    </>
  );
}

export default CompanyTypeEdit;
