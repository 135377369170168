import React from 'react';
import Select, { SelectOption } from '../Select/Select';

function OrgSelect({ ...props }) {
  let { value, onChange } = props;
  const removeValue = (rowValue: string) => {
    const existingValue = value || [];
    const filteredValue = existingValue.filter(
      (item: SelectOption) => item.value !== rowValue
    );
    if (onChange) onChange(filteredValue);
  };

  return (
    <>
      <Select className="basic-multi-select" {...props} />
      {value && value?.length ? (
        <div className="qtr-margin-top card">
          <div className="responsive-table">
            <table className="table table--lined table--compressed">
              <tbody>
                {value.map((item: SelectOption) => (
                  <tr key={item.value}>
                    <td style={{ width: `calc(100% - 40px)` }}>
                      {item.firstName + ' ' + item.lastName} ({item.label})
                    </td>
                    <td className="text-center" style={{ width: '40px' }}>
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => removeValue(item.value)}
                        className="icon-exit-contain"
                      ></span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default OrgSelect;
