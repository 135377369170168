import httpUtil from '../helpers/interceptor';
import notify from '../helpers/notification';

export const fetchEventActions = (body: any) => {
  return (dispatch: Function) => {
    dispatch({ type: 'GET_EVENT_ACTION_LIST_START' });
    return httpUtil
      .post(`/api/am/scheduleJobs/v1/audit/getFilterList`, body)
      .then((res: any) => {
        dispatch({
          type: 'GET_EVENT_ACTION_LIST_COMPLETE',
          payload: res?.PayLoad || []
        });
        return res;
      })
      .catch((error) => {
        let errorMessage = '';
        if (error?.response?.status != 401) {
          errorMessage = `An error occurred while fetching event actions ${
            error?.response?.data?.error
              ? ': ' + error?.response?.data?.error
              : ''
          }`;
          notify(errorMessage, 'error');
        }
        dispatch({
          type: 'GET_EVENT_ACTION_LIST_FAILURE',
          errorMessage: errorMessage
        });
      });
  };
};

export const fetchActors = (body: any) => {
  return (dispatch: Function) => {
    dispatch({ type: 'GET_ACTORS_LIST_START' });
    return httpUtil
      .post(`/api/am/scheduleJobs/v1/audit/getFilterList`, body)
      .then((res: any) => {
        dispatch({
          type: 'GET_ACTORS_LIST_COMPLETE',
          payload: res?.PayLoad || []
        });
        return res;
      })
      .catch((error) => {
        let errorMessage = '';
        if (error?.response?.status != 401) {
          errorMessage = `An error occurred while fetching actors ${
            error?.response?.data?.error
              ? ': ' + error?.response?.data?.error
              : ''
          }`;
          notify(errorMessage, 'error');
        }
        dispatch({
          type: 'GET_ACTORS_LIST_FAILURE',
          errorMessage: errorMessage
        });
      });
  };
};

export const fetchAuditLogCompanies = (body: any) => {
  return (dispatch: Function) => {
    dispatch({ type: 'GET_ACTORS_LIST_START' });
    return httpUtil
      .post(`/api/am/scheduleJobs/v1/audit/getFilterList`, body)
      .then((res: any) => {
        dispatch({
          type: 'GET_AUDITLOG_COMPANIES_LIST_COMPLETE',
          payload: res?.PayLoad || []
        });
        return res;
      })
      .catch((error) => {
        let errorMessage = '';
        if (error?.response?.status != 401) {
          errorMessage = `An error occurred while fetching companies ${
            error?.response?.data?.error
              ? ': ' + error?.response?.data?.error
              : ''
          }`;
          notify(errorMessage, 'error');
        }
        dispatch({
          type: 'GET_AUDITLOG_COMPANIES_FAILURE',
          errorMessage: errorMessage
        });
      });
  };
};

export const fetchVendorId = (body: any) => {
  return (dispatch: Function) => {
    dispatch({ type: 'GET_VENDORID_LIST_START' });
    return httpUtil
      .post(`/api/am/scheduleJobs/v1/audit/getFilterList`, body)
      .then((res: any) => {
        dispatch({
          type: 'GET_VENDORID_LIST_COMPLETE',
          payload: res?.PayLoad || []
        });
        return res;
      })
      .catch((error) => {
        let errorMessage = '';
        if (error?.response?.status != 401) {
          errorMessage = `An error occurred while fetching Vendor Id ${
            error?.response?.data?.error
              ? ': ' + error?.response?.data?.error
              : ''
          }`;
          notify(errorMessage, 'error');
        }
        dispatch({
          type: 'GET_VENDORID_LIST_FAILURE',
          errorMessage: errorMessage
        });
      });
  };
};

export const fetchSiteId = (body: any) => {
  return (dispatch: Function) => {
    dispatch({ type: 'GET_SITEID_LIST_START' });
    return httpUtil
      .post(`/api/am/scheduleJobs/v1/audit/getFilterList`, body)
      .then((res: any) => {
        dispatch({
          type: 'GET_SITEID_LIST_COMPLETE',
          payload: res?.PayLoad || []
        });
        return res;
      })
      .catch((error) => {
        let errorMessage = '';
        if (error?.response?.status != 401) {
          errorMessage = `An error occurred while fetching Vendor Id ${
            error?.response?.data?.error
              ? ': ' + error?.response?.data?.error
              : ''
          }`;
          notify(errorMessage, 'error');
        }
        dispatch({
          type: 'GET_SITEID_LIST_FAILURE',
          errorMessage: errorMessage
        });
      });
  };
};
