import httpUtil from '../helpers/interceptor';
import notify from '../helpers/notification';

export const fetchInvitations = (body = {}) => {
  return (dispatch: Function) => {
    dispatch({ type: 'GET_INVITATION_LIST_START' });
    return httpUtil
      .post(`/api/am/dashboard/v1/user/nomination/getUserNominationList`, body)
      .then((res) => {
        dispatch({
          type: 'GET_INVITATION_LIST_COMPLETE',
          payload: res?.data || []
        });
        return res;
      })
      .catch((error) => {
        let errorMessage = '';
        if (error?.response?.status != 401) {
          errorMessage = `An error occurred while fetching nominations ${
            error?.response?.data?.error
              ? ': ' + error?.response?.data?.error
              : ''
          }`;
          notify(errorMessage, 'error');
        }
        dispatch({
          type: 'GET_INVITATION_LIST_FAILURE',
          errorMessage: errorMessage
        });
      });
  };
};
