import React, { useState, useEffect } from 'react';
import moment from 'moment';

import Dialog from '../../atoms/Dialog/Dialog';
import UserInvitationApproval from '../UserInvitationApproval';
import './index.css';

export interface CompanyTypeDialogProps {
  invitation: any;
  open: boolean;
  onChange: Function;
  onSuccess: Function;
}

const CompanyTypeDialog = ({
  invitation,
  open: isDialogOpen = false,
  onChange = (isVisble: boolean) => {},
  onSuccess
}: CompanyTypeDialogProps) => {
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState<any>(false);
  const [action, setAction] = useState<string | null>(null);
  useEffect(() => {
    setOpen(isDialogOpen);
  }, [isDialogOpen]);

  const dialogClose = () => {
    setOpen(false);
    if (onChange) onChange(false);
  };

  const onInvitationUpdate = () => {
    if (onSuccess) {
      onSuccess();
    }
  };

  const getRoleName = (role: string) => {
    switch (true) {
      case role === 'COMPANY_ONBOARD_SPONSOR_NOTIFICATION':
        return 'Sponsor';

      case role === 'COMPANY_ONBOARD_PAM_NOTIFICATION':
        return 'Partner Account Managers';

      case role === 'COMPANY_ONBOARD_PA_NOTIFICATION':
        return 'Partner Administrators';

      case role === 'COMPANY_ONBOARD_OP_NOTIFICATION':
        return 'Operational Admin';

      case role === 'COMPANY_ONBOARDED_EXISTING_PA_NOTIFICATION':
        return 'Partner Administrators';

      default:
        return role;
    }
  };

  const onClickRequestDialogOpen = (actionValue?: string) => {
    setAction(actionValue || null);
    setDialogOpen(true);
  };

  return (
    <>
      <Dialog
        open={open}
        size={''}
        onDialogClose={dialogClose}
        closeOnClickOutside={false}
        title={`Invitation Details`}
        body={
          <div className="invitation-dialog-body">
            <div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <>
                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                        <div className="record-info">
                          <span className="record-label">Company Name</span>
                          <div>
                            <span className="record-value">
                              {invitation?.companyName}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                        <div className="record-info">
                          <span className="record-label">Company Type</span>
                          <div>
                            <span className="record-value">
                              {invitation?.companyType}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                        <div className="record-info">
                          <span className="record-label">Nominated Role</span>
                          <div>
                            <span className="record-value">
                              {getRoleName(invitation?.notificationName)}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                        <div className="record-info">
                          <span className="record-label">Invited By</span>
                          <div>
                            <span className="record-value">
                              {invitation?.createdBy}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                        <div className="record-info">
                          <span className="record-label">Nominated Date</span>
                          <div>
                            <span className="record-value">
                              {invitation?.creationDate
                                ? moment(
                                    new Date(invitation?.creationDate)
                                  ).format('MM-DD-YYYY')
                                : '-'}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                        <div className="record-info">
                          <span className="record-label">Status</span>
                          <div>
                            <span className="record-value">
                              {invitation?.approvalStatus}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                        <div className="record-info">
                          <span className="record-label">Site Id</span>
                          <div>
                            <span className="record-value">
                              {invitation?.supplierSiteId || '-'}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                        <div className="record-info">
                          <span className="record-label">Site Address</span>
                          <div>
                            <span className="record-value">
                              {invitation?.address1}
                              {invitation?.address2
                                ? ', ' + invitation?.address2
                                : null}
                              {invitation?.city
                                ? ', ' + invitation?.city
                                : null}
                              {invitation?.country
                                ? ', ' + invitation?.country
                                : null}
                              {invitation?.postalCode
                                ? ', ' + invitation?.postalCode
                                : null}
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                </div>
              </div>
            </div>

            {
              <div className="flex flex-right base-margin-top">
                <button
                  type="button"
                  onClick={() => onChange(false)}
                  className="btn btn--ghost"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn--dark"
                  onClick={() => {
                    onClickRequestDialogOpen('DENY');
                  }}
                >
                  Deny
                </button>
                <button
                  type="button"
                  className="btn btn--primary"
                  onClick={() => {
                    onClickRequestDialogOpen('ACCEPT');
                  }}
                >
                  Accept
                </button>
              </div>
            }

            <UserInvitationApproval
              invitation={invitation}
              open={dialogOpen}
              action={action || ''}
              onChange={(dialogStatus: boolean) => {
                setDialogOpen(dialogStatus);
              }}
              onSuccess={onInvitationUpdate}
            />
          </div>
        }
        footer={<></>}
      />
    </>
  );
};

export default CompanyTypeDialog;
