import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// import RequestStatusTable from '../../organisms/RequestStatus';
import RequestsHandle from '../../organisms/RequestsHandle';

const RequestStatus = () => {
  const companyRegisterData = useSelector((state) => state.companyRegisterData);
  const authData = useSelector((state) => state.auth);
  const { isFederatedUser, userType = [] } = authData;
  const [activeTab, setActiveTab] = useState('tab1');
  const requestData = useSelector((state) => state.request);

  const { profileSubmitStatus } = companyRegisterData;

  const { requesterActiveRequest = [] } = requestData;

  const isRequestProfileStatusPending = () => {
    let isExist = false;
    requesterActiveRequest?.forEach(({ status, profileStatus }) => {
      if (
        status?.toLowerCase() === 'approved' &&
        profileStatus?.toLowerCase() === 'pending'
      ) {
        isExist = true;
      }
    });

    return isExist;
  };

  return (
    <div className="base-margin-top">
      {userType.includes('requester') && !profileSubmitStatus && (
        <>
          <h5>Please find the request details for your Supply Chain access.</h5>
          {/* {isRequestProfileStatusPending() && (
            <div className="base-margin-top">
              <div className="alert alert--warning">
                <div className="alert__message">
                  <div>
                    Your Supply Chain profile creation request has been
                    approved.
                  </div>
                  <div className="qtr-margin-top">
                    Your account has not been provisioned. Please contact{' '}
                    <a href="mailto:sciam-support@cisco.com">
                      sciam-support@cisco.com
                    </a>{' '}
                    for Supply Chain access provisioning.
                  </div>
                </div>
              </div>
            </div>
          )} */}
        </>
      )}
      {((isFederatedUser && !userType.includes('requester')) ||
        !isFederatedUser) &&
        !userType.includes('pa') &&
        !userType.includes('pam') && (
          <div className="base-margin-top panel base-padding panel--bordered">
            <RequestsHandle requestTableType="activeRequestorRequest" />
          </div>
        )}

      {(userType.includes('pa') || userType.includes('pam')) && (
        <div className="base-margin-top panel base-padding panel--bordered">
          <ul id="embossed" className="tabs tabs--embossed">
            <li
              id="embossed-1"
              className={`tab  ${activeTab === 'tab1' ? 'active' : ''}`}
              onClick={() => setActiveTab('tab1')}
            >
              <a tabIndex="0">Active Requests</a>
            </li>
            <li
              id="embossed-2"
              className={`tab  ${activeTab === 'tab2' ? 'active' : ''}`}
              onClick={() => setActiveTab('tab2')}
            >
              <a tabIndex="0">Request History</a>
            </li>
          </ul>
          <div id="embossed-content" className="tab-content">
            <div
              id="embossed-1-content"
              className={`tab-pane  ${activeTab === 'tab1' ? 'active' : ''}`}
            >
              <div className="">
                {activeTab === 'tab1' && (
                  <RequestsHandle requestTableType="activeRequest" />
                )}
              </div>
            </div>
            <div
              id="embossed-2-content"
              className={`tab-pane  ${activeTab === 'tab2' ? 'active' : ''}`}
            >
              <div className="">
                {activeTab === 'tab2' && (
                  <RequestsHandle requestTableType="requestHistory" />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default RequestStatus;
