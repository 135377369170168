import httpUtil from '../helpers/interceptor';
import notify from '../helpers/notification';

export const fetchRevalidationDetails = async (body: object = {}) => {
  try {
    const res: any = await httpUtil.post(
      `/api/company/revalidation/v1/company-account-revalidation/details`,
      body
    );
    return res && res?.length ? res : [];
  } catch (error: any) {
    const errorMessage = `An error occurred while fetching Revalidation Details ${
      error?.response?.data?.error ? ': ' + error?.response?.data?.error : ''
    }`;
    notify(errorMessage, 'error');
    return Promise.reject(error);
  }
};
