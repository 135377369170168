import httpUtil from '../helpers/interceptor';
import notify from '../helpers/notification';

export const fetchCompanyAccountApprovalDetails = async (body: any = {}) => {
  try {
    const res: any = await httpUtil.post(
      `/api/am/dashboard/v1/get/company/offboard/reviewDetails`,
      body
    );
    return res && res?.data?.length ? res?.data : [];
  } catch (error: any) {
    const errorMessage = `An error occurred while fetching company Off-boarding review Details ${
      error?.response?.data?.error ? ': ' + error?.response?.data?.error : ''
    }`;
    notify(errorMessage, 'error');
    return Promise.reject(error);
  }
};
