const initialState = {};

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN_COMPLETE': {
      return {
        ...state,
        ...{ status: 'SUCCESS', response: action.payload }
      };
    }
    case 'LOGIN_START': {
      return { ...state, ...{ status: 'PENDING' } };
    }
    case 'LOGIN_FAILURE': {
      return {
        ...state,
        ...{ status: 'FAILURE', errorMessage: action.payload }
      };
    }
    default:
      return state;
  }
};
