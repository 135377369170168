const initialState = {};

export const requestReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'REQUESTOR_REQUEST_COMPLETE': {
      return {
        ...state,
        ...{
          getRequesterActiveRequestStatus: 'SUCCESS',
          requesterActiveRequest: action.payload
        }
      };
    }
    case 'REQUESTOR_REQUEST_START': {
      return {
        ...state,
        ...{
          getRequesterActiveRequestStatus: 'PENDING',
          requesterActiveRequest: undefined
        }
      };
    }
    case 'REQUESTOR_REQUEST_FAILURE': {
      return {
        ...state,
        ...{
          getRequesterActiveRequestStatus: 'FAILURE',
          getRequesterActiveRequestErrorMessage: action.payload,
          requesterActiveRequest: undefined
        }
      };
    }

    case 'ACTIVE_REQUEST_COMPLETE': {
      return {
        ...state,
        ...{
          getActiveRequestStatus: 'SUCCESS',
          activeRequest: action.payload
        }
      };
    }
    case 'ACTIVE_REQUEST_START': {
      return {
        ...state,
        ...{ getActiveRequestStatus: 'PENDING', activeRequest: undefined }
      };
    }
    case 'ACTIVE_REQUEST_FAILURE': {
      return {
        ...state,
        ...{
          getActiveRequestStatus: 'FAILURE',
          getActiveRequestErrorMessage: action.payload,
          activeRequest: undefined
        }
      };
    }

    case 'UPDATE_REQUEST_COMPLETE': {
      return {
        ...state,
        ...{
          getUpdateRequestStatus: 'SUCCESS',
          activeRequest: action.payload
        }
      };
    }
    case 'UPDATE_REQUEST_START': {
      return {
        ...state,
        ...{ getUpdateRequestStatus: 'PENDING' }
      };
    }
    case 'UPDATE_REQUEST_FAILURE': {
      return {
        ...state,
        ...{
          getUpdateRequestStatus: 'FAILURE',
          getUpdateRequestErrorMessage: action.payload
        }
      };
    }

    case 'REQUEST_HISTORY_COMPLETE': {
      return {
        ...state,
        ...{
          getRequestHistoryStatus: 'SUCCESS',
          requestHistory: action.payload
        }
      };
    }
    case 'REQUEST_HISTORY_START': {
      return {
        ...state,
        ...{ getRequestHistoryStatus: 'PENDING', requestHistory: undefined }
      };
    }
    case 'REQUEST_HISTORY_FAILURE': {
      return {
        ...state,
        ...{
          getRequestHistoryStatus: 'FAILURE',
          getRequestHistoryErrorMessage: action.payload,
          requestHistory: undefined
        }
      };
    }

    case 'REQUEST_HISTORY_RESET': {
      return {
        ...state,
        ...{
          getRequestHistoryStatus: undefined,
          getRequestHistoryErrorMessage: undefined,
          requestHistory: undefined
        }
      };
    }

    default:
      return state;
  }
};
