import React, { KeyboardEventHandler, useEffect } from 'react';

import CreatableSelect from '../Select/CreatableSelect';

const components = {
  DropdownIndicator: null
};

interface Option {
  readonly label: string;
  readonly value: string;
}

const createOption = (label: string) => ({
  label,
  value: label
});

export interface EditDomainSelectProps {
  onChange: Function;
  values?: Option[];
}

const EditDomainSelect = ({ onChange, values = [] }: EditDomainSelectProps) => {
  const [inputValue, setInputValue] = React.useState('');
  const [isValid, setIsValid] = React.useState<boolean>(true);

  const handleKeyDown: KeyboardEventHandler = (event) => {
    setIsValid(true);
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        {
          const regexp = /^@[a-z0-9]+([-.]{1}[a-z0-9]+)*[.]{1}[a-z]{2,5}$/i;

          if (!regexp.test(inputValue)) {
            setIsValid(false);
            return;
          }
          const exist = values.find((item) => item.value === inputValue);
          if (!exist) {
            if (onChange) onChange([...values, createOption(inputValue)]);
          }
          setInputValue('');
        }
        event.preventDefault();
    }
  };

  return (
    <>
      <CreatableSelect
        components={components}
        inputValue={inputValue}
        isClearable
        isMulti
        menuIsOpen={false}
        onChange={(newValue: any) => {
          onChange(newValue);
        }}
        onInputChange={(newValue: any) => setInputValue(newValue)}
        onKeyDown={handleKeyDown}
        placeholder="Type something and press enter..."
        value={values}
      />
      {!isValid ? (
        <div className="form-group__help" role="alert">
          <span>Email domain is not valid to add</span>
        </div>
      ) : null}
    </>
  );
};

export default EditDomainSelect;
