import React from 'react';

import ExternalUsersManage from '../../organisms/ExternalUsersManage';

const UsersManage = () => {
  return (
    <div className="container">
      <div className="section">
        <ExternalUsersManage />
      </div>
    </div>
  );
};
export default UsersManage;
