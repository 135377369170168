const commonConfig = {
  userMandatoryRole: {
    name: 'SC_Registration_Standard_Reports',
    displayName: 'SC Registration Standard Reports'
  },
  adminRole: {
    name: 'SCREG_User_Access_Manager',
    displayName: 'SCREG UAM'
  }
};
export { commonConfig };
