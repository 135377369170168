import React from 'react';

const PlaceHolderBox = ({ title }) => {
  return (
    <div className="center-holder-wrap">
      <div className="place-holder-box">
        <div className="place-holder-title">{title}</div>
      </div>
    </div>
  );
};
export default PlaceHolderBox;
