import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import React, { useState, useEffect, useCallback } from 'react';

type AggridRefObj = React.RefObject<AgGridReact>;

type PaginationPanelProps = {
  gridRef: AggridRefObj;
  updatePaginationPanel: Function;
  serverSidePagination: boolean;
};

const PaginationPanel = ({
  gridRef,
  updatePaginationPanel,
  serverSidePagination
}: PaginationPanelProps) => {
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [startRange, setStartRange] = useState(0);
  const [endRange, setEndRange] = useState(0);

  const getValidGridRef = (gridRef: AggridRefObj) => {
    return gridRef && gridRef?.current && gridRef?.current?.api;
  };

  useEffect(() => {
    if (getValidGridRef(gridRef)) {
      const currentPage = gridRef.current!.api.paginationGetCurrentPage() + 1;
      const pageSize = gridRef.current!.api.paginationGetPageSize();
      const totalRows = gridRef.current!.api.paginationGetRowCount();

      const startRange = (currentPage - 1) * pageSize + 1;
      const endRange = Math.min(currentPage * pageSize, totalRows);

      setStartRange(startRange);
      setEndRange(endRange);
      setTotalCount(totalRows);

      setTotalPage(gridRef.current!.api.paginationGetTotalPages());
      setPageSize(pageSize);
      setCurrentPage(currentPage);
    }
  }, [gridRef, updatePaginationPanel]);

  const onBtFirst = useCallback(() => {
    if (getValidGridRef(gridRef)) {
      gridRef.current!.api.paginationGoToFirstPage();
    }
  }, []);

  const onBtLast = useCallback(() => {
    if (getValidGridRef(gridRef)) {
      gridRef.current!.api.paginationGoToLastPage();
    }
  }, []);

  const onBtNext = useCallback(() => {
    if (getValidGridRef(gridRef)) {
      gridRef.current!.api.paginationGoToNextPage();
    }
  }, []);

  const onBtPrevious = useCallback(() => {
    if (getValidGridRef(gridRef)) {
      gridRef.current!.api.paginationGoToPreviousPage();
    }
  }, []);

  const onPageSizeChanged = useCallback((value) => {
    if (getValidGridRef(gridRef)) {
      gridRef.current!.api.paginationSetPageSize(Number(value));
      gridRef.current!.api.paginationGoToFirstPage();

      if (serverSidePagination) {
        gridRef.current!.api.refreshServerSide({
          route: undefined,
          purge: true
        });
      }
    }
  }, []);

  return (
    <div className="ag-paging-panel ag-unselectable">
      <span className="ag-paging-row-summary-panel">
        <label className="ag-paging-row-summary-panel-number">
          Show page entries:{' '}
        </label>
        <div className="form-group form-group--inline">
          <div className="form-group__text select">
            <select
              value={pageSize}
              onChange={(ev) => {
                onPageSizeChanged(ev.target.value);
              }}
              style={{ height: '10px', minHeight: '30px' }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
            </select>
          </div>
        </div>
      </span>
      <span className="ag-paging-row-summary-panel">
        <span className="ag-paging-row-summary-panel-number">{startRange}</span>
        <span> to </span>
        <span className="ag-paging-row-summary-panel-number">{`${endRange} `}</span>
        <span>of</span>
        <span className="ag-paging-row-summary-panel-number">{` ${totalCount}`}</span>
      </span>
      <span className="ag-paging-page-summary-panel">
        <div
          className={`ag-button ag-paging-button ${
            currentPage == 1 ? 'ag-disabled' : ''
          }`}
          onClick={onBtFirst}
        >
          <span className="ag-icon ag-icon-first"></span>
        </div>
        <div
          className={`ag-button ag-paging-button ${
            currentPage == 1 ? 'ag-disabled' : ''
          }`}
          onClick={onBtPrevious}
        >
          <span className="ag-icon ag-icon-previous"></span>
        </div>
        <span className="ag-paging-description">
          <span>Page </span>
          <span className="ag-paging-number">{currentPage}</span>
          <span> of </span>
          <span className="ag-paging-number">{totalPage}</span>
        </span>
        <div
          className={`ag-button ag-paging-button ${
            currentPage === totalPage ? 'ag-disabled' : ''
          }`}
          onClick={onBtNext}
        >
          <span className="ag-icon ag-icon-next"></span>
        </div>
        <div
          className={`ag-button ag-paging-button ${
            currentPage === totalPage ? 'ag-disabled' : ''
          }`}
        >
          <span className="ag-icon ag-icon-last" onClick={onBtLast}></span>
        </div>
      </span>
    </div>
  );
};

export default PaginationPanel;
