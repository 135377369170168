import React, { useState, useEffect, useRef, CSSProperties } from 'react';

import './index.css';

export interface DropdownProps {
  content?: React.ReactNode;
  title: React.ReactNode;
  open?: boolean;
  onChange?: Function;
  showIcon?: boolean;
  dropDownStyle?: CSSProperties;
  className?: string;
}

const Dropdown = ({
  title,
  content,
  open,
  onChange: onOpenChange,
  showIcon = true,
  dropDownStyle,
  className
}: DropdownProps) => {
  const wrapperRef = useRef(null);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (typeof open !== 'undefined') {
      setIsActive(!!open);
    }
  }, [open]);

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
    function handleClick(e: MouseEvent) {
      if (wrapperRef && wrapperRef.current) {
        const ref: any = wrapperRef.current;
        if (!ref.contains(e.target)) {
          setIsActive(false);
          if (onOpenChange) {
            onOpenChange(false);
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    if (typeof open !== 'undefined') {
      setIsActive(!!open);
    }
  }, [open]);

  const onChange = () => {
    setIsActive(!isActive);
    if (onOpenChange) {
      onOpenChange(!isActive);
    }
  };

  return (
    <div ref={wrapperRef} className={`dropdown ${className}`}>
      <div className="dropdown-body">
        <div className="dropdown-title" onClick={onChange}>
          <span className="qtr-margin-right"> {title} </span>
          {showIcon && (
            <>
              {isActive ? (
                <span className="icon-chevron-up"></span>
              ) : (
                <span className="icon-chevron-down"></span>
              )}
            </>
          )}
        </div>
        {isActive && (
          <div className="dropdown-content" style={dropDownStyle}>
            {content}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
