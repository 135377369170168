import React from 'react';

import Loader from '../../atoms/Loader';
import './index.css';

const TransparentFullScreenLoader = ({ title = '', height = '' }) => {
  return (
    <div
      className="fullscreen-loader"
      style={{
        background: 'transparent',
        backgroundColor: 'transparent',
        height: height
      }}
    >
      <div className="loader-inner-wrap panel panel--raised panel--bordered">
        <Loader />
        {title && (
          <div className="base-margin-top">
            <h6>{title}</h6>
          </div>
        )}
      </div>
    </div>
  );
};

export default TransparentFullScreenLoader;
