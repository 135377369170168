import React from 'react';

import InvitationsTable from '../../organisms/InvitationsTable';

const Invitations = () => {
  return (
    <div className="container">
      <div className="section">
        <InvitationsTable title={'Pending Invitations'} />
      </div>
    </div>
  );
};
export default Invitations;
