import React, { useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import './index.css';
import { fetchAuditReportScheduleDetails } from '../../../services';

import Loader from '../../atoms/Loader';
import { capitalizeFirstLetter } from '../../../helpers/common';

interface AuditReportScheduleViewDialogProps {
  scheduleId?: string;
  action: string;
}

const AuditReportScheduleViewDialog = ({
  scheduleId
}: AuditReportScheduleViewDialogProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [scheduleDetails, setScheduleDetails] = useState<any>();

  useEffect(() => {
    if (scheduleId) {
      getAuditReportDetails();
    }
  }, [scheduleId]);

  const getAuditReportDetails = async () => {
    try {
      setIsLoading(true);
      const payload = {
        id: scheduleId
      };
      const result = await fetchAuditReportScheduleDetails(payload);
      setScheduleDetails(result && result?.length ? result[0] : {});

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  return (
    <div className="card base-margin-top" style={{ marginBottom: '60px' }}>
      <div className="card-header">
        <div className="flex">
          <h5 className="text-primary" style={{ width: '100%' }}>
            View Scheduled Report
          </h5>
          <Link
            to="/audit-report-schedule"
            state={{ fromSource: 'companyDetail' }}
            style={{ width: '90px' }}
            className="pull-right btn btn--ghost btn--small"
          >
            <span className="icon-arrow-left-tail"></span> Back
          </Link>
        </div>
      </div>
      <div className="card-body">
        <>
          <form>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <div className="row">
                  <div className="col-md-8">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="record-info">
                          <span className="record-label">Report Name</span>
                          <div>
                            <span className="record-value">
                              {scheduleDetails?.reportName}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="record-info">
                          <span className="record-label">Description</span>
                          <div>
                            <span className="record-value">
                              {scheduleDetails?.reportDescription || '-'}
                            </span>
                          </div>
                        </div>
                      </div>

                      {scheduleDetails?.frequency?.toLowerCase() ===
                      'onetime' ? (
                        <>
                          <div className="col-md-6">
                            <div className="record-info">
                              <span className="record-label">
                                Scheduled Date
                              </span>
                              <div>
                                <span className="record-value">
                                  {scheduleDetails?.startDate
                                    ? moment(
                                        scheduleDetails?.startDate,
                                        'YYYY-MM-DD'
                                      ).format('MM-DD-YYYY')
                                    : '-'}
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}

                      {scheduleDetails?.frequency?.toLowerCase() !==
                      'onetime' ? (
                        <>
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                            <div className="record-info">
                              <span className="record-label">Start Date</span>
                              <div>
                                <span className="record-value">
                                  {scheduleDetails?.startDate
                                    ? moment(
                                        scheduleDetails?.startDate,
                                        'YYYY-MM-DD'
                                      ).format('MM-DD-YYYY')
                                    : '-'}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                            <div className="record-info">
                              <span className="record-label">End Date</span>
                              <div>
                                <span className="record-value">
                                  {scheduleDetails?.endDate
                                    ? moment(
                                        scheduleDetails?.endDate,
                                        'YYYY-MM-DD'
                                      ).format('MM-DD-YYYY')
                                    : '-'}
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}

                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                        <div className="record-info">
                          <span className="record-label">Frequency</span>
                          <div>
                            <span className="record-value">
                              {scheduleDetails?.frequency
                                ? scheduleDetails?.frequency?.toLowerCase() ==
                                  'onetime'
                                  ? 'One Time'
                                  : capitalizeFirstLetter(
                                      scheduleDetails?.frequency
                                    )
                                : ''}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                        <div className="record-info">
                          <span className="record-label">Created By</span>
                          <div>
                            <span className="record-value">
                              {scheduleDetails?.createdBy}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                        <div className="record-info">
                          <span className="record-label">Last Updated By</span>
                          <div>
                            <span className="record-value">
                              {scheduleDetails?.lastUpdatedBy}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                        <div className="record-info">
                          <span className="record-label">Recipients</span>
                          <div>
                            <span className="record-value">
                              {scheduleDetails?.cecReceipents?.join(', ')}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                        <div className="record-info">
                          <span className="record-label">Created Date</span>
                          <div>
                            <span className="record-value">
                              {scheduleDetails?.creationDate &&
                                moment(scheduleDetails?.creationDate).format(
                                  'MM-DD-YYYY'
                                )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="half-margin-bottom half-margin-top">
                      <div className="card">
                        <div className="card-header">
                          <h5 className="text-primary">Filter</h5>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                              <div className="record-info">
                                <span className="record-label">
                                  Report Type
                                </span>
                                <div>
                                  <span className="record-value">
                                    {scheduleDetails?.reportType || '-'}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                              <div className="record-info">
                                <span className="record-label">
                                  Event Action
                                </span>
                                <div>
                                  <span className="record-value">
                                    {scheduleDetails?.eventAction?.join(', ') ||
                                      '-'}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                              <div className="record-info">
                                <span className="record-label">
                                  Company Name
                                </span>
                                <div>
                                  <span className="record-value">
                                    {scheduleDetails?.companyName || '-'}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                              <div className="record-info">
                                <span className="record-label">Vendor Id</span>
                                <div>
                                  <span className="record-value">
                                    {scheduleDetails?.vendorId || '-'}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                              <div className="record-info">
                                <span className="record-label">Site Id</span>
                                <div>
                                  <span className="record-value">
                                    {scheduleDetails?.siteId || '-'}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                              <div className="record-info">
                                <span className="record-label">
                                  Company Type
                                </span>
                                <div>
                                  <span className="record-value">
                                    {scheduleDetails?.companyType || '-'}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                              <div className="record-info">
                                <span className="record-label">Actor</span>
                                <div>
                                  <span className="record-value">
                                    {scheduleDetails?.actor || '-'}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-sm-12 col-md-5">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="text-primary">Scheduled Dates </h5>

                        <div className="text-weight-700">
                          {' '}
                          {scheduleDetails?.message}{' '}
                        </div>
                      </div>
                      <div className="card-body scroll-y">
                        <div className="responsive-table">
                          <table
                            className="table table--bordered table--compressed text-center"
                            aria-label="Compressed table"
                          >
                            <thead>
                              <tr>
                                <th>Schedule Date</th>
                                <th>Day</th>
                                <th>Schedule Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {scheduleDetails?.scRegAuditScheduleJobsDetails &&
                                scheduleDetails?.scRegAuditScheduleJobsDetails?.map(
                                  (item: any) => {
                                    return (
                                      <tr
                                        key={`item$${item?.scheduledStartDate}`}
                                      >
                                        <td>
                                          {moment(
                                            item?.scheduledStartDate
                                          ).format('MM-DD-YYYY')}{' '}
                                        </td>
                                        <td>
                                          {' '}
                                          {moment(
                                            item?.scheduledStartDate
                                          ).format('ddd')}{' '}
                                        </td>
                                        <td>
                                          {item?.action?.toLowerCase() ===
                                          'cancelled'
                                            ? item?.action
                                            : item?.status}
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </form>
        </>
      </div>
    </div>
  );
};

export default AuditReportScheduleViewDialog;
