import httpUtil from '../helpers/interceptor';
import notify from '../helpers/notification';

export const fetchChildCompany = async (
  companyName: string,
  companyTypeId: string,
  userType: string[]
) => {
  try {
    const res = await httpUtil.post(`/api/v1/company/sub`, {
      companyName: companyName,
      companyTypeId: companyTypeId,
      userType: userType
    });
    return res || [];
  } catch (error: any) {
    const errorMessage = `An error occurred while fetching child companies ${
      error?.response?.data?.error ? ': ' + error?.response?.data?.error : ''
    }`;
    notify(errorMessage, 'error');
    return Promise.reject(error);
  }
};
