import React from 'react';
import { Tooltip } from 'react-tooltip';
import moment from 'moment';

import './index.css';
import { SelectOption } from '../../molecules/Select/Select';
import { capitalizeFirstLetter } from '../../../helpers/common';

export interface UsersListValueProps {
  users: SelectOption[];
  showStatus?: boolean;
}

const InvitePaListValue = ({
  users,
  showStatus = true
}: UsersListValueProps) => {
  const isExpired = (date: string) => {
    const a = moment(date);
    const b = moment();
    const noOfDays = a.diff(b, 'days');
    return noOfDays < -14;
  };
  return (
    <>
      {users && users?.length ? (
        <div className="qtr-margin-top card user-value-card">
          <div>
            {users?.map((item: SelectOption, index) => {
              const isInviteExpired = isExpired(item?.creationDate);
              return (
                <div
                  className="panel panel--bordered user-value-panel"
                  key={`${item.value}${index}`}
                >
                  <div className="flex">
                    <div
                      className="qtr-margin-right"
                      style={{ width: 'calc(100% - 40px)' }}
                    >
                      {item?.firstName} {item?.lastName} ({item?.email})
                    </div>
                    <div className="flex" style={{ width: '40px' }}>
                      <div>
                        {item?.status?.toLowerCase() ===
                        'nomination_accepted' ? (
                          <span
                            className="icon-check-outline text-success qtr-margin-left"
                            style={{ cursor: 'pointer' }}
                            data-tooltip-id={`tooltip-user-status${index}`}
                          ></span>
                        ) : null}
                        {item?.status?.toLowerCase() === 'in_process' ? (
                          <>
                            {isInviteExpired ? (
                              <span
                                className="icon-clock text-warning qtr-margin-left"
                                style={{ cursor: 'pointer' }}
                                data-tooltip-id={`tooltip-user-status${index}`}
                              ></span>
                            ) : (
                              <span
                                className="icon-time text-warning-alt qtr-margin-left"
                                style={{ cursor: 'pointer' }}
                                data-tooltip-id={`tooltip-user-status${index}`}
                              ></span>
                            )}
                          </>
                        ) : null}
                        {item?.status?.toLowerCase() === 'denied' ? (
                          <span
                            className="icon-blocked text-danger qtr-margin-left"
                            style={{ cursor: 'pointer' }}
                            data-tooltip-id={`tooltip-user-status${index}`}
                          ></span>
                        ) : null}

                        {item?.status?.toLowerCase() !== 'in_process' &&
                        item?.status?.toLowerCase() !== 'nomination_accepted' &&
                        item?.status?.toLowerCase() !== 'denied' ? (
                          <span
                            className="icon-info text-muted qtr-margin-left"
                            style={{ cursor: 'pointer' }}
                            data-tooltip-id={`tooltip-user-status${index}`}
                          ></span>
                        ) : null}
                        <Tooltip id={`tooltip-user-status${index}`}>
                          <span style={{ zIndex: 1111 }}>
                            {item?.status?.toLowerCase() === 'in_process' &&
                            isInviteExpired
                              ? 'INVITE EXPIRED'
                              : item?.status?.replace(/_/gi, ' ')}
                          </span>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default InvitePaListValue;
